import React, { useContext, useState, useEffect } from 'react';
import { ProductContext } from '@components/Context';
import { getCouponByProduct, receiveCoupon } from '@service/api';
import CouponDrawer from '@modules/Product/CouponDrawer';
import { CLAIM_TYPE, CLAIM_TYPE_LABEL } from '@lib/constants';
import Toast from '@components/Toast';
import './coupon.scss';

function Coupon() {
    const productContext: any = useContext(ProductContext);
    const { id } = productContext?.product || {};
    const [voucher, setVoucher]: any = useState();
    const [drawerVisible, setDrawerVisible] = useState(false);
    useEffect(() => {
        if(id) {
            getCouponInfo(id)
        }
    }, [id]);

    function getCouponInfo(id: string) {
        getCouponByProduct(id)
            .then((res: any) => {
                if(res && res.WrapCouponOrStamp) {
                    res?.WrapCouponOrStamp?.forEach((item: any) => item.data = item.coupon || item.stamp)
                    const voucher = {
                        ...res,
                        coupon: res?.WrapCouponOrStamp?.filter((item: any) => !!item.coupon),
                        stamp: res?.WrapCouponOrStamp?.filter((item: any) => !!item.stamp)
                    }
                    setVoucher(voucher);
                }
            })
    }

    function handleReceive(coupon: any) {
        const { claimType, data } = coupon;
        const couponId = data?.id;
        const type = coupon.coupon ? 'coupon' : 'stamp';
        const isUnclaim = claimType === CLAIM_TYPE.UNCLAIM;
        if(isUnclaim) {
            receiveCoupon(couponId, type)
            .then((res: any) => {
                Toast('领取成功');
                getCouponInfo(id);
            })
            .catch((err: any) => {
                Toast('领取失败，请稍后重试');
            })
        }
    }
    if(!voucher || !voucher?.WrapCouponOrStamp?.length) return null;
    return (
        <div className='m-coupon'>
            <div className='coupon-title-wrap'>
                <div className='title'>用券</div>
                <div className='tips-wrap' onClick={() => setDrawerVisible(true)}>
                    {voucher?.headTip && <div className='couopon-tips'>{voucher?.headTip}</div>}
                    {getUnclaimedCount(voucher) > 0 && <div className='unclaimed-count'>{getUnclaimedCount(voucher)}张可领</div>}
                </div>
            </div>
            {voucher?.coupon && voucher?.coupon?.length ?
                <div className='voucher'>
                    <div className='label'>运费券</div>
                    <div className='coupon-item-wrap'>
                        {voucher?.coupon?.map((item: any) => {
                            return <CouponItem coupon={item} handleReceive={handleReceive} />
                        })}
                    </div>
                </div> : null
            }
            {voucher?.stamp && voucher?.stamp?.length ?
                <div className='voucher'>
                    <div className='label'>商品券</div>
                    <div className='coupon-item-wrap'>
                        {voucher?.stamp?.map((item: any) => {
                            return <CouponItem coupon={item} handleReceive={handleReceive} />
                        })}
                    </div>
                </div> : null
            }
            <CouponDrawer
                voucher={voucher}
                handleReceive={handleReceive}
                visible={drawerVisible}
                onClose={() => setDrawerVisible(false)}
            />
        </div>
    )
}

function CouponItem(props: any) {
    const { coupon, handleReceive } = props;
    const { claimType, data } = coupon || {};

    return (
        <div className='m-coupon-item'>
            <div className='text'>{getCouponName(data)}</div>
            {claimType !== CLAIM_TYPE.NORMAL && <img className='coupon-bind' src={require('@images/coupon-bind.svg').default} />}
            <div className='text' onClick={() => handleReceive(coupon)}>
                {CLAIM_TYPE_LABEL[claimType] || ''}
            </div>
        </div>
    )
}

function getCouponName(coupon: any) {
    let namePrefix, nameSuffix;
    if(coupon.restriction && coupon.restriction.minOrderValue > 0) {
        namePrefix = '满$' + coupon.restriction.minOrderValue/100
    } else {
        namePrefix = '无门槛'
    }
    if(coupon.amount && coupon.amount > 0) {
        nameSuffix = '减$' + coupon.amount/100
    } else {
        nameSuffix = '减¥' + coupon.amountFen/100
    }
    return namePrefix + nameSuffix;
}

function getUnclaimedCount(voucher: any) {
    let couponCount = voucher?.coupon?.reduce(function(acc: number, cur: any) {
        return acc + (cur?.claimType === CLAIM_TYPE.UNCLAIM ? 1 : 0)
    }, 0);
    let stampCount = voucher?.stamp?.reduce(function(acc: number, cur: any) {
        return acc + (cur?.claimType === CLAIM_TYPE.UNCLAIM ? 1 : 0)
    }, 0);
    return couponCount + stampCount;
}

export default Coupon;