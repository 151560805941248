import { getProductAlt, getImageUrl } from '@/lib/utils';
import React, { useMemo, Fragment } from 'react';
import * as styles from './Detail.module.scss';

const DES_TYPE = {
    TEXT: 'TEXT',
    IMAGE: 'IMAGE'
}

function interlaceImg(url) {
    return `${url}?x-oss-process=image/format,jpg/interlace,1`;
}

const Details  = ({product}) => {
  const attribute = useMemo(() => {
    const info = [];
    const material = product?.attributes?.attributes?.material?.choices;
    const detail = product?.keyDetails;
    const origin = product?.attributes?.attributes?.origin?.choices;
    material?.length && info.push({label: '材质', value: material});
    detail?.length && info.push({label: '细节', value: detail});
    origin?.length && info.push({label: '产地', value: origin});
    return info
  }, [product])

  const description = product?.imageText?.layers || []

  return (
    <div className={styles.details}>
      {
        attribute.length ? (
          <>
            <div className={`${styles.title} ${styles.parameter}`}>
              商品参数
            </div>
            <div className={`${styles.parameterMain} ${styles.main}`}>
              {
                attribute.map((item, index) => (
                  <div className={styles.parameterItem} key={index}>
                    <div className={styles.parameterItemTitle}>
                      {item.label}：
                    </div>
                    <div className={styles.parameterItemContext}>
                      {item.value}
                    </div>
                  </div>
                ))
              }
            </div>
          </>
        ) : null
      }
      {
        description.length ? (
          <>
            <div className={`${styles.title} ${styles.description}`}>
            商品描述
            </div>
            <div className={`${styles.descriptionMain} ${styles.main}`}>
              {
                description.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      {
                        item.layerType === DES_TYPE.TEXT && (
                          <div className={styles.descriptionItem}>
                            {item?.text?.text}
                          </div>
                        )
                      }
                      {
                        item.layerType === DES_TYPE.IMAGE && (
                          <div className={`${styles.descriptionItem} ${styles.image}`}>
                            <img src={getImageUrl(item?.image?.url)} alt={`${getProductAlt(product)} 描述`} />
                          </div>
                        )
                      }
                    </Fragment>
                  )
                })
              }
              <div className={styles.priceDes}>
                <p className={styles.priceDesTitle}>价格说明</p>
                <p><strong>划线价</strong>：商品展示的划线价是指品牌专柜价、商品吊牌价或由供应商提供的正品零售价（如厂商指导价、建议零售价等）或该商品在别样上曾经展示过的销售价，并非原价；由于地区、时间的差异性和市场行情的波动，品牌专柜标价、商品吊牌价等可能会与您购物时展示的不一致，该价格仅供您参考。</p>
                <p><strong>别样价</strong>：别样价为用户购买的商品详情页显示售价，是别样用户最终决定是否购买该商品的依据。具体成交价格根据商品参加活动，或使用优惠券，积分等发生变化，最终以订单结算页价格为准。</p>
                <p><strong>价格异常</strong>：因可能存在系统缓存、页面更新延迟等不确定性情况，导致价格显示异常，商品具体售价请以订单结算页价格为准。如您发现异常情况出现，请联系我们修正，以便您能顺利购物。</p>
              </div>
            </div>
          </>) : null
      }
    </div>
  )
}

export default Details