import React, { FC, ReactNode } from 'react'
// import { Link } from 'gatsby'

interface IProps{
  children: ReactNode
  to: string
  prefetch: boolean
  [key: string]: any
}

const MyLink: FC<IProps> = ({ children, to, prefetch, ...rest }) => {
  // if (!prefetch) {
    return <a href={to} data-key='a' {...rest}>{children}</a>
  // }
  // return <Link to={to} data-key='link' {...rest}>{children}</Link>
}

MyLink.defaultProps = {
  prefetch: true
}

export default MyLink