import React from 'react';
import ProductComponent from '@/templates/product/product';
import { getProductDetail } from '@/service/api';

function ProductPage({ serverData }: any) {
  return (
    <ProductComponent pageContext={serverData} />
  )
}

export async function getServerData(context: any) {
  try {
    let id = context.params?.['id']
    if (!id) {
      id = context.query.productIds?.split(',')?.[0]
      if (!id) {
        id = context.query.id
      }
    }
    if (!id) {
      return {
        status: 404,
        props: {
          footer: true,
        },
      }
    }
    const productRes: any = await getProductDetail(id)
    let headers = {}
    if (!!productRes?.id) {
      headers = {
        'Cache-Control': 'public, max-age=600, s-maxage=86400, stale-while-revalidate=3600',
      }
    } else {
      headers = {
        'Cache-Control': 'max-age=0, no-cache, must-revalidate',
      }
    }

    return {
      headers,
      props: {
        product: productRes,
        renderingMode: !!productRes?.id ? 'SSR' : undefined,
        footer: true,
      },
    }
  } catch (e) {

  }
  return {
    headers: {
      'Cache-Control': 'max-age=0, no-cache, must-revalidate',
    },
    props: {
      footer: true,
    }
  }
}

export default ProductPage
