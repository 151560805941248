import React, { useMemo } from 'react'
import * as Styles from './index.module.scss'
import moment from 'moment'
import like from '@/images/like.png'
import starOrg from '@/images/star-org.png'
import starGray from '@/images/star-gray.png'
import Preview from '../Preview'
import more from '@/images/more.png'
import './index.scss'
import MoreProduct from '../MoreProduct'
import defaultAvatar from '@/images/ic_default_avatar.png'

function Info({ info, similarProducts }) {
  const { userAvatar, userLabel, userMedals, likes, productCommentLabel, pictures, sku, content, postedAt, videos } = info || {};
  const rate = sku?.attributes?.attributes?.exchangeRateUsed?.choices?.[0];
  const price = sku?.cents ? ((sku?.cents * rate) / 100).toFixed(2) : 0

  const _pictures = useMemo(() => {
    if (pictures?.length) {
      return pictures
    }
    if (videos?.length) {
      return videos.map(video => {
        return {
          full: {
            url: video.cover.url,
          }
        }
      })
    }
    return []
  }, [pictures, videos])

  function getSpecificationText() {
    const color = sku?.color?.name ? `颜色：${sku?.color?.name}` : '';
    const size = sku?.size?.name ? `尺码：${sku?.size?.name}` : '';
    const width = sku?.attributes?.attributes?.width?.choices?.[0] ? `宽度：${sku?.attributes?.attributes?.width?.choices?.[0]}` : '';
    const arr = [color, size, width].filter(item => !!item);
    return arr.join('｜');
  }
  // function handleClick() {
    // window.location.href = `https://yourls.bybieyang.com/h5opa`
  // }

  return <div className={Styles.info}>
    <div className={Styles.userDetail}>
      <img className={Styles.avatar} src={userAvatar || defaultAvatar} alt={userLabel ? `用户${userLabel}` : '用户头像 icon'} />
      <div className={Styles.userInfo}>
        <div className={Styles.nameWrap}>
          <div className={Styles.name}>{userLabel}</div>
          {userMedals && userMedals.length ?
            <div className={Styles.badge}>
              <img className={Styles.badgeIcon} src={userMedals?.[0]?.image?.url} alt="badge icon" />
              {userMedals?.[0]?.text?.text}
            </div> : null
          }
        </div>
        <div className={Styles.starWrap}>
          <div className={Styles.time}>{moment(postedAt).format('YYYY-MM-DD')}</div>
          {[1, 2, 3, 4, 5].map((_, index) => {
            const starImg = index >= productCommentLabel?.productSatisfyScore ? starGray : starOrg;
            return <img src={starImg} key={index} alt="review star" />
          })}
        </div>
      </div>
      <div className={Styles.button}>
        <img className={Styles.like} src={like} alt="like icon" />
        <div className={Styles.likeNumber}>{likes || '赞'}</div>
      </div>
    </div>
    
    <div className='info-preview'>
      <Preview images={_pictures} content={content} />
    </div>
    <div className={Styles.specification}>{getSpecificationText()}</div>
    <div className={Styles.tag}>{
      productCommentLabel?.wordTags?.map(w => (
        <span className={Styles.tagItem} key={w}>{w}</span>
      ))
    }</div>
    <p className={Styles.content}>{content}</p>
    <a href={`/pd/${sku?.productId}/`}>
      <div className={Styles.product}>
        <img className={Styles.productImg} src={sku?.images?.[0]?.full?.url || sku?.images?.[0]?.thumbnail?.url || ''} alt={`${sku?.name || ''} ${price || ''}元 商品图片`} />
        <div className={Styles.msg}>
          <div className={Styles.title}>{sku?.name}</div>
          <div className={Styles.price}>{price ? '¥' : ''}{price || ''}</div>
        </div>
        <img className={Styles.arrow} src={more} alt="more icon" />
      </div>
    </a>

    <MoreProduct similarProducts={similarProducts} />
  </div>
}

export default Info