import React, { useState, useEffect, useMemo } from 'react'
import SEO from '@/templates/seo'
import { getHelpContent } from '@/service/helpService'
import FloatingWidget from "@/components/FloatingWidget"
import './help.scss'
import { isBrowser } from '@/lib/utils'

function Help(props: any) {
  const { pageContext } = props || {}
  const { helpContent, current: _current } = pageContext || {}
  const [content, setContent] = useState<any[]>(() => {
    return helpContent?.categories
  })
  const current = useMemo(() => {
    if (!isBrowser) return _current || 0
    const g = window.location.pathname.match(/help\/([\d]+)\/?/)
    return g?.[1] ? +g?.[1] : (_current || 0)
  }, [])

  useEffect(() => {
    getHelpContent()
      .then((data: any) => {
        console.log(data?.categories)
        if (data?.categories?.length) {
          setContent(data?.categories)
        }
      })
      .catch(e => {})
  }, [])

  return (
    <div>
      <SEO
        title={`帮助中心 - ${content?.[current]?.name || '常见问题'} - 别样海外购`}
        description={`别样海外购（Bieyangapp.com）帮助中心, 别样海外购是一款欧美时尚商品海淘直购APP, 帮助中心给您提供${content?.[current]?.name || '帮助文档'}等方面常见问题指南，上别样，海淘专家一站式服务！`}
        keywords={'别样App,别样,别样海外购,海淘'}
        canonical={!current || +current === 0 ? 'https://www.bieyangapp.com/help' : `https://www.bieyangapp.com/help/${current}`}
      />
      <div className='hc-page'>
        <div className="mainTitle">
          <h1>帮助中心</h1>
          <span className="line"></span>
        </div>
        <div className="mainTitle2">Help Center</div>
        <div className="hc-main-content">
          <div className="hc-side-bar">
            <div className='hc-side-bar-head'>常见问题</div>
            <ul className="hc-side-bar-menu">
              {content?.map((item: any, i: number) => (
                <li className={`hc-side-item ${current == i ? 'hc-side-item-active' : ''}`} key={i}>
                  <a href={i === 0 ? '/help' : `/help/${i}`}>
                    <span>{item.name}</span>
                    {/* <img src={arrowDown}></img> */}
                  </a>
                </li>
              ))}
              <li className={`hc-side-item`} key={'user-policy'}>
                <a target='_blank' href='/static/html/user_policy.html'>
                  <span>用户协议</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="hc-content-wrapper">
            <ul className='hc-breadcrumb'>
              <li className='hc-breadcrumb-item'>
                <a href='/'>首页</a>
              </li>
              <li className='hc-breadcrumb-item'>
                <a href='/help'>帮助中心</a>
              </li>
              <li className='hc-breadcrumb-item'>
                <a href={void(0)}>{content?.[current]?.name || '帮助文档'}</a>
              </li>
            </ul>
            <div className={`hc-content`}>
              {content?.[current]?.questions?.map((item: any, index: number) => {
                return (
                  <div className='hc-qa-item' key={`q_${index}`}>
                    <h4 className='hc-qa-question'>
                      {index+1}.{item.question}
                    </h4>
                    <p className='hc-qa-answer'>
                      {item.answer}
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <FloatingWidget />
    </div>
  )
}

export default Help
