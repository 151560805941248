import React from 'react'
import Download from '../DownloadPopup'
import qrcode from '@images/qrcode-download-app.jpeg'
import qrIcon from '@images/qrcodeicon.png'
import topIcon from '@images/top.png'
import { isBrowser } from '@/lib/utils'
import './index.scss'

function FloatingWidget(props: any) {
  const { amount } = props || {}

  function handleTop() {
    if (!isBrowser) return
    const html: any = document.querySelector('html')
    html.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  return (
    <div className='floating-download'>
      <Download qrcodeImg={qrcode} amount={amount || '688元'} />
      <div className='button-wrap'>
        <img src={qrIcon} className='qr-icon' />
        <img src={topIcon} onClick={handleTop} className='top-icon' />
      </div>
    </div>
  )
}

export default FloatingWidget
