import React, { useMemo, Fragment } from 'react'
import * as styles from './Size.module.scss'
import img_measure from '@/assets/images/img_measure@2x.png'
const Size = ({info = {}}) => {
  const { shoe, tables  } = info

  const table = useMemo(() => {
    return tables.map(item => {
      const { desc, title, content, columns} = item
      const columnMaps = columns.reduce((init, col, index) => {
        init[col] = index
        return init
      }, {})
      
      const contentList = content.map(row => {
        return Object.entries(row).reduce((initial, [key, value]) => {
          initial[columnMaps[key]] = value
          return initial
        }, [])
      })
      return {
        desc, title, content: contentList, columns
      }
    })
  }, [tables])

  return (
    <div className={styles.size}>
      {
        shoe && (
          <div className={styles.shoe}>
            <div className={styles.title}>脚长测量方法</div>
            <img src={img_measure} alt="shoe" />
          </div>
        )
      }
      {
        table.map((item, index) => (
          <div className={styles.table} key={index}>
            <div className={styles.title}>
              { item.title }
            </div>
            <div className={styles.desc}>
              { item.desc }
            </div>
            <table
              cellPadding={0}
              cellSpacing={0}
            >
              <tbody>
                <tr>
                  {
                    item.columns.map(col => (
                      <th style={{ width:  `${1/item.columns.length}%`}} key={`th_${col}`} >{ col === '-' ? "" : col }</th>
                    ))
                  }
                </tr>
                {
                  item.content.map((row, index) => (
                    <tr key={`r_${index}`}>
                      {
                        row.map((val, index) => <td key={`td_${index}`} >{val}</td>)
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        ))
      }
      
    </div>
  )
}

export default Size