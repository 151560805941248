import * as React from 'react'
import Logo from '@images/logo_black.png'
import * as hs from './header.module.scss'
// import { navigate } from 'gatsby'
import { isBrowser } from '@/lib/utils'
import { useEffect, useState, useRef } from 'react'
import TABS from './tabs.json'
import { LayoutContext } from '@/components/Context'
import BieyangLink from '@components/BieyangLink'

const Header = () => {
  const { headerState, activeCategoryIndex, headerUnderline } = React.useContext(LayoutContext)
  const tabsWapper = useRef()
  const tabActive = useRef(null)
  const holeTab = useRef(null)
  const [scrollStatus, setScrollStatus] = useState('NONE')
  const [currentTab, changeTab] = useState(null)
  const [defaultActive, setDefaultActive] = useState({
    left: '0px',
    index: 0
  })
  const prevTop = useRef(0)

  const windowScrollHandler = React.useCallback(() => {
    if (holeTab.current) {
      if (window.pageYOffset > holeTab.current.offsetTop) {
        if (window.pageYOffset > prevTop.current) {
          // 向下滚动
          setScrollStatus('UP')
        } else {
          // 向上滚动
          setScrollStatus('DOWN')
        }
      } else {
        // 回到顶部
        setScrollStatus('NONE')
      }
      prevTop.current = window.pageYOffset
    }
  }, [])

  useEffect(() => {
    if (!isBrowser) return
    // window.scrollTo(0, 0)
    window.addEventListener('scroll', windowScrollHandler)
    return () => {
      window.removeEventListener('scroll', windowScrollHandler)
    }
  }, [windowScrollHandler])

  useEffect(() => {
    if (!isBrowser) return false
    let pathname = window.location.pathname
    if (!pathname.endsWith('/')) {
      pathname += '/'
    }
    const keys = pathname.split('/');
    const _routername = `/${keys[keys.length - 2]}` === '/bieyang' ? '/' : `/${keys[keys.length - 2]}`
    TABS.map((item, index) => {
      let link = item.path || item.id
      if (_routername === link || `/category${_routername}`.startsWith(`/category/${link}`)) {
        const left = document.querySelectorAll(`.${hs.headerContentNav} a`)[index].offsetLeft
        setDefaultActive({
          left: (left || 0) + 'px',
          index: index
        })
        tabActive.current.style.left = (left || 0) + 'px'
      }
    })
  }, [])

  useEffect(() => {
    if (currentTab === null) {
      document.body.height = 'auto';
      document.body.style.overflow = 'visible';
    } else {
      document.body.height = '100vh';
      document.body.style.overflow = 'hidden';
    }
  }, [currentTab])

  useEffect(() => {
    if (activeCategoryIndex > -1) {
      const offsetLeft = tabsWapper.current?.children[activeCategoryIndex].offsetLeft + 'px'
      setDefaultActive({
        left: offsetLeft,
        index: activeCategoryIndex
      })
      tabActive.current.style.left = offsetLeft
    }
  }, [activeCategoryIndex])

  const goTo = (e, path) => {
    // e.stopPropagation()
    // e.preventDefault()
    // navigate(path)
    // changeTab(null)
    const left = document.querySelectorAll(`.${hs.headerContentNav} a`)[currentTab].offsetLeft
    setDefaultActive({
      left: (left || 0) + 'px',
      index: currentTab
    })
    // return false
  }

  const tabMouseOver = (e, index) => {
    if (e.target.nodeName === 'A') {
      changeTab(index)
      tabActive.current.style.left = e.target.offsetLeft + 'px'
    }
  }

  const outTab = () => {
    tabActive.current.style.left = defaultActive.left
    changeTab(null)
  }

  const clickTab = (e, item, index) => {
    if (item.path) {
      setDefaultActive({
        left: e.target.offsetLeft + 'px',
        index: index
      })
    }
  }

  return (
    <header className={`${hs.header} ${headerUnderline ? hs.underline : ''}`}>
      <div
        className={
          scrollStatus === 'NONE' || headerState === 'relative'
            ? hs.headerBg
            : scrollStatus === 'UP' ? `${hs.headerBg} ${hs.headerBgFixedUp}` : `${hs.headerBg} ${hs.headerBgFixedDown}`}>
        <div className={hs.headerContent}>
          <a href="/" className={hs.headerContentBox}>
            <img className={hs.headerContentBoxLogo} src={Logo} alt="别样logo" />
          </a>
          <div className={hs.headerContentMain} onMouseLeave={outTab} ref={holeTab}>
            <div className={hs.headerContentNav} ref={tabsWapper}>
              {
                TABS?.map((item, index) => {
                  return (
                    <BieyangLink 
                      key={item.id + '_' + index} 
                      to={item.path || `/category/${item.id}/`} 
                      prefetch={!!item.path}
                      onClick={e => { clickTab(e, item, index) }}
                      onMouseOver={(e) => tabMouseOver(e, index)}
                    >
                        {item?.name}
                    </BieyangLink>
                  )
                })
              }
              <div className={hs.headerContentNavActive} ref={tabActive}>
                <span className={hs.headerContentNavActiveItem}>{TABS[currentTab !== null ? currentTab : defaultActive.index].name}</span>
              </div>
            </div>

            {
              TABS.map((tab, tabIndex) => {
                return <div key={'tab_' + tabIndex} className={tabIndex === currentTab ? hs.navHover : `${hs.navHover} ${hs.navHoverDisable}`} >
                  {
                    tab?.children &&
                    <div className={hs.navHoverContent}>
                      <div className={hs.navHoverContentMain}>
                        {
                          tab?.children?.map((category, idx) => {
                            return <div className={hs.navHoverContentSlide}  key={category.category.id + '_' + idx}>
                              <BieyangLink  to={`/category/${category.category.id}/`} prefetch={false}><div className={tabIndex === 9 ? `${hs.navHoverContentSlideTitle} ${hs.navHoverContentSlideTitle2}` : `${hs.navHoverContentSlideTitle}`}>{category.category.displayTerm}</div></BieyangLink>
                              <div className={hs.navHoverContentSlideBox}>
                                {
                                  category.children?.map((cate, ix) => {
                                    return <BieyangLink to={`/category/${cate.category.id}/`} key={cate.category.id} prefetch={false}><div key={ix} className={hs.navHoverContentSlideBoxItem} onClick={e => goTo(e, `/category/${cate.category.id}`)}>{cate.category.displayTerm}</div></BieyangLink>
                                  })
                                }
                              </div>
                            </div>
                          })
                        }
                      </div>
                    </div>
                  }
                  <div className={hs.navHoverBottom} onMouseOver={outTab}></div>
                </div>
              })
            }
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header