import React, { useEffect, useState } from 'react'
import './index.scss'
import InputBox from '@components/InputBox'
const PriceSort = (props: any) => {
    const { q, setSearchQuery, searchProducts } = props
    const priceRange: Array<string> = ['$30以下', '$30～$50', '$50～$100', '$100～$200', '$200～$300', '$300以上']
    const _priceRange: Array<any> = [[0, 30], [30, 50], [50, 100], [100, 200], [200, 300], [300, '']]

    const resetQuery = () => {
        setSearchQuery('priceRange', ['', ''])
    }

    const isActive = (item: any) => {
        if (q.priceRange) {
            const [_r1, _r2] = q.priceRange
            const [r1, r2] = item
            return _r1 === r1 && _r2 === r2
        }
        return false
    }

    return (
        <div className="discountSortContent">
            <div className="allInput">
                <div className="discountItem">
                    <div className="inputContent">
                        <InputBox
                            place="最低价格"
                            value={q.priceRange1 || ''}
                            onFocus={e => {}}
                            onChange={e => setSearchQuery('priceRange1', e)}
                        ></InputBox>
                        <span className="line"></span>
                        <InputBox
                            place="最高价格"
                            value={q.priceRange2 || ''}
                            onFocus={e => {}}
                            onChange={e => setSearchQuery('priceRange2', e)}
                        ></InputBox>
                    </div>
                    <div className='discountRec'>
                        {
                            priceRange.map((item, idx) => {
                                return <div className={`discountRec-item ' ${isActive(_priceRange[idx]) ? 'active' : ''}`} key={item} onClick={() => setSearchQuery('priceRange', _priceRange[idx])}>{item}</div>
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="searchBtnContent">
                <div className="resetBtn baseBtn" onClick={resetQuery}>
                    重置
                </div>
                <div className="sureBtn baseBtn" onClick={searchProducts}>
                    确认
                </div>
            </div>
        </div>
    )
}

export default PriceSort
