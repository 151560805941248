import * as React from "react"
// import { Link } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react'
import FloatingWidget from "@/components/FloatingWidget"
import banner from '@/images/merchant-list/banner.png'
import bannerM from '@/images/merchant-list/m-banner.jpg'
import arraw1 from '@/images/arraw1.png'
import 'swiper/css';
import "swiper/css/navigation"
import "swiper/css/autoplay"
import './merchant.scss'
import SwiperCore, {
  Navigation,
  Autoplay,
} from 'swiper';
import SEO from './seo'
import { getImageUrl } from '@/lib/utils';

SwiperCore.use([Navigation, Autoplay]);

const Merchants = (props: any) => {
  const { merchantsData } = props.pageContext
  return (
    <div className='merchants'>
      <SEO
        title={'精选商家频道 - 别样海外购'}
        description={'别样海外购（Bieyangapp.com）是一家欧美海外网购商城, 汇聚欧美上百家高端百货店, 新潮买手店, 数千奢品时尚品牌, 全球实时帮你抢黑五好货! 24小时中文同步欧美商家的海量优惠: 折扣、小样、礼包, 一个都不少。实时播报,机器人极速下单,促销海淘上别样,全球官网正品一键直邮到家'}
        keywords={'别样App,别样海外购,奢侈品,手表,别样海淘,海淘'}
        canonical='https://www.bieyangapp.com/merchants'
      />
      <section className="top-banner">
        <img className="banner" src={banner} />
        <img className="m-banner" src={bannerM} />
      </section>

      <section className="merchants-header"> 
        <h1 className="title">精选商家</h1>
        <div className="sub-title">
          <h2 className="with-wing">机器人下单·价格同步·官网直邮</h2>
        </div>
        <p className="description">
          别样海外购通过自主研发的智能机器人下单系统，直接对接海外商家官网，以同步的价格、款式和折扣提供给消费者，
          <br />用户在别样海外购APP上下单，再由下单机器人将用户订单一键同步给到海外商家，由商家直接发货，直邮好货到家。目前别样已与数百个
          <br />欧美商家达成合作，其中包括知名百货公司、综合性电商平台及享誉国内外的品牌官网，让用户安心海外购。
        </p>
      </section>

      <article>
        <section className="merchantList">
          {
            merchantsData.map((item: any, index: number) => {
              return (
                <div className="merchantItem FinishLineBox" key={index}>
                  <div className="merchantBanner">
                    <div className="merchantImg dynamicImg">
                      <img src={item.merchantInfo.images?.[2]?.full?.url || item.merchantInfo.images?.[1]?.full?.url} alt={`商家 ${item.merchantInfo.name} 主图`} />
                    </div>
                    <div className="merchantInfoContent">
                      <img
                        className="finishlineTitle"
                        src={item.merchantInfo.images[0].full.url}
                        alt={`商家 ${item.merchantInfo.name} 图标`} />
                      <p className="merchantItemintroduction">{item.merchantInfo.description}</p>
                      <a
                        href={`/browse?q=${encodeURIComponent(item.merchantInfo.name)}`}
                        className="merchantBtn clickCourse"
                        data-id={item.merchantId}
                      >
                        <img src={arraw1} alt="arrow icon" />
                        <span>查看详情</span>
                      </a>
                    </div>
                  </div>
                  <div className="outMerchantBox">
                    <div className="merchantProductContent finishlineProductsBox">
                      <Swiper
                        className={'productList mySwiper'}
                        loop
                        slidesPerView={4}
                        navigation={true}
                        autoplay={{
                          delay: 5000,
                        }}
                      >
                        {
                          item.products.map((product: any) => {
                            return <SwiperSlide
                              key={product.searchProduct.product.id}
                              className="productItem swiper-slide"
                              data-id={product.searchProduct.product.id}
                            >
                              <a href={`/pd/${product.searchProduct.product.id}/`}>
                                <div className="productImage merchantProductImg">
                                  <img src={getImageUrl(product.searchProduct?.product?.images?.[0]?.full?.url)} alt={`商品 ${product.searchProduct.product.nameCN || product.searchProduct.product.name} 图`} />
                                </div>
                                <div className="prodcutItemInfo">
                                  <p className="productName">
                                    {product.searchProduct.product.displayBrand || product.searchProduct.product.brand} | {product.searchProduct.product.nameCN || product.searchProduct.product.name}
                                  </p>
                                  <p className="productFrom">{product.searchProduct.origin} {product.searchProduct.merchantName}</p>
                                  <p className="priductPrice">
                                    <span className="priceCN">{product.searchProduct.product.priceTagCN}</span>
                                    <span className="priceAm">{product.searchProduct.product.priceTag}</span>
                                    <span className="priceOriginal">{product.searchProduct.product.originalPriceTag}</span>
                                  </p>
                                  <p className="productTags">
                                    {
                                      product.searchProduct.activityOffs.join(' | ')
                                    }
                                  </p>
                                </div>
                              </a>
                            </SwiperSlide>
                          })
                        }
                      </Swiper>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </section>
      </article>

      <FloatingWidget />
    </div >
  )
}
export default Merchants
