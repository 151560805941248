import React from "react"
import './404.scss'
import { Empty } from 'antd-mobile'

const NotFoundPage = () => {
  return (
    <Empty description="您访问的页面没有找到" imageStyle={{ width: 128 }} style={{ width: '100%', height: '300px' }}>
    </Empty>
  )
}

export default NotFoundPage
