import { BxlAnalytics } from 'bxl-analytics'
import bxlUtils from 'bxl-utils'
import http from "@/lib/http"
import LocalStorage from '@/lib/localStorage'
import SesssionStorage from '@/lib/session'
import { BXL_GUEST_ID, BXL_SESSION_START } from '@/lib/constants'

function sendAppChannelRegisterEventApi(data: any) {
  return http.post(`/api/v1/_internal/appchannel/register`, data)
}

let analytics: any = null

function initAnalytics() {
  if (!window) return
  analytics = new BxlAnalytics()
  analytics.registerSuperProperties({
    version: '1.0.0',
    appVersion: '',
    guestId: () => {
      return LocalStorage.get(BXL_GUEST_ID) || ''
    },
    platform: () => {
      return 'H5'
    },
    sessionStart: () => {
      try {
        const sessionStart = SesssionStorage.get(BXL_SESSION_START)
        return sessionStart ? parseInt(sessionStart) : 0
      } catch(e) {
        return 0
      }
    }
  })
}

function trackAppChannelRegisterEvent(productId: string) {
  if (!window) return
  try {
    // const analytics = new BxlAnalytics()
    const ua = navigator.userAgent
    const utmParams = analytics?.utmParams()
    const guestId = LocalStorage.get(BXL_GUEST_ID)
    const queryParams = bxlUtils.uri.parseUrlParams(window.location.href)
    sendAppChannelRegisterEventApi({
      content: JSON.stringify({
        '$utm_source': utmParams?.utm_source || 'bieyangapp_pd',
        '$utm_content': utmParams?.utm_content || '',
        '$utm_medium': utmParams?.utm_medium || '',
        '$utm_term': utmParams?.utm_term || productId,
        '$utm_campaign': utmParams?.utm_campaign || '',
        product_id: productId || '',
        os: queryParams?.os || '',
        experiment: queryParams?.experiment || '',
        search_id: queryParams?.search_id || '',
        guest_id: guestId  || '',
      }),
      userAgent: ua,
      happenedAt: Date.now(),
    })
  } catch (e) {
    console.warn(e)
  }
}

function sendPageOpenEvent(productId: string) {
  if (!window) return
  try {
    const queryParams = bxlUtils.uri.parseUrlParams(window.location.href)
    analytics?.send({
      webPageOpen: {
        pageName: 'bieyangapp_pd',
        id: productId || '',
        url: window.location.href || '',
        previousPage: document?.referrer || '',
      }
    }, {
      utmContent: queryParams?.search_id || '',
    })
  } catch (e) {
    console.error(e)
  }
}

function sendClickEvent(productId: string, componentId: string) {
  if (!window) return
  try {
    const queryParams = bxlUtils.uri.parseUrlParams(window.location.href)
    analytics?.send({
      webComponentClick: {
        pageName: 'bieyangapp_pd',
        componentId: componentId || '',
        id: productId || '',
        url: window.location.href || '',
        previousPage: document?.referrer || '',
      }
    }, {
      utmContent: queryParams?.search_id || '',
    })
  } catch (e) {
    console.error(e)
  }
}

function sendSessionEvent(productId: string) {
  if (!window) return
  try {
    analytics?.send({
      webPagePerformance: {
        projectName: 'bieyangapp_pd',
        page_name: productId || '',
        url: window.location.href || '',
      }
    })
  } catch (e) {
    console.error(e)
  }
}

export {
  initAnalytics,
  sendSessionEvent,
  sendPageOpenEvent,
  sendClickEvent,
  trackAppChannelRegisterEvent,
  analytics,
}
