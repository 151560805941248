const preferDefault = m => m.default;
const React = require(`react`);
const { Script } = require('gatsby');
const Boostrap = preferDefault(require('./src/layouts/Boostrap'));
const RegularLayout = preferDefault(require('./src/layouts/RegularLayout'));

module.exports = ({ element, props }) => {
  return (
    <>
      <Boostrap {...props}>
        <RegularLayout>
          {element}
        </RegularLayout>
      </Boostrap>
      <Script id="gtm-load">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-N29CBJG');
        `}
      </Script>
      <Script id="ttzz-load">
      {`
        ;(function(){
          var el = document.createElement("script");
          el.src = "https://lf1-cdn-tos.bytegoofy.com/goofy/ttzz/push.js?a674d2ba34b0c3aef7a848c6b3ec2a15ff388b1ccbab2972f9832b6baa743f5bfd9a9dcb5ced4d7780eb6f3bbd089073c2a6d54440560d63862bbf4ec01bba3a";
          el.id = "ttzz";
          var s = document.getElementsByTagName("script")[0];
          s.parentNode.insertBefore(el, s);
        })()
      `}
      </Script>
      <Script id="baidu-hm-load">
        {`
          var _hmt = _hmt || [];
          ;(function () {
            var scripts = document.querySelectorAll('script')
            var has = Array.from(scripts).find(item => {
              return item.id === 'hm_baidu'
            })
            if (!has) {
              var hm = document.createElement("script");
              hm.id = 'hm_baidu'
              hm.src = "https://hm.baidu.com/hm.js?c7487e7cb62a4c055e9fd54769a8f897";
              var s = document.getElementsByTagName("script")[0];
              s.parentNode.insertBefore(hm, s);
            }
          })()
        `}
      </Script>
      <Script id="sobot-load">
        {`
          ;(function (w, d, e) {
            w[e] = function () {
              w.cbk = w.cbk || []
              w.cbk.push(arguments);
            }
            var x = d.createElement("script");
            x.async = true;
            x.id = "zhichiScript";
            x.className="zhiCustomBtn";
            x.src = "https://bybieyang.sobot.com/chat/frame/v2/entrance.js?sysnum=4291aa02fcc7428c96b43b3ee59ca7ad";
            d.body.appendChild(x);
            w[e]("config", {
              custom: true,
              "manual": true,
            })
          })(window, document, "zc");
        `}
      </Script>
    </>
  )
}
