import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './index.module.scss'
// import { Link } from 'gatsby'

const Breadcrumb = ({ cate }) => {
  return (
    <ul className={styles.breadcrumb}>
      {
        cate.map((item, index) => (
          <li key={index}>
            {
              index < cate?.length - 1 ? (
                <a href={item.path ? item.path : `/category/${item.cid}/`}>{item.term}</a>
              ) : (
                <a href={void(0)}>
                  <h1 className={styles.title}>{item.term}</h1>
                </a>
              )
            }
          </li>
        ))
      }
    </ul>
  )
}

Breadcrumb.displayName = 'Breadcrumb'

Breadcrumb.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    cid: PropTypes.string,
    term: PropTypes.string,
  }))
}

Breadcrumb.defaultProps = {
  cate: []
}

export default Breadcrumb