import React, { useState, useEffect } from 'react';
import Product from '@modules/Home/Product';
import './hot-list.scss';
import badge1 from '@images/brand_top_1.png'
import badge2 from '@images/brand_top_2.png'
import badge3 from '@images/brand_top_3.png'
import badgeother from '@images/brand_top_other.png'

function HotList(props: any) {
    const { hotList } = props || {};
    const [active, setActive] = useState(0);

    function handleClick(e: any, item: any) {
        e.preventDefault()
        setActive(item)
    }

    useEffect(() => {
        setActive(0);
    }, [hotList])
    
    return (
        <div className='m-hot-list'>
            <div className='content'>
                <h2 className='main-title'>热卖榜单</h2>
                <h3 className='sub-title'>BlackFriday Sale</h3>
                <div className='tab-wrap'>
                    {hotList?.map((item: any, idx: number) => {
                        const isActive = idx === active
                        return (
                            <div className='tab-wrap-main' key={item.id}>
                                <a
                                    title={item.title}
                                    href={`/static/html/hotSale/index.html?activeTab=${item.id}&utm_source=bieyang_home`}
                                    className={`tab-item ${isActive ? 'active' : ''}`}
                                    onClick={(e) => handleClick(e, idx)}
                                >
                                    {item.title}
                                </a>
                                {idx !== hotList.length - 1
                                    ? <div className='tab-item-split'></div>
                                    : null
                                }
                            </div>
                        )
                    })}
                </div>

                {
                    hotList?.map((list: any, listIndex: number) => {
                        return <div key={'hotList_' + listIndex} className={active === listIndex ? 'products-list-wrap' : 'product-list-wrap product-list-wrap-disable'}>
                            {
                                list?.products?.map((product: any, idx: number) => {
                                    return (
                                        <div className='product-wrap' key={listIndex + '_' + idx}>
                                            <Product product={product} />
                                            <div className='product-wrap-badge'>
                                                <img
                                                    src={
                                                        idx === 0
                                                            ? badge1
                                                            : idx === 1
                                                                ? badge2
                                                                : idx === 2
                                                                    ? badge3
                                                                    : badgeother
                                                    }
                                                    alt="badge" />
                                                <div className={idx < 3 ? 'product-wrap-badge-text-top' : 'product-wrap-badge-text-other'}>{idx < 3 ? `TOP ${idx + 1}` : idx + 1}</div>
                                            </div>
                                        </div>
                                    )
                                })

                            }
                        </div>
                    })

                }
            </div>
        </div>
    )
}

export default HotList;