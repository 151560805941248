import React from 'react';
// import { useClearSku } from '@lib/hooks';
import './recommend-product.scss';
// import { Link } from 'gatsby'
import { openAppStore } from '@/lib/appstore';
import { getImageUrl } from '@/lib/utils';

function RecommendProduct(props: any) {
    const { product } = props;
    // const clearSku = useClearSku();
    function handleClick() {
        // if (product?.id) {
        //     clearSku();
        // }
        openAppStore()
    }

    return (
        <a className='m-recommend-product-item' title={product?.label || ''} href={`/pd/${product?.id}/`} onClick={!product?.id ? handleClick : undefined}>
            <div className='img-wrap'>
                <img src={getImageUrl(product?.image?.path)} alt={`推荐${product?.label || ''}商品`} />
            </div>
            <div className='name'>{product?.label}</div>
            <div className='price'>
                <span className={`${product?.marks?.length > 1 ? 'discount' : 'normal-price'}`}>{product?.marks?.[0]}</span>
                <span className='origin-price'>{product?.marks?.[1]}</span>
            </div>
        </a>
    )
}

export default RecommendProduct;