import React from 'react';
import { SKUProvider, CartProvider, GlobalProvider, CheckoutProvider, AccountProvider } from '@/components/Context';
import ProductDetail from './productDetail';
import { isBrowser } from '@/lib/utils';

class ContextProvider extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
  }

  // componentDidMount() {
  //   if (isBrowser) {
  //     const html = document.querySelector('html');
  //     html && (html.style.fontSize = `60px`);
  //   }
  // }
  
  componentWillUnmount() {
    if (isBrowser) {
      document.querySelector('html')?.removeAttribute('style')
    }
  }

  state = {
    sku: {
      chosenSku: null,
      chosenParam: {},
      availableOption: {},
      count: 1,
      drawerVisible: false,
      isExpressBuy: false,
      updateChosenSku: this.createUpdateFun('sku', 'chosenSku').bind(this),
      updateChosenParam: this.createUpdateFun('sku', 'chosenParam').bind(this),
      updateAvailableOption: this.createUpdateFun('sku', 'availableOption').bind(this),
      updateCount: this.createUpdateFun('sku', 'count').bind(this),
      updateDrawerVisible: this.createUpdateFun('sku', 'drawerVisible').bind(this),
      updateIsExpressBuy: this.createUpdateFun('sku', 'isExpressBuy').bind(this)
    },
    cart: {
      merchantList: [],
      detail: {},
      activeMerchant: '',
      cartNum: 0,
      updateMerchantList: this.createUpdateFun('cart', 'merchantList').bind(this),
      updateDetail: this.createUpdateFun('cart', 'detail').bind(this),
      updateActiveMerchant: this.createUpdateFun('cart', 'activeMerchant').bind(this),
      updateCartNum: this.createUpdateFun('cart', 'cartNum').bind(this)
    },
    global: {
      iframeLink: '',
      selectCountry: null,
      countryList: null,
      addonPayerEdit: {},
      updateIframeLink: this.createUpdateFun('global', 'iframeLink').bind(this),
      updateSelectCountry: this.createUpdateFun('global', 'selectCountry').bind(this),
      updateCountryList: this.createUpdateFun('global', 'countryList').bind(this),
      updateAddonPayerEdit: this.createUpdateFun('global', 'addonPayerEdit').bind(this)
    },
    checkout: {
      current: '',
      event: 'add_shopping_cart',
      detail: {},
      editAddress: {},
      payer: '',
      payerList: [],
      payerEdit: {},
      updateEvent: this.createUpdateFun('checkout', 'event').bind(this),
      updateCurrent: this.createUpdateFun('checkout', 'current').bind(this),
      updateDetail: this.createUpdateFun('checkout', 'detail').bind(this),
      updateEditAddress: this.createUpdateFun('checkout', 'editAddress').bind(this),
      updatePayer: this.createUpdateFun('checkout', 'payer').bind(this),
      updatePayerList: this.createUpdateFun('checkout', 'payerList').bind(this),
      updatePayerEdit: this.createUpdateFun('checkout', 'payerEdit').bind(this)
    },
    account: {
      newcomer: {},
      searchPlaceholderText: '',
      loginState: false,
      updateNewcomer: this.createUpdateFun('account', 'newcomer').bind(this),
      updateSearchPlaceholderText: this.createUpdateFun('account', 'searchPlaceholderText').bind(this),
      updateLoginState: this.createUpdateFun('account', 'loginState').bind(this)
    }
  }

  createUpdateFun(name: any, subName: any) {
    const _this: any = this
    return function (newState: any, callback: any) {
      _this.setState((state: any) => ({
        [name]: {
          ...state[name],
          [subName]: newState
        }
      }), callback);
    }
  }

  render() {
    return (
      <GlobalProvider value={this.state.global}>
        <AccountProvider value={this.state.account}>
          <CheckoutProvider value={this.state.checkout}>
            <SKUProvider value={this.state.sku}>
              <CartProvider value={this.state.cart}>
                  <ProductDetail isMobile={this.props.isMobile} id={this.props.id} className={this.props.className} />
              </CartProvider>
            </SKUProvider>
          </CheckoutProvider>
        </AccountProvider>
      </GlobalProvider>
    )
  }
}
export default ContextProvider
