import React, { useContext, useState } from 'react';
import { ProductContext } from '@components/Context';
import './protect.scss';

function Protect() {
    const productContext: any = useContext(ProductContext);
    const { product } = productContext;

    if (product?.hideModule?.hide) {
        return null
    } else if (product?.id) {
        return (
            <div className='m-protect'>
                <div className='title'>别样保障</div>
                <div className='content'>
                    <img className='quality-protect-guarantee' src={require('@images/quality-protect-guarantee.png').default} />
                </div>
            </div>
        )
    }
    return null;
}

export default Protect;