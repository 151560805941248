import React, { useContext } from 'react';
import { ProductContext, AccountContext, GlobalContext } from '@/components/Context';
import { useGetPrice } from '@/lib/hooks';
import Tag from '@/components/Tag';
import { isLogin } from '@/lib/utils';
import history from '@/lib/history';
import './product-info.scss';
import { STAIC_ORIGIN } from '@/lib/constants';
import { openAppStore } from '@/lib/appstore';
const directShipping = require('@images/protect_mini_direct_shipping.png').default;
const qualityProduct = require('@images/protect_mini_quality_product.png').default;
const robot = require('@images/protect_mini_robot.png').default;

function ProductInfo() {
    const context: any = useContext(ProductContext);
    const accountContext: any = useContext(AccountContext);
    const globalContext: any = useContext(GlobalContext);
    const price: any = useGetPrice();
    const { id, gender, brand, nameCN, name, displayBrand, labelView, hideModule, badges, featureBar } = context.product || {};
    const promotions = context?.product?.promotions || {};

    function goToLogin() {
        openAppStore()
    }

    function handleProtectClick() {
        const link = `${STAIC_ORIGIN}/static/html/qualityPage/index.html`
        globalContext.updateIframeLink(link)
        history.push('/iframe')
    }

    function getDisplayName() {
        const brandSection = displayBrand || brand || ''
        const nameSection = nameCN || name || ''
        const showBrandSection = (brandSection && nameSection) ? nameSection.indexOf(brandSection) === -1 : false
        return `${gender ? `${gender} ` : ''}${showBrandSection ? `${brandSection} | ` : ''}${nameSection}`
    }

    function renderLabels() {
        const shortLabels = labelView?.shortLabels
        if (shortLabels?.length) {
            return (
                <div className='promotion-tags'>
                    {
                        shortLabels.map((item: any, index: number) => {
                            let color = item.labels?.[0]?.color || 'E1660C'
                            if (!color.startsWith('#')) {
                                color = '#' + color
                            }
                            let backgroundColor = item.labels?.[0]?.backgroundColor || ''
                            if (backgroundColor && !backgroundColor.startsWith('#')) {
                                backgroundColor = '#' + backgroundColor
                            }

                            return (
                                <div
                                    className='promotion-label'
                                    style={{
                                        color: color,
                                        backgroundColor: backgroundColor,
                                        fontSize: item.labels?.[0]?.fontSize / 2 || 10,
                                    }}
                                    key={index}
                                >
                                    {item.labels?.[0]?.text}
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
        return (
            (promotions?.promoBadges?.length) ?
                <div className='promotion-tags'>
                    <Tag>特惠</Tag>
                    {promotions.promoBadges.map((item: any, index: number) => {
                        return (
                            <div key={index} className='tag'>{item.badges[0]}</div>
                        )
                    })}
                </div> : null
        )
    }

    function renderLowestPriceLabel(badges: any[]) {
        if (!badges?.length) return null
        const lowestPriceIndex = badges.findIndex(b => b.productLable === 'LOW_PRICE')
        if (lowestPriceIndex !== -1) {
            const badge = badges[lowestPriceIndex]
            return (
                <span className='m-lowest-price-label'>{badge.text}</span>
            )
        }
        return null
    }

    return (
        <div className='m-product-info'>
            <div className='preview-price'>
                <div className='price'>{price?.priceTag || ''}</div>
                <div className='original-price'>{price?.originalPriceTag || ''}</div>
            </div>
            <h3 className='main-price'>
                <span className='small'>约</span><strong>{price?.priceTagCN || ''}</strong>
                {renderLowestPriceLabel(badges)}
            </h3>
            {renderLabels()}
            <h1 className='main-title'>{getDisplayName()}</h1>
            {!hideModule?.hide ? (
                <div className='protect-bar' onClick={handleProtectClick}>
                    <div className='protect-item'>
                        <img src={robot} />
                        <div className='label'>机器人下单</div>
                    </div>
                    <div className='protect-item'>
                        <img src={directShipping} />
                        <div className='label'>官网直邮</div>
                    </div>
                    <div className='protect-item'>
                        <img src={qualityProduct} />
                        <div className='label'>100%正品</div>
                    </div>
                </div>
            ) : null}
            {(!isLogin() && !accountContext.loginState) ? (
                <div className='go-login-wrap' onClick={goToLogin}>
                    <div className='tips-wrap'>
                        <div className='new-user-tag'>新人</div>
                        <div className='tips-text'>注册送{accountContext?.newcomer?.priceWithCurrencySymbolAndText}新人礼包</div>
                    </div>
                    <div className='go-login'>登录领取</div>
                </div>
            ) : null}
        </div>
    )
}

export default ProductInfo
