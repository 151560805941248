import React, { useCallback, useEffect, useRef, useState } from "react"
import Main from '@modules/Home/Main';
import Brand from '@modules/Home/Brand';
import HotList from '@modules/Home/HotList';
import Direct from '@modules/Home/Direct';
import FloatingWidget from "@/components/FloatingWidget";
import PymlSection from '@modules/Home/PymlSection'
import './index.scss';
import SEO from './seo';
import { isBrowser } from "@/lib/utils";
import { useMediaQuery } from "react-responsive";
import { getPymlHomeProducts } from '@/service/homeService';
import LoadMoreProgress from "@components/LoadMore";

const PAGE_SIZE = 40

const IndexPage = (props: any) => {
  const { pageContext } = props || {}
  const { homeData } = pageContext || {}
  const pymlRef = useRef(null)
  const [from, setFrom] = useState(0)
  const hasMore = useRef(true)
  const [moreLoading, setMoreLoading] = useState(false)
  const scrollRef = useRef(null)
  const [pymlProducts, setPymlProducts] = useState([])
  const isPC = useMediaQuery({ query: "(min-width: 960px)" })

  useEffect(() => {
    if (!isBrowser) return
    window.scrollTo(0, 0)
  }, [])

  const onScrollObserve = useCallback(() => {
    const scrollTop = window.pageYOffset
    const tabWrap: HTMLElement | null = document.querySelector('.m-hot-list .tab-wrap')
    const menuItem: HTMLElement | null = document.querySelector('.menu-items')
    const header: HTMLElement | null = document.querySelector('#l-m-header-container')
    const headerHeight = header?.offsetHeight || 0
    const hotListTabWrapOffsetHeight = tabWrap?.getBoundingClientRect()?.top || 0
    const menuItemOffsetHeight = menuItem?.getBoundingClientRect()?.top || 0
    const pymlHeaderOffsetTop = (pymlRef.current as HTMLElement | null)?.offsetTop || 0

    if (scrollTop + headerHeight >= menuItemOffsetHeight) {
      if (pymlHeaderOffsetTop > 0 && scrollTop + headerHeight > pymlHeaderOffsetTop) {
        // 取消 fixed
        tabWrap!.className = 'tab-wrap'
        menuItem!.className = 'menu-items'
      } else if (scrollTop + headerHeight >= hotListTabWrapOffsetHeight) {
        // 热卖 fixed
        tabWrap!.className = 'tab-wrap tab-wrap-fixed'
        menuItem!.className = 'menu-items'
      } else {
        // 品牌fixed
        tabWrap!.className = 'tab-wrap'
        menuItem!.className = 'menu-items menu-items-fixed'
      }
    } else {
      // all clear
      tabWrap!.className = 'tab-wrap'
      menuItem!.className = 'menu-items'
    }
  }, [])

  useEffect(() => {
    if (isPC) return
    window.addEventListener('scroll', onScrollObserve)
    return () => {
      window.removeEventListener('scroll', onScrollObserve)
    }
  }, [isPC, onScrollObserve])

  const onLoadMoreObserver = useCallback(() => {
    if (!hasMore.current) return
    if (pymlRef.current && scrollRef.current) {
      const pageTop = (scrollRef.current as HTMLElement).offsetTop
      const top = (pymlRef.current as HTMLElement).offsetTop 
      const height = (pymlRef.current as HTMLElement).offsetHeight
      const scrollY = window.pageYOffset
      const bottom = Math.ceil(scrollY + pageTop) >= top + (height * 0.8)
      if (bottom) {
        hasMore.current = false
        setFrom(prev => prev + 1)
      }
    }
  }, [])
  useEffect(() => {
    window.addEventListener('scroll', onLoadMoreObserver)
    return () => {
      window.removeEventListener('scroll', onLoadMoreObserver)
    }
  }, [onLoadMoreObserver])

  useEffect(() => {
    setMoreLoading(true)
    getPymlHomeProducts(from * PAGE_SIZE, PAGE_SIZE)
      .then((res: any) => {
        setMoreLoading(false)
        if (res.bottomRecs?.length) {
          const _current = res.bottomRecs.map((rec: any) => {
            return {
              searchProduct: {
                ...rec.productRec,
              },
            }
          })
          hasMore.current = res.bottomRecs?.length >= 0
          setPymlProducts(prev => from === 0 ? _current : [].concat(prev, _current))
        }
      })
      .catch(e => {
        setMoreLoading(false)
        console.error(e)
      })
  }, [from])

  return (
    <div className='p-home' ref={scrollRef}>
      <SEO
        title='别样海外购(Bieyangapp.com)官网-海淘专家一站式服务'
        description='别样海外购（Bieyangapp.com）是一款欧美时尚商品海淘直购APP, 汇聚欧美数百家高端百货店, 新潮买手店, 以及上万奢品时尚品牌, 全球实时帮你抢好货! 24小时中文同步欧美商家的海量优惠: 折扣、小样、礼包。实时播报, 机器人极速下单, 官网100%正品一键直邮到家!'
        keywords='别样App,别样海外购,别样海淘,海淘,别样'
        canonical='https://www.bieyangapp.com'
      />
      <Brand brands={homeData?.tabs || []} />
      <HotList hotList={homeData?.topSelling?.tabs || []} />
      <section ref={pymlRef} className='section-pyml'>
        {pymlProducts?.length ? <PymlSection pymlProducts={pymlProducts} /> : null}
        {moreLoading ? <LoadMoreProgress /> : null}
      </section>
      <Main amount={homeData?.giftInfo?.amount || '688元'} />
      <Direct />
      <FloatingWidget />
    </div>
  )
}

export default IndexPage
