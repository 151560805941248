import React from 'react';
import directDelivery from '@images/direct-delivery.png';
import directDeliveryM from '@images/img_process.png';
// import shadow from '@images/shadow3.png';
import './direct.scss';

function Direct(props: any) {
    return (
        <div className='m-direct'>
            <div className='line'></div>
            <div className='title-wrap'>
                <h2 className='main-title'>别样直邮模式介绍</h2>
                <h3 className='sub-title'>/  DIRECT DELIVERY MODE</h3>
            </div>
            <p className='content'>
                别样海外购主要采用的是直邮模式，当消费者在APP/网站上下单时，别样开发的智能机器人会帮消费者在品牌和商场/家的官网上直接下单，等待订单成功后，货品会由海外商家直接邮寄到消费者手中。这种模式实现了商品活动、折扣、价格与海外官网24小时同步，确保100%正品。   
            </p>
            <div className='delivery-img-wrap'>
                <img src={directDeliveryM } className='direct-img-m' />
                <img src={directDelivery} className='direct-img' />
            </div>
            {/* <img src={shadow} className='shadow' /> */}
        </div>
    )
}

export default Direct;