import React, { useContext, useEffect } from 'react';
import './product-detail.scss';
import { ProductContext, SKUContext, CartContext } from '@/components/Context';
import ProductDetailCarousel from '@/modules/Product/ProductDetailCarousel';
import ColorPicker from '@/modules/Product/ColorPicker';
import ProductInfo from '@/modules/Product/ProductInfo';
import Promotion from '@/modules/Product/Promotion';
import Features from '@/modules/Product/Features';
import Specification from '@/modules/Product/Specification';
import MerchantInfo from '@/modules/Merchant/MerchantInfo';
import ProductAttributes from '@/modules/Product/ProductAttributes';
import ProductDescription from '@/modules/Product/ProductDescription';
import BrandInfo from '@/modules/Brand/BrandInfo';
import Protect from '@/modules/Product/Protect';
import SimilarProduct from '@/modules/Product/SimilarProduct';
import OfficialWebsite from '@/modules/Merchant/OfficialWebsite';
import ProductBottomBar from '@/modules/Product/ProductBottomBar';
import ProductComment from '@/modules/Comment/ProductComment';
import Coupon from '@/modules/Product/Coupon';
import { setReopenFlag } from '@/lib/utils';

function ProductDetail(props: any) {
    const { id: productId, className } = props || {}
    const productContext: any = useContext(ProductContext)
    const cartContext: any = useContext(CartContext)
    // const accountContext: any = useContext(AccountContext);
    const skuContext: any = useContext(SKUContext)
    // const layoutContext = useContext<any>(LayoutContext)
    const { translation } = productContext || {}

    useEffect(() => {
        if (!productContext?.product) return
        cartContext.updateActiveMerchant(productContext?.product?.merchantId)
    }, [productContext.product])

    useEffect(() => {
        setReopenFlag()
    }, [])

    const carouselImg = skuContext?.chosenSku?.images ? skuContext?.chosenSku?.images : productContext?.product?.images
    return (
        <div className={`p-product-detail ${className || ''}`}>
            <div className='carousel'>
                {(carouselImg && carouselImg.length) ?
                    <ProductDetailCarousel list={carouselImg} productId={productId} product={productContext?.product} /> : null
                }
                <ColorPicker />
            </div>
            <ProductInfo />
            <Coupon />
            <Promotion />
            <Specification />
            <Features />
            <ProductComment />
            <MerchantInfo />
            <ProductAttributes />
            <ProductDescription />
            <OfficialWebsite description={translation || productContext?.product?.description} />
            <BrandInfo />
            <Protect />
            <SimilarProduct similar={productContext?.similar} />
            <ProductBottomBar isMini={false} />
        </div>
    )
}

export default ProductDetail
