// extracted by mini-css-extract-plugin
export var descriptionItem = "Detail-module--description-item--0a3b2";
export var details = "Detail-module--details--577ed";
export var image = "Detail-module--image--3c4e8";
export var main = "Detail-module--main--4802e";
export var parameterItem = "Detail-module--parameter-item--9f14a";
export var parameterItemContext = "Detail-module--parameter-item-context--2ceef";
export var parameterItemTitle = "Detail-module--parameter-item-title--5c61a";
export var parameterMain = "Detail-module--parameter-main--f8ef9";
export var priceDes = "Detail-module--price-des--d69f3";
export var priceDesTitle = "Detail-module--price-des-title--1a344";
export var title = "Detail-module--title--edea1";