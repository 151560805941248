import React, { useContext, useState } from 'react';
import Drawer from '@components/Drawer';
import { getNumberInt, getNumberFloat } from '@lib/utils';
import Tag from '@components/Tag';
import { CLAIM_TYPE } from '@lib/constants';
import './coupon-drawer.scss';

function CouponDrawer(props: any) {
    const { voucher, handleReceive, visible, onClose } = props;
    const coupons = voucher?.WrapCouponOrStamp || [];

    return (
        <Drawer
            visible={visible}
            className='m-coupon-drawer'
            id="C-Coupon-Drawer"
            hasCloseBtn={true}
            onClose={onClose}
        >
            <div className='title'>适用代金券</div>
            {voucher?.headTip &&
                <div className='tips'>
                    <img className='lamp' src={require('@images/lamp.png').default} />
                    {voucher?.headTip}
                </div>
            }
            <div className='coupon-wrap'>
                {coupons?.map((coupon: any) => {
                    return <CouponItem coupon={coupon} handleReceive={handleReceive} />
                })}
            </div>
        </Drawer>
    )
}

function CouponItem(props: any) {
    const { coupon, handleReceive } = props;
    const { amount, amountFen, caption, description, expiresAt } = coupon?.data || {};
    const currency = (amount && amount > 0) ? '$' : '¥';
    const price = (amount || amountFen) / 100;

    return (
        <div className='m-drawer-coupon-item'>
            <div className='value'>
                <div className='price'>
                    <div className='currency'>{currency}</div>
                    <div className='price-int'>{getNumberInt(price)}</div>
                    {getNumberFloat(price) ? <div className='price-float'>{'.' + getNumberFloat(price)}</div> : null}
                </div>
                <div className='restriction'>{getRestrictionTips(coupon?.data)}</div>
            </div>
            <div className='detail'>
                <div className='name'>{caption?.split(' ')?.[1] || caption}</div>
                <div className='description'>{description}</div>
                <div className='info'>
                    <Tag backgroundColor='#FBF5F0' fontColor='#D27D3F'>{coupon.coupon ? '运费券' : '商品券'}</Tag>
                    {coupon.claimType === CLAIM_TYPE.UNCLAIM && <div className="receive-btn" onClick={() => handleReceive(coupon)}>立即领取</div>}
                    {coupon.claimType === CLAIM_TYPE.WILLEXPIRE && <div className="expires-tips">{getExpiresDay(expiresAt)}</div>}
                </div>
            </div>
        </div>
    )
}

function getRestrictionTips(coupon: any) {
    if(coupon?.restriction && coupon.restriction.minOrderValue && coupon.restriction.minOrderValue > 0) {
        return '满$' + coupon.restriction.minOrderValue / 100;
    } else {
        return '无金额门槛';
    }
}

function getExpiresDay(timeStamp: number) {
    var now = new Date().valueOf();
    var diff = timeStamp - now;
    var dayMillisecond = 86400000;
    var hourMillisecond = 3600000;
    var minuteMillisecond = 60000;
    if(diff < 0) {
        return '';
    } else if (diff < dayMillisecond) {
        var h = Math.floor(diff / hourMillisecond);
        var m = Math.floor((diff % hourMillisecond) / minuteMillisecond);
        return h + '小时' + m + '分后过期';
    } else {
        return Math.ceil(diff / dayMillisecond) + '天后过期'
    }
}

export default CouponDrawer;