import React, { FC, useEffect, useState, useMemo } from "react"
import Breadcrumb from '@/modules/HaulDetail/Breadcrumb'
import * as Styles from './haul-detail.module.scss'
import Info from "@/modules/HaulDetail/Info"
import Relate from "@/modules/HaulDetail/Relate"
import { getHaulComment, getHaulDetail, getSimilarProduct } from "@/service/api"
import { isBrowser } from '@/lib/utils'
import SEO from "../seo"
import bxlUtils from 'bxl-utils'

interface IHaulDetailProps {
  pageContext: any
  categoryId?: string,
}

function useParams() {
  if (!isBrowser) return {}
  const url = window.location.href
  const params = bxlUtils.uri.parseUrlParams(url)
  if (params.commentId && params.productId) {
    return {
      productId: Array.isArray(params.productId) ? params.productId?.[0]?.trim() : params.productId?.trim(),
      commentId: Array.isArray(params.commentId) ? params.commentId?.[0]?.trim() : params.commentId?.trim(),
    }
  }
  const group = url.match(/\/?review\/?#?\/?(?!index.html)([^\/#\?]+)\/([^\/#\?]+)\/?(?:index.html)?/i)
  if (group && group?.length > 2) {
      return {
        productId: group[1],
        commentId: group[2],
      }
  }
  return {
    productId: '',
    commentId: '',
  }
}

const HaulDetail: FC<IHaulDetailProps> = ({ pageContext, categoryId }) => {
  const params = useParams()
  // console.log(params)
  const [haulInfo, setHaulInfo] = useState(() => {
    return pageContext?.info
  })

  const [breadCrumbList, setBreadCrubmList] = useState<any>(() => {
    return [
      { path: '/', term: '首页' },
      { path: '/haul', term: '晒单精选' },
      { path: '', term: pageContext?.info?.userLabel || '用户评价' },
    ]
  })
  const [relateList, setRelateList] = useState(() => {
    return pageContext.relateList
  })
  const [similarProducts, setSimilarProducts] = useState(() => {
    return pageContext.similarProducts
  })

  useEffect(() => {
    if (!params.productId || !params.commentId) return
    if (haulInfo) return
    getHaulDetail(params.productId, params.commentId).then((res: any) => {
      if (res?.comments?.[0]) {
        setHaulInfo(res?.comments?.[0] || {})
      }
      setBreadCrubmList([
        { path: '/', term: '首页' },
        { path: '/haul', term: '晒单精选' },
        { path: '', term: res?.comments?.[0]?.content || '' },
      ])
    }).catch((err: any) => {
      console.log(err)
    })
  }, [params.productId, params.commentId])

  useEffect(() => {
    if (!params.productId) return
    getSimilarProduct(params.productId).then((res: any) => {
      setSimilarProducts(res.products.slice(0, 9))
    }).catch((err: any) => {
      console.log(err)
    })
  }, [params.productId])

  useEffect(() => {
    if (!haulInfo) return
    getHaulComment(0, 30, haulInfo.classify?.categoryIds?.[0] || '0')
      .then((res: any) => {
        if (res.waterFall?.waterDrops?.length) {
          setRelateList(res.waterFall?.waterDrops)
        }
      })
      .catch(() => {})
  }, [haulInfo])

  const SeoComponent = useMemo(() => {
    if (!haulInfo) return null
    const getProductName = () => {
      if (haulInfo?.sku?.name?.indexOf('|') !== -1) {
        return haulInfo?.sku?.name?.split('|')?.[1]?.trim() || ''
      }
      return haulInfo?.sku?.name?.trim() || ''
    }

    const getName = () => {
      if (haulInfo?.content && haulInfo.content?.indexOf('默认好评') === -1) {
        if (haulInfo?.content?.length < 12) {
          return haulInfo?.content
        }
      }
      return getProductName()
    }
  
    const getTags = () => {
      if (haulInfo?.productCommentLabel?.wordTags?.length) {
        return haulInfo.productCommentLabel.wordTags.join(', ')
      }
      if (haulInfo?.sku?.name?.indexOf('|') !== -1) {
        return haulInfo?.sku?.name?.split('|')[1] || ''
      }
      return haulInfo?.sku?.name || ''
    }
    return (
      <SEO
        title={`别样用户${haulInfo?.userLabel || '匿名'}晒单评价 ${getName()} | 别样海外购`}
        description={`别样海外购${haulInfo?.userLabel || '匿名用户'}对商品${getProductName()}晒单评价: ${haulInfo?.content || '海淘欧美奢品正品直购'}`}
        keywords={`别样App,别样海淘,别样海外购,海淘,黑五,${getTags()}`}
        canonical={params?.productId && params?.commentId ? `https://www.bieyangapp.com/review/${params.productId}/${params.commentId}/` : undefined}
        image={haulInfo?.pictures?.[0]?.full?.url || haulInfo?.videos?.[0]?.cover?.url}
        author={haulInfo?.userLabel}
      />
    )
  }, [haulInfo])

  return (
    <section className={Styles.container}>
      {SeoComponent}
      <Breadcrumb cate={breadCrumbList} />
      <article className={Styles.panel}>
        <Info info={haulInfo} similarProducts={similarProducts} />
        <Relate list={relateList} categoryId={categoryId || '0'} commentId={params.commentId || haulInfo?.id} />
      </article>
    </section>
  )
}

export default HaulDetail