import * as React from "react"
import * as Styles from './ArticleItem.module.scss'

function ArticleItem(props: any) {
  const { article, index } = props || {}
  const item = article?.summary || {}
  // console.log(item)
  const getLink = () => {
    if (item?.legoId) {
      return `/art/${item.legoId}/index.html`
    }
    return `/article/${item.id}/`
  }

  const getImage = () => {
    return `https://baleen-cdn-g.bybieyang.com/static/curation${item.image}`
  }

  const getAvatar = () => {
    if (!item.authorAvatar) {
      return 'https://baleen-cdn-g.bybieyang.com/static/curation/authors/bieyangnew.png'
    }
    return `https://baleen-cdn-g.bybieyang.com/static/curation${item.authorAvatar}`
  }

  if (!!item.fullImageMeasure) {
    return (
      <a title={`${item.title} ${item.merchant}`} className={Styles.articleWideLink} key={index} href={getLink()}>
        <div className={Styles.articleWideWrap}>
          <div className={Styles.imgWideWrap}>
            <img className={Styles.img} src={getImage()} alt={`${item.title || ''}${item.subtitle || ''}${item.merchant}大图`} />
          </div>
        </div>
    </a>
    )
  }

  return (
    <a title={`${item.title} ${item.merchant}`} className={Styles.articleLink} key={index} href={getLink()}>
      <div className={Styles.articleWrap}>
        <div className={Styles.content}>
          <div className={Styles.des}>{item.merchant} {item.date}</div>
          <h2 className={Styles.title}>{item.title}</h2>
          <h3 className={Styles.subtitle}>{item.subtitle}</h3>
          <div className={Styles.avatar}>
            <img className={Styles.avatarImg} src={getAvatar()} alt={item.authorLabel ? `别样编辑${item.authorLabel}` : '别样编辑头像'} />
            <span>{item.authorLabel || '推荐'}</span>
          </div>
        </div>
        <div className={Styles.imgWrap}>
          <img className={Styles.img} src={getImage()} alt={`${item.title} ${item.subtitle} ${item.merchant}`} />
        </div>
      </div>
    </a>
  )
}

export default ArticleItem
