import React, { useContext, useState } from 'react';
import { ProductContext } from '@components/Context';
import FeaturesDrawer from '@modules/Product/FeaturesDrawer';
import './features.scss';

function Features() {
    const context: any = useContext(ProductContext);
    const [visible, setVisible] = useState(false);
    const features = context?.product?.featureGroup && context?.product?.featureGroup.features;
    function onClose(event: React.MouseEvent) {
        event.stopPropagation();
        setVisible(false);
    }
    if (features && features.length) {
        return (
            <div className='m-features'>
                <div className='title' onClick={() => setVisible(true)}>服务</div>
                {features.map((item: any, index: number) => {
                    const isBold = item.label && item.label.length && (item.label[0].fontWeight === 'BOLD');
                    return (
                        <div className={`tag ${isBold ? 'bold' : ''}`} key={index}>{item.name}</div>
                    )
                })}
                <FeaturesDrawer visible={visible}  onClose={onClose}/>
            </div>
        )
    }
    return null;
}

export default Features;