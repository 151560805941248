import React, { useContext }  from 'react';
import { ProductContext, SKUContext } from '@/components/Context';
import Tag from '@/components/Tag';
import './promotion.scss';
import { openAppStore } from '@/lib/appstore';
import { sendClickEvent } from '@/service/trackingService';
// import { SEARCH_URL } from '@lib/constants';
// import { navigateToH5 } from '@lib/utils';

function Promotion() {
    const context: any = useContext(ProductContext);
    const promotions = context?.product?.promotions && context?.product?.promotions?.promoCategories || [];

    if (promotions && promotions.length) {
        return (
            <div className='m-promotion'>
                <div className='title'>促销</div>
                {promotions.map((promotion: any) => {
                    const promos = promotion.promos || [];
                    if (promos && promos.length) {
                        return promos.map((promo: any) => {
                            return <PromotionItem productId={context?.product?.id} key={promo.id} type={promotion.type} {...promo} />
                        })
                    }
                })}
            </div>
        )
    }
    return null;
}

function PromotionItem(props: any) {
    const skuContext: any = useContext(SKUContext);
    const { chosenSku } = skuContext;
    const chosenSkuId = chosenSku?.id;
    const { productId, qualifiedSkus, deeplink, humanTitle, type, inexactExpiration, expiresAt, inexactExpirationDesc } = props;
    const isActive = !chosenSkuId || (qualifiedSkus || []).indexOf(chosenSkuId) >= 0;

    function handlePromo(deeplink:any) {
        // let url;
        // if(deeplink) {
        //     if(window.location.search.length > 1) {
        //         url = SEARCH_URL + window.location.search + '&hiddenTerm=' + encodeURIComponent(deeplink.split('?')[1])
        //     }else {
        //         url = SEARCH_URL + '?hiddenTerm=' + encodeURIComponent(deeplink.split('?')[1])
        //     }
        //     navigateToH5(url);
        // }
        openAppStore(productId)
        sendClickEvent(productId, 'pdpromo-item')
    }

    return (
        <div className={`promotion-item ${isActive ? '' : 'disable-promotion'}`} onClick={()=>{handlePromo(deeplink)}}>
            <Tag
                backgroundColor={isActive ? '#F08F48' : '#F2F2F2'}
                fontColor={isActive ? '#FFFFFF' : '#999999'}
            >
                    {type}
            </Tag>
            <div className='detail'>
                <div className='promotion-title'>{humanTitle}{isActive ? '' : '（该规格不参与本活动）'}</div>
                <div className='expiration'>{inexactExpiration ? (inexactExpirationDesc || '商家未说明结束时间，请尽早下单') : getExpireTips(expiresAt)}</div>
            </div>

        </div>
    )
}

function getExpireTips(timeStamp: number) {
    var now = Number(new Date());
    var diff = timeStamp - now;
    var dayMillisecond = 86400000;
    var hourMillisecond = 3600000;
    var minuteMillisecond = 60000;
    if (diff < 0) { return ''; } else {
        var d = Math.floor(diff / dayMillisecond); 
        var h = Math.floor((diff % dayMillisecond) / hourMillisecond);
        var m = Math.floor((diff % hourMillisecond) / minuteMillisecond);
        return '活动仅剩' + d + '天' + h + '小时' + m + '分钟';
    }
}

export default Promotion;