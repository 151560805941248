import React, { useContext } from 'react';
import { ProductContext } from '@components/Context';
import './product-attributes.scss';

function ProductAttributes() {
    const productContext: any = useContext(ProductContext);
    const material = productContext?.product?.attributes?.attributes?.material?.choices;
    const detail = productContext?.product?.keyDetails;
    const origin = productContext?.product?.attributes?.attributes?.origin?.choices;
    let attribute = [];
    if(material?.length) attribute.push({label: '材质', value: material});
    if(detail?.length) attribute.push({label: '细节', value: detail});
    if(origin?.length) attribute.push({label: '产地', value: origin});

    if(!attribute.length) return null;
    return (
        <div className='m-product-attributes'>
            <div className='title'>商品参数</div>
            <div className='content'>
                {attribute.map((item: any, index: number) => {
                    return (
                        <div key={index}>
                            <div className='label'>{item.label}</div>
                            <div className='value'>{item.value.join()}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ProductAttributes;