import React, { useEffect, useState, useRef, useMemo } from "react";
import { categoryDiscover } from '@/service/api';
import Pagination from "@/modules/Common/Pagination";
import { Empty } from "antd-mobile";
import categoryArrow from '@/images/category_arrow.png';
import * as cs from './category-pc.module.scss';
import CategorySeoComponent from './CategorySeoComponent';
import Loading from '@/layouts/Loading';
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import "swiper/css/navigation";
import "swiper/css/autoplay";
import '../../style/swiper.scss';
import SwiperCore, {
  Navigation,
  Autoplay,
} from 'swiper';
import { getImageUrl } from '@/lib/utils';

SwiperCore.use([Navigation, Autoplay])
interface sort {
  label: string,
  id: null | string
}
const availableSorts: sort[] = [
  { label: '默认排序', id: null },
  { label: '销量从高到低', id: 'sales' },
  { label: '折扣从高到低', id: 'discount' },
  { label: '价格从高到低', id: 'priceDescTag' },
  { label: '价格从低到高', id: 'priceAscTag' }
]

const size = 60;

function ProductItem(props: any) {
  const { item, index, showTags = true } = props || {}
  const brandSection = item?.searchProduct?.product?.displayBrand || item?.searchProduct?.product?.brandCN || item?.searchProduct?.product?.brand || ''
  const { labelView, origin, merchantName } = item?.searchProduct || {}

  const getName = () => {
    const namePart = item.searchProduct?.product?.nameCN || item.searchProduct?.product?.name || item.nameCN || item.name
    if (brandSection) {
      return `${brandSection} | ${namePart}`
    }
    return namePart
  }

  const nameSection = useMemo(() => getName(), [item])

  const renderColors = () => {
    const { colors } = item.searchProduct?.product || {}
    // console.log(colors)
    if (!colors?.length || colors?.length < 2) return null
    return (
      <Swiper 
        slidesPerView={6}
        // onSwiper={setThumbsSwiper}
        freeMode
        watchSlidesProgress
        className={cs.categoryProductImgColors}
      >
        {
          colors?.map((color: any, index: number) =>(
            <SwiperSlide 
              key={index}
              className={
                `thumbs-swiper-item`
              }
            >
              <div 
                className="thumbs-slider-item-img"
              >
                <img src={color.image?.thumbnail?.url || color.image?.full.url} alt={`颜色: ${color.name}, ${nameSection}`} />
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    )
  }

  return (
    <a 
      key={`p_${index}_desktop`} 
      className={cs.categoryProduct} 
      href={`/pd/${item?.searchProduct?.product?.id}/`}
      title={`${nameSection},商家${item.searchProduct.merchantName},价格${item.searchProduct.product.priceTagCN}`}
    >
      <div className={cs.categoryProductImg}>
        <img
          src={getImageUrl(item?.searchProduct?.product?.images?.[0]?.thumbnail?.url || item?.searchProduct?.product?.images?.[0]?.full?.url)}
          alt={`${nameSection},商家${item.searchProduct.merchantName},价格${item.searchProduct.product.priceTagCN}`}
        />
        {renderColors()}
      </div>
      <h2 className={cs.categoryProductName}>
        {nameSection}
      </h2>
      <div className={cs.categoryProductOrigin}>
        {origin || ''}{merchantName || ''}
      </div>
      <div className={cs.categoryProductPrice}>
        <h3 className={cs.categoryProductPriceCn}>{item.searchProduct.product.priceTagCN}</h3>
        <span className={cs.categoryProductPriceEn}>{item.searchProduct.product.priceTag}</span>
      </div>
      {showTags ? (
        <div className={cs.categoryProductTag}>
          {labelView?.shortLabels?.length ? (
            labelView?.shortLabels.map((shortLabel: any) => {
                return shortLabel?.labels?.length ? shortLabel?.labels.map((label: any, index: number) => {
                    return (
                      <div key={`${label.text}_${index}`} className={cs.categoryProductTagItem}>
                        <span>{label.text}</span>
                        <span className={cs.categoryProductTagItemLine}></span>
                      </div>
                    )
                }) : null
            })
          ) : null}
        </div>) : null}
    </a>
  )
}

const CategoryPC = (props: any) => {
  const { cid, name, product, mappingTerm, term, displayTerm, hotProducts: _hotProducts } = props;
  const { productList: _productList, total: _total } = product || {}
  const [products, setProducts] = useState<null | Array<any>>(() => {
    return _productList
  })
  const [total, setTotal] = useState<null | number>(() => {
    return _total
  })
  const [hotProducts, setHotProducts] = useState(() => {
    return _hotProducts || []
  })
  const isPC = useMediaQuery({ query: "(min-width: 960px)" })
  const firstRender = useRef(true)
  const [sort, changeSort] = useState(availableSorts[0])
  const [from, setFrom] = useState(() => {
    return 0
  })
  const [searchInput, setSearchInput] = useState<string>(() => {
    return mappingTerm || displayTerm || term
  })
  const [recBrands, setRecBrands] = useState<any[]>([])
  const [showLoading, setShowLoading] = useState(false)
  const [discountRange1, changeDiscountRange1] = useState<undefined | string>(undefined)
  const [discountRange2, changeDiscountRange2] = useState<undefined | string>(undefined)
  const [priceRange1, changePriceRange1] = useState<undefined | string>(undefined)
  const [priceRange2, changePriceRange2] = useState<undefined | string>(undefined)

  const discountRef1 = useRef<HTMLInputElement>(null)
  const discountRef2 = useRef<HTMLInputElement>(null)
  const discountRef3 = useRef<HTMLInputElement>(null)
  const discountRef4 = useRef<HTMLInputElement>(null)

  const priceRef1 = useRef<HTMLInputElement>(null)
  const priceRef2 = useRef<HTMLInputElement>(null)
  const priceRef3 = useRef<HTMLInputElement>(null)
  const priceRef4 = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!isPC) return
    search()
  }, [isPC, from, sort])

  useEffect(() => {
    firstRender.current = false
  }, [])

  const search = () => {
    const disableLoading = firstRender.current && from === 0 && products?.length
    setShowLoading(!disableLoading)

    let q = `f=${from * size}&t=${(from + 1) * size}&cids=${cid}`
    if (sort && sort.id) q += `&s=${sort.id}`
    if (priceRange1 && priceRange2) {
      q += `&prs=${Number(priceRange1) * 100}-${Number(priceRange2) * 100}`
    } else if (priceRange1) {
      q += `&prs=${Number(priceRange1) * 100}-`
    } else if (priceRange2) {
      q += `&prs=0-${Number(priceRange2) * 100}`
    }
    let _discountRange1 = Number(discountRange1) || 0;
    let _discountRange2 = Number(discountRange2) || 100;
    if (_discountRange1 < 0) _discountRange1 = 0
    if (_discountRange2 > 10) _discountRange2 = 10
    q += `&dr=${100 - _discountRange2 * 10}-${100 - _discountRange1 * 10}`
    categoryDiscover(q)
      .then((res: any) => {
        setShowLoading(false)
        const productList = res.products?.filter((product: any) => product.type == "PRODUCT") || []
        setProducts(productList)

        const brandSet = new Set()
        const recBrands = []
        for (let i = 0; i < productList.length; i++) {
          const p = productList[i]
          const b = p.searchProduct?.product?.displayBrand || p.searchProduct?.product?.brand
          const brandId = p.searchProduct?.product?.brandId
          if (b && !brandSet.has(b)) {
            brandSet.add(b)
            recBrands.push({
              display: b,
              value: brandId,
            })
            if (recBrands.length >= 15) {
              break
            }
          }
        }
        // console.log(recBrands)
        setRecBrands(recBrands)
        setTotal(res?.total)
      }).catch(err => {
        setShowLoading(false)
      })
  }

  const changeInput = (e: React.ChangeEvent<HTMLInputElement>, cb: Function, type: string) => {
    let value = e.target.value.trim()
    value = value.replace(/[^0-9.]/g, '')
    if (type === 'discount' && Number(value) > 10) {
      return
    }
    cb(value)
  }

  const onSave = () => {
    if (priceRange1 && priceRange1 !== '$' && !priceRange2) changePriceRange2('$')
    if (!priceRange1 && priceRange2 && priceRange2 !== '$') changePriceRange1('$')
    if ((!priceRange1 || priceRange1 === '$') && (!priceRange2 || priceRange2 === '$')) {
      resetPrice()
    }
    if (discountRange1 && discountRange1 !== '0折' && !discountRange2) changeDiscountRange2('全价')
    if (!discountRange1 && discountRange2 && discountRange2 !== '全价') changeDiscountRange1('0折')
    if ((!priceRange1 || priceRange1 === '0折') && (!priceRange2 || priceRange2 === '全价')) {
      resetPrice()
    }
    if (from === 0) {
      search()
    } else {
      setFrom(0)
    }
    // 加个loading
  }

  const resetDiscount = () => {
    changeDiscountRange1(undefined);
    changeDiscountRange2(undefined);
    discountRef1.current!.value = ''
    discountRef2.current!.value = ''
    discountRef3.current!.value = ''
    discountRef4.current!.value = ''
  }

  const resetPrice = () => {
    changePriceRange1(undefined);
    changePriceRange2(undefined);
    if (priceRef1.current) { priceRef1.current.value = ''; }
    if (priceRef2.current) { priceRef2.current.value = ''; }
    if (priceRef3.current) { priceRef3.current.value = ''; }
    if (priceRef4.current) { priceRef4.current.value = ''; }
  }

  function getHotProducts() {
    const q = `f=${0}&t=${30}&cids=${cid}&s=metrics.sales30Days`
    categoryDiscover(q)
      .then((res: any) => {
        const productList = res.products?.filter((product: any) => product.type == "PRODUCT") || []
        if (productList?.length) {
          setHotProducts(productList)
        }
      }).catch(err => {
      })
  }

  useEffect(() => {
    getHotProducts()
  }, [])

  function onChange(current: number) {
    setFrom(current - 1)
  }

  const onQueryInputChange = (e: any) => {
    const value = e.target.value.trim()
    setSearchInput(value)
  }

  const onSearch = () => {
    window.location.href = `https://www.bieyangapp.com/browse?q=${encodeURIComponent(searchInput)}`
  }

  return (
    <div className={cs.category}>
      {showLoading && <Loading />}
      {isPC ? (
        <CategorySeoComponent
          cid={cid}
          mappingTerm={mappingTerm}
          displayTerm={displayTerm}
          term={term}
          products={products}
          total={total}
        />
      ) : null }
      <ul className={cs.categoryStep}>
        {
          name.map((item: any, index: number) => {
            return (
              <li key={index}>
                <a href={item.path || void(0)}><span>{item.value}</span> </a>
                {
                  index < name.length - 1 ? <img className={cs.categoryStepArrow} src={categoryArrow} /> : null
                }
              </li>
            )
          })
        }
      </ul>
      {isPC ? <h1 className={cs.categoryTitle}>{mappingTerm || displayTerm || term}</h1> : null}
      <div className={cs.categoryBar}>
        <div className={cs.categoryBarLeft}>
          <div className={cs.categoryBarLeftSort}>
            <span className={cs.categoryBarLeftSortText}>{sort.label}</span>
            <div className={cs.categoryBarLeftSortHoverBg}>
              <ul className={cs.categoryBarLeftSortHover}>
                {
                  availableSorts.map((item: sort) => {
                    return <li
                      key={item.label}
                      className={sort.label === item.label ? cs.categoryBarLeftSortHoverCurrent : ''}
                      onClick={() => { changeSort(item) }}
                    >{item.label}</li>
                  })
                }
              </ul>
            </div>
          </div>
          <div className={cs.categoryBarLeftPrice}>
            <span>价格：</span>
            <input ref={priceRef1} type="text" name="最小价格" placeholder='$' value={priceRange1} autoComplete='off' />
            <span className={cs.categoryBarLeftPriceLine}></span>
            <input ref={priceRef2} type="text" name="最高价格" placeholder='$' value={priceRange2} autoComplete="off" />
            <div className={cs.categoryBarLeftPriceHover}>
              <div className={cs.categoryBarLeftPriceHoverTop}>
                <span>价格：</span>
                <input ref={priceRef3} type="text" name="最小价格" placeholder='$' value={priceRange1}
                  onChange={(e) => { changeInput(e, changePriceRange1, 'price') }}
                  autoComplete="off" />
                <span className={cs.categoryBarLeftPriceLine}></span>
                <input ref={priceRef4} type="text" name="最高价格" placeholder='$' value={priceRange2}
                  onChange={(e) => { changeInput(e, changePriceRange2, 'price') }}
                  autoComplete="off" />
              </div>
              <div className={cs.categoryBarLeftPriceHoverBtns}>
                <span onClick={resetPrice}>清除</span><span onClick={onSave}>确定</span>
              </div>
            </div>
          </div>
          <div className={cs.categoryBarLeftDiscount}>
            <span>折扣：</span>
            <input ref={discountRef1} type="text" name='最低折扣' placeholder='0折' value={discountRange1 && discountRange1 !== '0折' ? discountRange1 + '折' : discountRange1} autoComplete="off" />
            <span className={cs.categoryBarLeftDiscountLine}></span>
            <input ref={discountRef2} type="text" name='最高折扣' placeholder='全价' value={discountRange2 && discountRange2 !== '全价' ? discountRange2 + '折' : discountRange2} autoComplete="off" />
            <div className={cs.categoryBarLeftDiscountHover}>
              <div className={cs.categoryBarLeftDiscountHoverTop}>
                <span>折扣：</span>
                <input maxLength={3} ref={discountRef3} type="text" name='最低折扣' placeholder='0折' value={discountRange1}
                  onChange={(e) => { changeInput(e, changeDiscountRange1, 'discount') }}
                  autoComplete="off" />
                <span className={cs.categoryBarLeftDiscountLine}></span>
                <input maxLength={3} ref={discountRef4} type="text" name='最高折扣' placeholder='全价' value={discountRange2}
                  onChange={(e) => { changeInput(e, changeDiscountRange2, 'discount') }}
                  autoComplete="off" />
              </div>
              <div className={cs.categoryBarLeftDiscountHoverBtns}>
                <span onClick={resetDiscount}>清除</span><span onClick={onSave}>确定</span>
              </div>
            </div>
          </div>
        </div>
        <div className={cs.categoryBarRight}>共{total}件商品</div>
      </div>
      <section className={cs.categorySection}>
        {
          products?.length ? products?.map((item: any, idx: number) => {
            return (
              <ProductItem item={item} index={idx} key={idx} />
            )
          }) : (
            <Empty description='暂无数据' imageStyle={{ width: 128 }} style={{ width: '100%', height: '300px' }} />
          )
        }
      </section>
      {isPC ? <Pagination current={from + 1} onChange={onChange} totalNum={total} pageSize={size} /> : null}
      <section className={cs.searchNavSection}>
        {recBrands?.length ? (
          <div className={cs.searchNavRelevantKeywords}>
            <p>您是不是要找:</p>
            {recBrands?.map((screenBrand: any, index: number) => {
              return (
                <a key={index} className='search-related-keywords' href={`/browse?q=${encodeURIComponent(screenBrand.display)}`}><p>{screenBrand.display}</p></a>
              )
            })}
          </div>
          ) : null}
        <div className={cs.searchBarWrapper}>
          <input onChange={onQueryInputChange} value={searchInput} className={cs.searchBar} type="text" name="keyword" placeholder={mappingTerm || displayTerm || term} autoComplete="off" />
          <div className={cs.searchBarBtn} onClick={onSearch}>搜索</div>
        </div>
      </section>
      {hotProducts?.length ? (
        <section className={cs.hotProductSection}>
          <h2 className={cs.mainTitle}>大家都在买</h2>
          <h3 className={cs.subTitle}>{mappingTerm || displayTerm || term || ''}最热门商品推荐</h3>
          <div className={cs.hotProductList}>
            <Swiper
              className={cs.hotProductSwiper}
              loop
              slidesPerView={5}
              spaceBetween={20}
              navigation
              autoplay={{
                delay: 3000,
              }}
            >
              {
                hotProducts?.map((item: any, idx: number) => {
                  return (
                    <SwiperSlide
                      key={idx}
                      className={cs.hotProduct}
                    >
                      <ProductItem showTags={false} item={item} index={idx} key={idx} />
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
          </div>
        </section>
      ) : null}
    </div>
  )
}

export default CategoryPC
