import React, { useContext } from 'react';
import { ProductContext } from '@components/Context';
import Drawer from '@components/Drawer';
import './features-drawer.scss';

interface FeaturesDrawerProps {
    visible: boolean,
    onClose: (event: React.MouseEvent) => void
}

function FeaturesDrawer(props: FeaturesDrawerProps) {
    const productContext: any = useContext(ProductContext);

    return (
        <Drawer
            visible={props.visible}
            onClose={props.onClose}
            className='m-features-drawer'
            hasCloseBtn={true}
            id="C-Features-Drawer"
        >
            <div className='title'>服务</div>
            <div className='feature-wrap'>
                {productContext?.product?.featureGroup?.features?.map((item: any, index: number) => {
                    return (
                        <div className='feature-item' key={index}>
                            <div className='label'>{item.name}</div>
                            <div className='desc'>{item.desc}</div>
                        </div>
                    )
                })}
            </div>
        </Drawer>
    )
}

export default FeaturesDrawer;