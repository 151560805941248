import React, { useState, useEffect, Children, cloneElement, isValidElement } from 'react'
import { WithAnchorProvider, useAnchor, getOffsetPosition } from './context'
import PropTypes from 'prop-types'
import _ from 'lodash'
import * as styles from './Anchor.module.scss'
const Anchor = ({ children }) => {
  const { anchorList, activeIndex, scrollIntoView, headerEl } = useAnchor()
  const [headerState, setHeaderState] = useState('relative') // relative || 'fixed'
  
  const handClick = (elRefObj) => {
    scrollIntoView(elRefObj)
  }

  useEffect(() => {
    const windowScrollHandler = _.throttle(() => {
      const { top } = getOffsetPosition(headerEl.current)
      if(top <= 0) {
        setHeaderState('fixed')
      }else {
        setHeaderState('relative')
      }
    }, 300)
    windowScrollHandler()
    window.addEventListener('scroll', windowScrollHandler)
    return () => {
      window.removeEventListener('scroll', windowScrollHandler)
    }
  }, [])

  return (
    <>
      <div className={styles.anchorHeaderContainer} ref={headerEl}>
        <ul className={`${styles.anchorHeader} ${headerState === 'fixed' ? styles.fixed : ''}`}>
          {
            anchorList.map((item, index) => (
              item ? (
                <li 
                  key={index}
                  className={+activeIndex === index ? styles.active : ''}
                  onClick={() => handClick(item.elRefObj)}
                >
                  {item.title}
                </li>
              ) : null
            ))
          }
        </ul>
      </div>
      {
        Children.map(children, (child, index) => 
          isValidElement(child) ? cloneElement(child, { index }) : child)
      }
    </>
  )
}

Anchor.propTypes = {
  children: PropTypes.node
}

Anchor.displayName = "Anchor"

export default WithAnchorProvider(Anchor)