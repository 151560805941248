import React, { useContext, useState } from 'react';
import { ProductContext } from '@components/Context';
import RecommendProduct from '@modules/Product/RecommendProduct';
import MerchantShippingDrawer from '@modules/Merchant/MerchantShippingDrawer';
import './merchant-info.scss'; 
import { getImageUrl, logoImageUrl } from '@/lib/utils';

function MerchantInfo() {
    const productContext: any = useContext(ProductContext);
    const [visible, setVisible] = useState(false);
    const { merchant } = productContext;
    if (!merchant?.name) return null;
    return (
        <div className='m-merchant-info'>
            <div className='merchant-name'>商场 {merchant?.name}</div>
            <div className='merchant-info' style={{backgroundImage: `url(${merchant?.images?.[1]?.full?.url})`}}>
                <img className='logo' src={getImageUrl(merchant?.images?.[0]?.full?.url) || logoImageUrl} alt={`商家${merchant?.name}图标`} />
                <div className='info'>
                    <p className='specialties'>{merchant?.specialties}</p>
                    {merchant?.costInfo && <div className='costInfo' onClick={() => setVisible(true)}>{merchant?.costInfo?.summary}</div>}
                </div>
            </div>
            <div className='merchant-recommend-product'>
                {merchant?.recommendProducts?.map((item: any, index: number) => {
                    return (
                        <RecommendProduct product={item} key={`${item.id}_${index}`} />
                    )
                })}
            </div>
            {merchant?.costDetails && 
                <MerchantShippingDrawer
                    visible={visible}
                    onClose={() => setVisible(false)}
                    merchantName={merchant?.name}
                    costDetails={merchant?.costDetails}
                />}
        </div>
    )
}

export default MerchantInfo;