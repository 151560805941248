import React from 'react';
import ReactDom from 'react-dom';
import './toast.scss';

function Toast(configInput: any, callback?: any) {
    let config = configInput;
    let toastEle = document.getElementById('J-toast');
    if (!toastEle) {
        const body = document.body;
        toastEle = document.createElement('div');
        toastEle.id = 'J-toast';
        body.appendChild(toastEle);
    } else {
        ReactDom.unmountComponentAtNode(toastEle);
    }

    if (typeof config === 'string') {
        config = {
            text: config,
        };
    }
    config.time = config.time || 2000;
    ReactDom.render(
        <ToastContainer
            elem={toastEle}
            config={config}
            callback={callback}
        />, toastEle);
}

export default Toast;

class ToastContainer extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        const config = this.props.config;

        setTimeout(() => {
            ReactDom.unmountComponentAtNode(this.props.elem);
            this.props.callback && this.props.callback();
        }, config.time);
    }

    render() {
        const { toastName, maskVisible } = this.props.config;
        const toastClassName = toastName || '';
        const maskClassName = maskVisible === false ? 'mask-invisible' : '';
        return (
            <div className={toastClassName}>
                <div className="c-toast">
                    {this.props.config.text}
                </div>
                <div className={`toast-mask ${maskClassName}`} />
            </div>
        );
    }
}
