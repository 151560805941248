import React from 'react';
import './download.scss';

function Download(props: any) {
    const { qrcodeImg, amount } = props;

    return (
        <div className={`m-download ${props.className || ''}`}>
            <img className='qr-code' src={qrcodeImg} />
            <div className='text'>扫码领<span className='red'>{amount}</span>新人大礼包</div>
        </div>
    )
}

export default Download;