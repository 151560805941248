import React, { useMemo } from 'react';
import * as styles from './Brand.module.scss';
import ProductItem from '../ProductItem';
import { getImageUrl, logoImageUrl } from '@/lib/utils';

const Brand = ({ data = {} }) => {
  const { name, icon, images, recommendProducts = [], descriptions, nameCN } = data || {}
  const _description = useMemo(() => {
    return Array.isArray(descriptions) ? descriptions.join('\n') : descriptions
  }, [descriptions])
  if (!name) return null
  return (
    <div className={styles.brand}>
      <div className={styles.header}>
        <img src={getImageUrl(icon?.full?.url || images?.[0]?.full?.url || images?.[0]?.thumbnail?.url) || logoImageUrl} alt={`${nameCN || name}品牌, ${_description}`} />
        <div className={styles.info}>
          <div>
            <div className={styles.name}>{ name }</div>
          </div>
          <div className={styles.specialties}>
            { nameCN || name }
          </div>
        </div>
      </div>
      <div className={styles.descriptions}>
        {_description}
      </div>
      <div className={styles.productList}>
        {
          recommendProducts?.slice(0, 16)?.map?.(item => (
            <ProductItem
              key={item.id}
              product={item}
              name={name}
            />
          ))
        }
      </div>
    </div>
  )
}

export default Brand