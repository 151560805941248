import * as React from 'react'
import * as hs from './header_m.module.scss'
import Logo from '@images/logo_black.png'
import CloseWhite from '@images/ic_close_white.png'
import HeaderMRight from '@/images/header_m_right.png'
// import { Link } from 'gatsby'
import { isBrowser } from '@/lib/utils'
const HeaderM = () => {
  const [visible, setVisible] = React.useState(false)
  const currentPath = isBrowser ? window.location.pathname : ''
  return (
    <header className={hs.headerM} id='header-m'>
      <div className={hs.lMHeader}>
        <img className={hs.lMHeaderLogo} src={Logo} alt="logo_m" />
        <div className={`${hs.lMHeaderRight} ${visible ? hs.mHeaderShow : ''}`}>
          <img className={hs.lMHeaderRightImg} src={HeaderMRight} alt="折叠菜单按钮" onClick={() => { setVisible(true) }} />
          <ul className={hs.lMHeaderMenu} onClick={() => setVisible(false)}>
            <img className={hs.lMHeaderClose} src={CloseWhite} alt="close" />
            <li><a className={currentPath == '/' ? hs.activeMenu : ''} href='/'>首页</a></li>
            <li><a className={(currentPath?.indexOf('/category') !== -1) ? hs.activeMenu : ''} href='/category/8/?from=menu'>商品一览</a></li>
            <li><a className={currentPath?.indexOf('/merchants') !== -1 ? hs.activeMenu : ''} href='/merchants/'>商家精选</a></li>
            <li><a className={currentPath?.indexOf('/haul') !== -1 ? hs.activeMenu : ''} href='/haul/'>晒单精选</a></li>
            <li><a className={currentPath?.indexOf('/articles') !== -1 ? hs.activeMenu : ''} href='/articles/'>文章精选</a></li>
            <li><a className={currentPath?.indexOf('/about') !== -1 ? hs.activeMenu : ''} href='/about/'>联系我们</a></li>
            <li><a className={currentPath?.indexOf('/help') !== -1 ? hs.activeMenu : ''} href='/help/'>帮助文档</a></li>
            <li><a className={currentPath?.indexOf('/privacy') !== -1 ? hs.activeMenu : ''} href='/privacy/'>隐私政策</a></li>
          </ul>
        </div>
      </div>
      <div className={hs.lMHeaderContainer}  id='l-m-header-container'>
      </div>
    </header>
  )
}

export default HeaderM