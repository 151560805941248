import React from 'react';
import './carousel.scss';

interface CarouselProps {
    list: Array<any>,
    period: number,
    autoPlay: boolean,
}

interface CarouselState {
    inIndex: number,
    outIndex: number,
    playing: Boolean,
    direction: string // left,right,
    list: Array<any>,
}

interface ContentProps extends CarouselProps {
    carouselItem: any,
    index: number
}

function Carousel(Content: React.ComponentType<ContentProps>) {
    class CarouselContent extends React.Component<CarouselProps, CarouselState> {
        static defaultProps: CarouselProps;
        _timer: number | null;
        _startTouchX: number;
        constructor(props: CarouselProps) {
            super(props);
            this.state = {
                inIndex: 0,
                outIndex: -1,
                playing: false,
                direction: 'left',
                list: props.list
            }
            this._timer = null;
            this._startTouchX = 0;
            this._touchStartHandler = this._touchStartHandler.bind(this);
            this._touchEndHandler = this._touchEndHandler.bind(this);
            this._playSlice = this._playSlice.bind(this);
        }

        static getDerivedStateFromProps(props: CarouselProps, state: CarouselState) {
            if (JSON.stringify(props.list) != JSON.stringify(state.list)) {
                return {
                    inIndex: 0,
                    outIndex: -1,
                    playing: false,
                    direction: 'left',
                    list: props.list
                }
            }
            return null;
        }

        componentDidMount() {
            const { autoPlay, list } = this.props;
            if (autoPlay && list.length > 1) {
                this._startPlay();
            }
        }

        componentWillUnmount() {
            this._pausePlay();
            this.setState({
                inIndex: 0,
                outIndex: -1,
                playing: false,
                direction: 'left'
            });
        }

        _startPlay() {
            this._timer = setInterval(this._playSlice, this.props.period * 1000);
        }
    
        _pausePlay() {
            if (this._timer) {
                clearInterval(this._timer);
            }
        }

        _playSlice(direction = 'left') {
            let nextInIdx;
            let nextOutIdx;
            let playing = true
            const length = this.props.list.length;
            if (direction == 'left') {
                nextInIdx = (this.state.inIndex + 1) % length;
                nextOutIdx = this.state.inIndex % length;
            } else if (direction == 'right') {
                let inIdx = this.state.inIndex - 1;
                while (inIdx < 0) {
                    inIdx += length;
                }
                nextInIdx = inIdx;
                nextOutIdx = this.state.inIndex % length;
            } else {
                nextInIdx = 0;
                nextOutIdx = -1;
                playing = false;
            }
            this.setState({
                inIndex: nextInIdx,
                outIndex: nextOutIdx,
                playing: playing,
                direction: direction
            });
        }

        _touchStartHandler(e: React.TouchEvent<HTMLDivElement>) {
            try {
                this._startTouchX = e.changedTouches[0].pageX;
            } catch (e) {
            }
        }

        _touchEndHandler(e: React.TouchEvent<HTMLDivElement>) {
            try {
                const moveX =  e.changedTouches[0].pageX - this._startTouchX;
                if (moveX > 30) {
                    //向右划
                    if (this.props.list.length > 1) {
                        this._pausePlay();
                        this._playSlice('right');
                        this.props.autoPlay && this._startPlay();
                    }
                } else if (moveX < -30) {
                    //向左划
                    if (this.props.list.length > 1) {
                        this._pausePlay();
                        this._playSlice('left');
                        this.props.autoPlay && this._startPlay();
                    }
                } else {
                    // this._gotoLink();
                }
            } catch (e) {
            }
        }

        render() {
            const { list } = this.props;
            const { inIndex, outIndex, direction } = this.state;
            return (
                <div className='c-carousel'>
                    <div className="carousel-slice" onTouchStart={this._touchStartHandler} onTouchEnd={this._touchEndHandler}>
                        {list && list.map((item, index) => {
                            if (this.state.playing) {
                                if(index === outIndex) {
                                    return (
                                        <div className={`carousel-slice-item carousel-slice-${direction}-out`} key={index}>
                                            <Content {...this.props} carouselItem={item} index={index} />
                                        </div>
                                    )
                                } else if (index === inIndex) {
                                    return (
                                        <div className={`carousel-slice-item carousel-slice-${direction}-in`} key={index}>
                                            <Content {...this.props} carouselItem={item} index={index} />
                                        </div>
                                    )
                                }
                            } else {
                                if(index === inIndex) {
                                    return (
                                        <div className='carousel-slice-item' key={index}>
                                            <Content {...this.props} carouselItem={item} index={index} />
                                        </div>
                                    ) 
                                }
                            }
                        })}
                    </div>
                    <div className="carousel-dot-wrap">
                        <div className="carousel-dot">
                        {list && (list.length > 1) && list.map((item, index) => {
                                let active = '';
                                if (index == this.state.inIndex) {
                                    active = 'item-active'
                                }
                                return (
                                    <span className={`carousel-dot-item ${active}`} key={index}></span>
                                );
                            })
                        }
                        </div>
                    </div>
                </div>
            )
        }
    }
    CarouselContent.defaultProps = {
        list: [],
        period: 4,
        autoPlay: false
    }
    return CarouselContent;
}

export default Carousel;