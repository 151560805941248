import React, { memo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useAnchor  } from './context'
const AnchorItem = ({ title, children, index }) => {
  const el = useRef()
  const { addAnchor, removeAnchor } = useAnchor()

  useEffect(() => {
    addAnchor(index, {
      elRefObj: el,
      title
    })
    return () => {
      removeAnchor(index)
    }
  }, [addAnchor, removeAnchor, title])

  return (
    <div ref={el}>
      { children }
    </div>
  )
}

AnchorItem.displayName = 'AnchorItem'

AnchorItem.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
}

export default memo(AnchorItem)