import React, { useState, useEffect, useRef } from 'react';
import arrow from '@images/arrow-white.png';
import shadow from '@images/shadow.png';
import shadowM from '@images/img_shadow.png';
import Product from '@modules/Home/Product';
import tab1Img from '@images/tab1Img.png'
import tab2Img from '@images/tab2Img.png'
import tab3Img from '@images/tab3Img.png'
import tab4Img from '@images/tab4Img.png'
import tab5Img from '@images/tab5Img.png'
import tab6Img from '@images/tab6Img.png'
import tab7Img from '@images/tab7Img.png'
import tab8Img from '@images/tab8Img.png'
import Download from '@modules/Common/Download';
import './brand.scss';
import { useMediaQuery } from "react-responsive"

const tabMainImg: any = {
    'beauty_skincare': tab1Img,
    'mens_shoes': tab2Img,
    'womens_shoes': tab3Img,
    'children': tab4Img,
    'womens_bag': tab5Img,
    'womens_clothes': tab6Img,
    'mens_clothes': tab7Img,
    'accessory': tab8Img
}

function Brand(props: any) {
    const { brands } = props || {};
    const [active, setActive] = useState(0);
    const [visible, setVisible] = useState(false);
    const menuRef = useRef(null)
    const menuContainerRef = useRef(null)
    const isMobile = useMediaQuery({ query: "(max-width: 960px)" })

    function handleClick(index: number) {
        setActive(index);
    }

    function handleBrandItemClick(e: any) {
        // if (isMobile()) return
        // e.preventDefault()
        // setVisible(true)
    }

    useEffect(() => {
        setActive(0);
    }, [brands])

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (menuRef.current && menuContainerRef.current) {
                const scrollTop = window.pageYOffset
                const menu: HTMLElement = menuRef.current
                const mBrand: HTMLElement = menuContainerRef.current
                const windowHeight = window.innerHeight
                const { height: menuHeight } = menu.getBoundingClientRect()
                const { offsetTop, offsetHeight } = mBrand
                // 当（滚动的高度 >  mBrand.offsetTop + （屏幕高度 - 盒子高度 ）/  2 ）
                // 并且 滚动的高度 < mBrand距离顶部的高度 + mBrand自己的高度 - （屏幕高度 - 盒子高度 ）/  2
                // 开始fixed. 否则恢复默认
                if (scrollTop > offsetTop && scrollTop < offsetTop + offsetHeight - windowHeight) {
                    menu.className = 'menu-content menu-content-fixed'
                } else {
                    menu.className = 'menu-content'
                }
            }
        })

        return window.removeEventListener('scroll', () => { })
    }, [])

    return (
        <div className='m-brand' ref={menuContainerRef}>
            <div className='menu'>
                <div className='menu-content' ref={menuRef}>
                    <div className='decoration'></div>
                    <h2 className='main-title'>精选大牌</h2>
                    <h3 className='sub-title'>一站式海淘体验</h3>
                    {brands?.map((brand: any, idx: number) => {
                        const isActive = active === idx;
                        return (
                            <div
                                className={`menu-item ${isActive ? 'active' : ''}`}
                                key={brand.id}
                                onClick={() => handleClick(idx)}
                            >
                                {brand.title}
                            </div>
                        )
                    })}
                </div>
                <div className='menu-content-m'>
                    <h2 className='main-title'>精选大牌</h2>
                    <h3 className='sub-title'>一站式海淘体验</h3>
                    <div className='menu-items'>
                        {brands?.slice(0, 6).map((brand: any, idx: number) => {
                            const isActive = active === idx;
                            return (
                                <div
                                    className={`menu-item ${isActive ? 'active' : ''}`}
                                    key={brand.id}
                                    onClick={() => handleClick(idx)}
                                >
                                    {brand.title}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            {
                brands?.map((brand: any, index: number) => {
                    return (
                        <div className={active === index ? 'content' : 'content content_disable'} key={'brand_' + index}>
                            <div className='content-top'>
                                <img src={tabMainImg?.[brand.id]} className='brand-banner' />
                                <div className='brand-detail'>
                                    <h3 className='main-title'>{brand.title}</h3>
                                    <h4 className='sub-title'>{brand?.id?.split('_')?.join(' ')?.toUpperCase()}</h4>
                                    <div className='brand-list'>
                                        {brand?.brands?.map((brand: any, i: number) => {
                                            return (
                                                <a
                                                    title={brand.name}
                                                    href={brand.pathname ? `/browse/${brand.pathname}/` : `/browse?q=${encodeURIComponent(brand.name)}` }
                                                    className='brand-item'
                                                    key={`${brand.name}_${i}`}
                                                    onClick={handleBrandItemClick}
                                                >
                                                    <img src={brand.image} className='brand-icon' alt={`${brand.name}品牌图标`} />
                                                    <div className='brand-name'>{brand.name}</div>
                                                </a>
                                            )
                                        })}
                                    </div>
                                    <div className='more-brand' onClick={() => setVisible(true)}>
                                        <img src={arrow} />
                                        <span>查看全部</span>
                                    </div>
                                    <div className='more-brand-m' onClick={() => setVisible(true)}>
                                        <img src={arrow} />
                                        <span>下载别样APP</span>
                                    </div>
                                    <img className='decoration' src={isMobile ? shadowM : shadow} />
                                </div>
                                <div className='block-content'></div>
                            </div>
                            <div className='content-bottom'>
                                {brand?.productList?.map((product: any, idx: number) => {
                                    return <Product key={idx} product={product} />
                                })}
                            </div>
                        </div>
                    )
                })
            }
            {visible && <Download onClose={() => setVisible(false)} />}
        </div >
    )
}

export default Brand;