import { createContext } from 'react'

export const LayoutContext = createContext({});

export const LayoutProvider = LayoutContext.Provider;

export const ProductContext = createContext({});

export const ProductProvider = ProductContext.Provider;

export const SKUContext = createContext({});

export const SKUProvider = SKUContext.Provider;

export const CartContext = createContext({});

export const CartProvider = CartContext.Provider;

export const GlobalContext = createContext({});

export const GlobalProvider = GlobalContext.Provider;

export const CheckoutContext = createContext({});

export const CheckoutProvider = CheckoutContext.Provider;

export const AccountContext = createContext({});

export const AccountProvider = AccountContext.Provider;