// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--40e75";
export var footerBottom = "footer-module--footer-bottom--e1431";
export var footerNavItem = "footer-module--footer-nav-item--b10f5";
export var footerNavs = "footer-module--footer-navs--2bda2";
export var footerTop = "footer-module--footer-top--751ee";
export var footerTopQrcode = "footer-module--footer-top-qrcode--58099";
export var footerTopQrcodeBox = "footer-module--footer-top-qrcode-box--d891c";
export var footerTopQrcodeBoxImg = "footer-module--footer-top-qrcode-box-img--337a1";
export var footerTopQrcodeText = "footer-module--footer-top-qrcode-text--23798";
export var footerTopQrcodeTextHighlight = "footer-module--footer-top-qrcode-text-highlight--78f3d";
export var footerTopRight = "footer-module--footer-top-right--7d253";
export var footerTopRightAddress = "footer-module--footer-top-right-address--0372c";
export var footerTopRightDescription = "footer-module--footer-top-right-description--723ba";
export var footerTopRightLogo = "footer-module--footer-top-right-logo--02384";
export var outlinks = "footer-module--outlinks--aa908";