import React from 'react';
import ReviewDetailComponent from '@/templates/review/haul-detail';
import { getHaulDetail } from '@/service/api';

function ProductPage({ serverData }: any) {
  return (
    <ReviewDetailComponent pageContext={serverData} />
  )
}

export async function getServerData(context: any) {
  try {
    if (!context.params?.['idx']) {
      return {
        status: 404,
        props: {
          footer: true,
        },
      }
    }
    const slug = context.params?.['idx']
    const idx = slug.split('/')
    const productId = idx[0]
    const commentId = idx[1]
    if (!productId || !commentId) {
      return {
        status: 404,
        props: {
          footer: true,
        },
      }
    }
    const reviewRes: any = await getHaulDetail(productId, commentId)
    let headers = {}
    if (!!reviewRes?.id) {
      headers = {
        'Cache-Control': 'public, max-age=600, s-maxage=86400, stale-while-revalidate=3600',
      }
    } else {
      headers = {
        'Cache-Control': 'max-age=0, no-cache, must-revalidate',
      }
    }

    return {
      headers,
      props: {
        info: reviewRes?.comments?.[0] || {},
        renderingMode: !!reviewRes?.id ? 'SSR' : undefined,
        footer: true,
      },
    }
  } catch (e) {

  }
  return {
    headers: {
      'Cache-Control': 'max-age=0, no-cache, must-revalidate',
    },
    props: {
      footer: true,
    }
  }
}

export default ProductPage
