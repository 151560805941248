import React from 'react'
import './index.scss'
import closeBlack from '@images/ic_close_BgModul.png'
interface IBgProps {
    closeMolul: () => void
    activeText: string | undefined
    children: any
}
class BgModul extends React.Component<IBgProps, any> {
    readonly module = React.createRef<HTMLDivElement>()
    componentDidMount() {
        // this.module.current?.addEventListener('touchmove', e => {
        //     e.stopPropagation()
        // })
        let node: HTMLDivElement | null = document.querySelector('.App')
        node?.classList.add('over')
    }
    componentWillUnmount() {
        let node: HTMLDivElement | null = document.querySelector('.App')
        // this.module.current?.removeEventListener('touchmove', e => {
        //     e.stopPropagation()
        // })
        node?.classList.remove('over')
    }
    render() {
        const { children, closeMolul, activeText } = this.props
        return (
            <div ref={this.module} className="bgModul">
                <div className="contentHeader">
                    <div className='content-text'>{activeText}</div>
                    <div className='closeBox' onClick={closeMolul}><img src={closeBlack} alt="" /></div>
                </div>
                <div
                    className="filterModul"
                    onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                >
                    {children}
                </div>
            </div>
        )
    }
}

export default BgModul
