import * as React from 'react'
import './privacy.scss'
import SEO from '../templates/seo'

const privacy = () => {
  return (
    <>
      <SEO
        title={'隐私政策 - 别样海外购'}
        description='别样海外购（Bieyangapp.com）是一款欧美时尚商品海淘直购APP, 汇聚欧美上百家高端百货店, 新潮买手店, 以及数千奢品时尚品牌, 全球实时帮你抢好货! 24小时中文同步欧美商家的海量优惠: 折扣、小样、礼包, 一个都不少。实时播报, 机器人极速下单, 全球官网正品一键直邮到家!'
        keywords={'别样App,别样,别样海外购,海淘'}
        canonical='https://www.bieyangapp.com/privacy'
      />
      <iframe title='隐私政策 - 别样海外购' className='p-privacy' src='https://www.bieyangapp.com/static/html/privacy.html'></iframe>
    </>
  )
}

export default privacy
