import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import './index.scss'
import {
  Thumbs,
  Navigation
} from 'swiper';
import 'swiper/scss';
import { getImageUrl } from '@lib/utils';

const navigationOptions = {
  nextEl: '.thumbs-swiper-arrow.next',
  prevEl: '.thumbs-swiper-arrow.prev',
  disabledClass: 'thumbs-swiper-arrow-disabled'
}

const modulesOptions = [Thumbs, Navigation]

const Preview = ({ images, product }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState()
  const [activeIndex, setActiveIndex] = useState(0)
  const onSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex)
  }

  return (
    <div className='preview'>
      <Swiper 
        thumbs={{
          swiper: thumbsSwiper,
          multipleActiveThumbs: false,
        }}
        className="gallery-swiper"
        modules={modulesOptions}
        navigation={navigationOptions}
        initialSlide={0}
        onSlideChange={onSlideChange}
      >
        {
          images?.map((item, index) =>(
            <SwiperSlide 
              key={item.id}
              className="gallery-swiper-item"
            >
              <img src={getImageUrl(item?.full.url || item?.thumbnail?.url)} alt={`${product?.nameCN || product?.name || ''} 商品`} />
            </SwiperSlide>
          ))
        }
      </Swiper>
      <div className="thumbs-swiper-container">
        <div className="thumbs-swiper-arrow prev" />
        <Swiper 
          slidesPerView={5}
          onSwiper={setThumbsSwiper}
          freeMode
          watchSlidesProgress
          className="thumbs-swiper"
        >
          {
            images?.map((item, index) =>(
              <SwiperSlide 
                key={item.id}
                className={
                  `thumbs-swiper-item ${activeIndex === index ? "thumbs-swiper-item-active" : ''}`
                }
              >
                <div
                  className="thumbs-slider-item-img"
                >
                  <img src={getImageUrl(item?.full.url || item?.thumbnail?.url)} alt={`${product?.nameCN || product?.name || ''} 商品`} />
                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>
        <div className="thumbs-swiper-arrow next" />
      </div>
    </div>
  )
}

Preview.displayName = 'Preview'

const imageType = PropTypes.shape({
  url: PropTypes.string,
  format: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
})

Preview.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    originalURL: PropTypes.string,
    blob: PropTypes.number,
    full: imageType,
    thumbnail: imageType
  }))
}

Preview.defaultProps = {
  images: []
}

export default Preview