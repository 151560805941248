import React from 'react';
// import { Link } from 'gatsby';
import './product.scss';
import { getImageUrl } from '@/lib/utils';

function Product(props: any) {
    const { product } = props || {};
    const { originalPriceTag, priceTag, images, id, nameCN, displayBrand, brandCN, brand, name, priceTagCN } = product?.product || product?.searchProduct?.product || product || {}
    const { merchantName, merchantOrigin, labelView } = product?.searchProduct || product?.product || {}
    const brandSection = displayBrand || brandCN || brand || ''
    return (
        <a className='product-item' href={`/pd/${id}/`}>
            <div className='product-img'>
                <img src={getImageUrl(images?.[0]?.thumbnail?.url || images?.[0]?.full?.url)} alt={`${brandSection ? `${brandSection}品牌, ` : ''}商品${nameCN || name}, 价格${priceTagCN}`} />
            </div>
            <div className='product-info'>
                <h2 className='product-name'>{brandSection ? `${brandSection} | ` : ''}{nameCN || name}</h2>
                <div className='product-origin'>
                    [{merchantOrigin || ''}]{merchantName || ''}
                </div>
                <div className='product-price'>
                    <h3 className={`product-price-cn ${originalPriceTag ? 'blue' : ''}`}>{priceTagCN}</h3>
                    <div className='product-price-en'>{priceTag}</div>
                    {originalPriceTag && <div className='product-price-origin'>{originalPriceTag}</div>}
                </div>
                <div className='product-tag'>
                    {labelView?.shortLabels?.length ? (
                        labelView?.shortLabels.map((shortLabel: any) => {
                            return shortLabel?.labels?.length ? shortLabel?.labels.map((label: any, index: number) => {
                                return (
                                    <div className='tag-item' key={`${label.text}_${index}`}>
                                        <span>{label.text || ''}</span>
                                        <span className='product-tag-line'></span>
                                    </div>
                                )
                            }) : null
                        })
                    ) : null}
                </div>
            </div>
        </a>
    )
}

export default Product;