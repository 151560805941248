import React from 'react';
import CommentItem from './CommentItem';
import * as styles from './Comment.module.scss';
import { openAppStore } from '@/lib/appstore';

function ProductComment(props: any) {
    const { comment } = props || {}

    function goToCommentList() {
        // Toast('请前往APP内查看更多精彩内容');
        openAppStore()
    }

    if(!comment?.comments?.length) {
      return null
    }

    return (
        <div className={styles.comment}>
            <div className={styles.title} onClick={goToCommentList}>
                商品评价({comment?.total})
            </div>
            <div className={styles.commentContainer}>
                {comment?.tagInfos && comment.tagInfos.length ? (
                  <div className={styles.tagWrap}>
                    {comment.tagInfos.map((item: any) => {
                        return (
                            <div className={styles.commentTag} key={item?.tagName} onClick={goToCommentList}>
                                { item?.tagName}({ item?.num})
                            </div>
                        )
                    })}
                  </div>
                ) : null}
                <div className={styles.commentWrap}>
                    {comment.comments.map((item: any, index: number) => {
                        return (
                            <CommentItem key={`comment_${index}`} comment={item} />
                        )
                    })}
                </div>
                <div className={styles.commentMore} onClick={goToCommentList}>
                  查看更多
                </div>
            </div>
        </div>
    )
}

export default ProductComment
