const Sentry = require('@sentry/browser');
const { BrowserTracing } = require('@sentry/tracing');
require('./src/style/common.scss');

exports.onClientEntry = function() {
  try {
    if (!window) return
    Sentry.init({
      tracesSampleRate: 0.2,
      integrations: [new BrowserTracing()],
      dsn: "https://a3508ba8553e47a190d270b944c32705@sentry.bybieyang.com/1",
    })
  } catch (e) {
    console.warn(e)
  }
}

exports.wrapPageElement = require('./gatsby-share');
