
import React from 'react'
import Pagination from 'rc-pagination';
import './index.scss'

const Paginate = (props) => {
  const { pageSize, current, onChange, totalNum, onNavLink, ...rest } = props || {}
  const itemRender = (current, type, element) => {
    if (type === 'prev') {
      return <div className='prev-page'>上一页</div>;
    }
    if (type === 'next') {
      return <div className='next-page'>下一页</div>;
    }
    if (type === 'jump-prev' || type === 'jump-next') {
      return '...'
    }
    if (type === 'page' && onNavLink) {
      return <a href={onNavLink(current)}>{current}</a>
    }
    return element;
  };

  return (
    <Pagination
      {...rest}
      defaultPageSize={pageSize || 10}
      current={current}
      onChange={onChange}
      total={totalNum}
      itemRender={itemRender}
    />
  )
}


export default Paginate