import  { useContext, useMemo } from 'react'
import { LayoutContext } from '@/components/Context';

const getTerm = (cid, children, result = []) => {
  if(!children?.length || !cid) {
    return []
  }
  return children?.reduce?.((initival, item) => {
    if (cid.startsWith(item.category.id)) {
      initival.push({
        cid: item.category.id,
        term: item.category.displayTerm || item.category.term,
      });
      getTerm(cid, item.children, result);
    }
    return initival
  }, result)
}

const useCateName = (categoryIds) => {
  const { categories } = useContext(LayoutContext);
  const cid = categoryIds?.[0]
  return useMemo(() => getTerm(cid, categories.children), [cid, categories])
}

export default useCateName