// extracted by mini-css-extract-plugin
export var avatar = "index-module--avatar--bdd4d";
export var content = "index-module--content--016f3";
export var contentWrap = "index-module--content-wrap--31327";
export var filp = "index-module--filp--5d4fd";
export var icon = "index-module--icon--0781f";
export var img = "index-module--img--6e5c8";
export var likeNum = "index-module--like-num--c4cc2";
export var likes = "index-module--likes--cb130";
export var listBox = "index-module--list-box--c7263";
export var listPanel = "index-module--list-panel--d9398";
export var msgBox = "index-module--msg-box--b96ca";
export var name = "index-module--name--ef790";
export var nameWrap = "index-module--name-wrap--0325b";
export var relate = "index-module--relate--3e600";
export var relateDetail = "index-module--relate-detail--d0cea";
export var relatedReviewSwiper = "index-module--related-review-swiper--97c72";
export var relatedReviewSwiperItem = "index-module--related-review-swiper-item--d8c70";
export var title = "index-module--title--3b461";