import React, { useContext } from 'react';
import { ProductContext } from '@components/Context';
import RecommendProduct from '@modules/Product/RecommendProduct';
import './brand-info.scss';
import { getImageUrl, logoImageUrl } from '@/lib/utils';

function BrandInfo() {
    const productContext: any = useContext(ProductContext);
    const { brand } = productContext;
    if (!brand?.name) return null
    return (
        <div className='m-brand-info'>
            <div className='info'>
                <img className='logo' src={getImageUrl(brand?.icon?.full?.url) || logoImageUrl} alt={`品牌${brand?.nameCN || ''}${brand?.name || ''}图标`} />
                <div className='name'>
                    <div className='name-origin'>{brand?.nameCN || brand?.name}</div>
                    <div className='name-cn'>{brand?.name}</div>
                </div>
            </div>
            {brand?.descriptions?.length ? <p className='description'>{brand?.descriptions?.join()}</p> : null}
            <div className='brand-recommend-product'>
                {brand?.recommendProducts?.map((item: any, index: number) => {
                    return (
                        <RecommendProduct product={item} key={`${item.id}_${index}`} />
                    )
                })}
            </div>
        </div>
    )
}

export default BrandInfo;