import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  Navigation
} from 'swiper'
import * as Styles from './index.module.scss'
import 'swiper/scss'
import like from '@/images/like.png'
import down_black from '@/images/ic_arrow_down.png'
import down_gray from '@/images/ic_arrow_down_gray.png'
import up_black from '@/images/ic_arrow_up_black.png'
import up_gray from '@/images/ic_arrow_up_gray.png'
import { useMediaQuery } from "react-responsive"
// import { Link } from 'gatsby'

// const PAGE_SIZE = 5

const navigationOptions = {
  nextEl: '.related-review-swiper-next',
  prevEl: '.related-review-swiper-prev',
}

function Relate(props) {
  const { commentId, list } = props || {}
  const [pageState, setPageState] = useState(0)
  const isMobile = useMediaQuery({ query: "(max-width: 960px)" })
  if (!list) return null

  return (
    <div className={Styles.relate}>
      <h4 className={Styles.title}>相关晒单</h4>
      <div className={Styles.listPanel}>
        <div className={Styles.listBox}>
          <Swiper
            slidesPerView={isMobile ? 2.5 : 6.5}
            direction={isMobile ? 'horizontal': 'vertical'}
            watchSlidesProgress
            spaceBetween={12}
            modules={[Navigation]}
            navigation={navigationOptions}
            className={Styles.relatedReviewSwiper}
            onSlideChange={(swiper) => {
              if (swiper.isBeginning) {
                setPageState(0)
              } else if (swiper.isEnd) {
                setPageState(1)
              } else {
                setPageState(-1)
              }
            }}
          >
            {
              list.filter(item => item.commentId !== commentId).map((item) =>(
                <SwiperSlide
                  key={item.commentId}
                  className={Styles.relatedReviewSwiperItem}
                >
                  <a href={`/review/${item.productId}/${item.commentId}/`}>
                    <div className={Styles.relateDetail}>
                      <img className={Styles.img} src={item.image?.thumbnail?.url || item.image?.full?.url || ''} alt={`${item.userLabel}评价:${item.content}` || "相关晒单图片"} />
                      <div className={Styles.contentWrap}>
                        <div className={Styles.content}>{item.content || '默认好评'}</div>
                        <div className={Styles.msgBox}>
                          <div className={Styles.nameWrap}>
                            <img className={Styles.avatar} src={item.userAvatar} alt="" />
                            <div className={Styles.name}>{item.userLabel}</div>
                          </div>
                          <div className={Styles.likes}>
                            <img className={Styles.icon} src={like} alt="点赞图标" />
                            <div className={Styles.likeNum}>{item.likes}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
        {!isMobile ? (
          <div className={Styles.filp}>
          <img className="related-review-swiper-prev" src={pageState === 0 ? up_gray : up_black} alt="prev" />
          <img className="related-review-swiper-next" src={pageState === 1 ? down_gray : down_black} alt="next" />
        </div>
        ) : null}
      </div>
    </div>
  )
}

export default Relate