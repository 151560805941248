import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { Empty } from "antd-mobile"
import Pagination from "@/modules/Common/Pagination"
import Breadcrumb from "@/modules/productPc/Breadcrumb"
import ArticleItem from "@/modules/Article/ArticleItem"
import FloatingWidget from "@/components/FloatingWidget"
import { getArticles } from "@/service/api"
// import { Link } from "gatsby"
import * as Styles from './article.module.scss'
import Loading from '@/layouts/Loading'
import { parseLegoId } from '@/lib/utils'
import { useMediaQuery } from 'react-responsive'
import SEO from './seo'

interface IArticlesProps {
  pageContext: {
    total: number,
    articles: any[]
    tag: string
    label: string
  }
}

const tabs = {"tags":[{"tag":"0","label":"最新"},{"tag":"20","label":"奢品"},{"tag":"15","label":"时尚"},{"tag":"19","label":"户外"},{"tag":"13","label":"潮牌"},{"tag":"5","label":"美妆"},{"tag":"7","label":"男士"},{"tag":"9","label":"母婴"},{"tag":"11","label":"健康"},{"tag":"14","label":"品质生活"}]}

const Articles: FC<IArticlesProps> = ({ pageContext }) => {
  const { tag, label, articles: _articles, total} = pageContext || {}
  const [loading, setLoading] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const [totalNum, setTotalNum] = useState(total || 0)
  const active = useMemo(() => {
    if (tag) {
      const index = tabs.tags.findIndex(t => t.tag.toLowerCase() == tag.toLowerCase())
      if (index !== -1) {
        return index
      }
    }
    return 0
  }, [tag])
  const scrollEle = useRef(null)
  const isPC = useMediaQuery({ query: "(min-width: 960px)" })
  const breadCrumbList = useMemo(() => {
    return [
      { path: '/', term: '首页' },
      { path: '/articles', term: '文章精选' },
      { path: '', term: label },
    ]
  }, [label])
  const [articles, setArticles] = useState<Array<any>>(() => {
    return _articles
  })

  function getCuratorPages() {
    if (loading) return
    setLoading(true)
    getArticles(tag, pageIndex)
      .then((res: any) => {
        setLoading(false)
        const _articles = isPC ? [] : [...articles]
        _articles.push(...res.hits.filter((hit: any) => hit.summary?.type === 'REGULAR').map((item: any) => {
          return {
            summary: {
              ...item.summary,
              legoId: parseLegoId(item.summary?.dynamicLink),
            },
          }
        }))
        setArticles(_articles)
        setTotalNum(res.totalHits)
      })
      .catch(err => {
        setLoading(false)
        console.log('err: ', err)
      })
  }

  function onChange(current: number, pageSize: number) {
    setPageIndex(current - 1)
  }

  useEffect(() => {
    getCuratorPages()
  }, [pageIndex, active])

  useEffect(() => {
    if (isPC) return
    const scrollHandler = () => {
      if (scrollEle.current && !loading) {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= (scrollEle.current as HTMLElement).scrollHeight
        if (bottom) {
          setPageIndex(pageIndex + 1)
        }
      }
    }
    window.removeEventListener('scroll', scrollHandler)
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [pageIndex, loading])

  return (
    <section className={Styles.articles} ref={scrollEle}>
      <SEO
        title={`编辑推荐${label}精选文章 | 别样海外购`}
        description={`别样海外购编辑推荐${label}精选文章, 包含最近超过${total || 1000}篇${label}的专业测评, 折扣优惠播报, 小众品牌种草和购物攻略等好文, 海淘专家一站式服务`}
        keywords={`别样海外购,别样App,别样海淘,${label},海淘`}
        canonical={+tag ? `https://www.bieyangapp.com/articles/${tag}/` : 'https://www.bieyangapp.com/articles'}
        image={articles?.length && articles?.[0]?.summary?.image ? `https://baleen-cdn-g.bybieyang.com/static/curation${articles?.[0]?.summary?.image}` : undefined}
      />
      <Breadcrumb cate={breadCrumbList} />
      <div className={Styles.articlesTabs}>
        {Array.from(tabs.tags)?.map((item: any, index: number) => {
          return (
            <div className={Styles.articlesTabsItem} key={item.tag}>
              <a
                className={`${Styles.articlesTabsItemTag} ${index === +active ? Styles.active : ''}`}
                href={index === 0 ? `/articles` : `/articles/${item.tag.toLowerCase()}/`}
              >
                {item.label}
              </a>
            </div>
          )
        })}
      </div>
      <div className={Styles.articleList}>
        {
          articles?.length ? articles.map((item, index) => {
            return <ArticleItem article={item} index={index}  key={index} />
          }) : (
            <Empty description='暂无数据' style={{ width: '100%', padding: '64px 0' }} />
          )
        }
      </div>
      {isPC ? <Pagination current={pageIndex + 1} onChange={onChange} totalNum={totalNum} pageSize={40} /> : null}
      {loading && <Loading />}
      <FloatingWidget />
    </section>
  )
}

export default Articles
