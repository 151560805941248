import React from 'react';
import Drawer from '@components/Drawer';
import './merchant-shipping-drawer.scss';

function MerchantShippingDrawer(props: any) {
    
    return (
        <Drawer
            visible={props.visible}
            onClose={props.onClose}
            className='m-merchant-shipping-drawer'
            hasCloseBtn={true}
            id="C-Merchant-Shipping-Drawer"
        >
            <div className='title'>{props?.merchantName}商家运费</div>
            {props?.costDetails?.faq?.questions?.map((item: any, index: number) => {
                return (
                    <div className='qa-wrap' key={index}>
                        <div className='question'>
                            <div>Q：</div>
                            <div>{item?.q?.text}</div>
                        </div>
                        <div className='answer'>
                            <div>A：</div>
                            <div>{item?.a?.text}</div>
                        </div>
                    </div>
                )
            })}
        </Drawer>
    )
}

export default MerchantShippingDrawer;