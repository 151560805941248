import React, { useEffect, useState } from 'react'
import { getGiftAmount } from '@service/api'
import { LayoutProvider } from '@components/Context'
import tabs from '@/layouts/tabs'

const categories = tabs.reduce((initival, item) => {
  if(item.id) {
    initival.push({
      ...item,
      category: {
        id: item.id,
        displayTerm: item.name
      }
    })
  }
  return initival
}, [])

const Boostrap = ({ children, pageContext }) => {
  const updateShowLayout = (flag) => {
    updateStore((store) => {
      return { ...store, showLayout: flag }
    })
  }

  const updateWidthAuto = (widthAuto) => {
    updateStore((store) => {
      return { ...store, widthAuto }
    })
  }

  const updateHeaderState = (headerState) => {
    updateStore((store) => {
      return { ...store, headerState }
    })
  }
  const updateActiveCategroyIndex = (activeCategoryIndex) => {
     updateStore((store) => {
      return { ...store, activeCategoryIndex }
    })
  }

  const setHeaderUnderline = (state) => {
    updateStore((store) => {
      return { ...store, headerUnderline: state }
    })
  }

  const updateDownloadBarVisable = (visable) => {
    updateStore((store) => {
      return { ...store, isShowDownloadBar: visable }
    })
  }

  const initialStoreState = {
    giftAmout: 688,
    showLayout: true,
    widthAuto: pageContext?.widthAuto ? true : false,
    updateShowLayout: updateShowLayout,
    updateWidthAuto: updateWidthAuto,
    headerState: 'fixed',
    updateHeaderState,
    categories: { children: categories },
    activeCategoryIndex: -1,
    updateActiveCategroyIndex,
    headerUnderline: false,
    setHeaderUnderline,
    isShowDownloadBar: true,
    updateDownloadBarVisable
  }

  const [store, updateStore] = useState(initialStoreState)

  useEffect(() => {
    getGiftAmount()
      .then(res => {
        updateStore(prevState => {
          return { ...prevState, giftAmout: res }
        })
      })
  }, [])

  return (
    <LayoutProvider value={store}>
      {children}
    </LayoutProvider>
  )
}

export default Boostrap
