import React from 'react'
import './index.scss'
import FirstCategory from '@components/FirstCategory'
import { IDefaultSortProps } from '../DefaultSort/index'
import TABS from '@/layouts/tabs.json'


interface InitState {
    categories: any
}

class CateGorySort extends React.Component<IDefaultSortProps, InitState> {
    readonly state: InitState = {
        categories: TABS.slice(1, 9)
    }
    render() {
        const { categories } = this.state
        const { q } = this.props
        return (
            <div className="categoryContent">
                {categories?.map((item: any, index: number) => {
                    return (
                        <FirstCategory
                            key={`categories${index}`}
                            firstCategory={item}
                        ></FirstCategory>
                    )
                })}
            </div>
        )
    }
}

export default CateGorySort
