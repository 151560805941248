let { isProd } = require('./env');
isProd = true;
export const API_ORIGIN = isProd ? 'https://5thave-prod.bieyangapp.com' : 'https://5thave-dev.bieyangapp.com';
export const STAIC_ORIGIN = isProd ? 'https://baleen-cdn-g.bieyangapp.com' : 'https://baleen-dev-cdn-g.bieyangapp.com';
export const SEARCH_URL = isProd ? 'https://baleen-cdn-g.bieyangapp.com/lego/search/index.html' : 'https://baleen-dev-cdn-g.bieyangapp.com/lego/search/index.html';
export const CONTENT_BASE = isProd ? "https://baleen-cdn-g.bieyangapp.com/" : "https://baleen-cdn-g.bieyangapp.com/" //数据
export const CART_PRODUCT = {
    'AVAILABLE': 'AVAILABLE',
    'UNAVAILABLE': 'UNAVAILABLE',
    'SPECIAL_OFFER': 'SPECIAL_OFFER',
    'GIFT': 'GIFT'
}

export const BXL_USER_ID = "BXL_USER_ID";
export const BXL_USER_KEY = "BXL_USER_KEY";
export const BXL_GUEST_ID = "BXL_GUEST_ID";
export const BXL_DEVICE_ID = "BXL_DEVICE_ID";
export const BXL_SESSION_START = "BXL_SESSION_START";
export const BXL_PAY_PENDING = "BXL_PAY_PENDING";
export const BXL_UTM_PARAMS = "BXL_UTM_PARAMS";
export const BXL_GOTO_APP = "BXL_GOTO_APP";
export const WX_LOGIN_FLAG = "WX_LOGIN_FLAG";
export const ADDON_PAY_ID = "ADDON_PAY_ID";
export const ADDON_PAY_INFO = "ADDON_PAY_INFO";

export const WECHATPAY = 'WECHATPAY';
export const ALIPAY = 'ALIPAY';
export const WECHATMINIPAY = 'WECHATMINIPAY';

export const ORDER = {
    'RECEIVED': 'RECEIVED',
    'FAILED_TO_PLACE': 'FAILED_TO_PLACE',
    'AWAIT_PAYMENT': 'AWAIT_PAYMENT'
}

export const ACCOUNT_TYPE = {
    'PHONE_WECHAT': ['PHONE_WECHAT', 'phoneWechatAccount'],
    'PHONE_ONLY': ['PHONE_ONLY', 'phoneOnlyAccount'],
    'WECHAT_ONLY': ['WECHAT_ONLY', 'wechatOnlyAccount']
}

export const SMZDM = 'smzdm';

export const BC_PAYER = {
    "BEFORE_MODIFICATION": "1",
    "AFTER_MODIFICATION": "2"
}

export const SHIPPING_METHOD = {
    'SUNSHINE_CUSTOM': 'SUNSHINE_CUSTOM'
}

export const CLAIM_TYPE = {
    NORMAL: 'NORMAL',
    UNCLAIM: 'UNCLAIM',
    WILLEXPIRE: 'WILLEXPIRE'
}

export const CLAIM_TYPE_LABEL: any = {
    [CLAIM_TYPE.UNCLAIM]: '领券',
    [CLAIM_TYPE.WILLEXPIRE]: '将过期',
    [CLAIM_TYPE.NORMAL]: ''
}

export const GIFT_AMOUNT = 'GIFT_AMOUNT';
