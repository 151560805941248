// extracted by mini-css-extract-plugin
export var bd = "footer_m-module--bd--c383a";
export var bigger = "footer_m-module--bigger--f074c";
export var detail = "footer_m-module--detail--6f06b";
export var downloadBar = "footer_m-module--download-bar--fb4f2";
export var footBottom = "footer_m-module--foot-bottom--ac67e";
export var footBottomDesc = "footer_m-module--foot-bottom-desc--606e8";
export var footLine = "footer_m-module--foot-line--6577c";
export var footTop = "footer_m-module--foot-top--6a3f1";
export var footTopAddress = "footer_m-module--foot-top-address--94c3d";
export var footTopDesc = "footer_m-module--foot-top-desc--68a2a";
export var footTopText = "footer_m-module--foot-top-text--93019";
export var footerM = "footer_m-module--footer-m--3b47f";
export var footerNavItem = "footer_m-module--footer-nav-item--3a9f3";
export var footerNavs = "footer_m-module--footer-navs--40d86";
export var ft = "footer_m-module--ft--0cf59";
export var logo = "footer_m-module--logo--fc98e";
export var outlinks = "footer_m-module--outlinks--c7d5f";
export var red = "footer_m-module--red--61f0c";