import * as React from "react"
import CategoryPC from '@modules/Category/CategoryPC'
import CategoryM from '@modules/Category/CategoryM'
import FloatingWidget from "@/components/FloatingWidget"

const Category = (props: any) => {
  const { cid, name, product, hotProducts, term, mappingTerm, displayTerm } = props.pageContext;

  return (
    <div>
      <CategoryPC product={product} hotProducts={hotProducts} name={name} cid={cid} term={term} mappingTerm={mappingTerm} displayTerm={displayTerm} />
      <CategoryM product={product} cid={cid} term={term} mappingTerm={mappingTerm} displayTerm={displayTerm}/>
      <FloatingWidget />
    </div>
  )

}
export default Category
