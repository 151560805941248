import React, { useState } from 'react'
import './index.scss'
import activeIcon from '@images/Checkmark.png'
export const SORTTYPE = [
    {
        label: '默认排序',
        value: ''
    },
    {
        label: '销量从高到低',
        value: 'sales'
    },
    {
        label: '折扣从高到低',
        value: 'discount'
    },
    {
        label: '价格从高到低',
        value: 'priceDescTag'
    },
    {
        label: '价格从低到高',
        value: 'priceAscTag'
    } 
]
export interface IDefaultSortProps {
    setSearchQuery: (type: string, vlaue: any) => Promise<{}>
    q: any
    searchProducts: () => void
}

const DefaultSort = (props: IDefaultSortProps) => {
    const { setSearchQuery, q } = props
    const setDefault = (value: any) => {
        setSearchQuery('s', value)
    }
    return (
        <div className="defaultSort">
            {SORTTYPE.map((item, index) => {
                return (
                    <div
                        className={`defaultItem ${
                            q.s === item.value || (!q.s && !item.value)
                                ? 'active'
                                : ''
                        }`}
                        key={`default${index}`}
                        onClick={() => setDefault(item.value)}
                    >
                        <div className="labelText">{item.label}</div>
                    </div>
                )
            })}
        </div>
    )
}

export default DefaultSort
