import React from 'react'
import * as styles from './index.module.scss'
// import { Link } from 'gatsby'

const ProductItem = ({ item = {} }) => {
  const { product, labelView, origin } = item
  const { id, images, marks, nameCN, name, priceTagCN, priceTag } = product
  const shortLabels = (labelView && labelView.shortLabels) || []
  const labelViews = shortLabels.reduce((init, item ) => {
    return [...init, ...(item.labels || []).filter((label) => label.text)]
  }, [])

  return  (
    <a className={styles.productItem} href={`/pd/${id}/`}>
      <div className={styles.img}>
        <img src={images?.[0]?.thumbnail?.url || images?.[0]?.full?.url} alt={`${nameCN || name}`} />
      </div>
      <div className={styles.label}>
        { nameCN || name }
      </div>
      <div className={styles.country}>
        { origin }
      </div>
      <div className={styles.marks}>
        {(marks.length ? marks : [priceTagCN, priceTag]).map(price => (
          <span key={price}>{price}</span>
        ))}
      </div>
      <div className={styles.labels}>
        {labelViews.map(item => item.text).join(' | ')}
      </div>
    </a>
  )
}


function MoreProduct(props) {
  return (
    <div className={styles.moreProduct}>
      <h4 className={styles.title}>更多商品推荐</h4>
      <div className={styles.productList}>
        {
        props?.similarProducts?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <ProductItem
                  key={item.product.id}
                  item={item}
                />
              </React.Fragment>
            )
          })
        }
      </div>
    </div>
  )
}



export default MoreProduct