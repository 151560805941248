import React, { useContext, useState } from 'react';
import { ProductContext, SKUContext } from '@components/Context';
import { useChoseSku } from '@lib/hooks';
import './color-picker.scss';

interface ColorPickerProps {

}

function ColorPicker(props: ColorPickerProps) {
    const productContext: any = useContext(ProductContext);
    const skuContext: any = useContext(SKUContext);
    const { colors } = productContext?.product || {};
    const { chosenSku } = skuContext;
    const preview = productContext?.product && productContext?.product?.images && productContext?.product?.images?.[0];
    if (!colors || !colors.length || colors.length < 2) return null;
    return (
        <div className='m-color-picker'>
            {preview && <ColorItem active={!chosenSku} img={preview?.thumbnail?.url} text="商品预览"/>}
            <a className='line' />
            {colors && colors.map((item: any, index: number) => {
                const active = chosenSku && chosenSku?.color?.name === item?.name;
                return (
                    <ColorItem active={active} name={item?.name} img={item?.image?.full?.url} text={item?.name} key={index} />
                )
            })}
        </div>
    );
}

interface ColorItemProps {
    img: string,
    text: string,
    active?: boolean,
    name?: string
}

function ColorItem(props: ColorItemProps) {
    const skuContext: any = useContext(SKUContext);
    const chosenSku = useChoseSku();
    function handleColorClick(color?: string) {
        chosenSku({color});
    }
    return (
        <div className='m-color-item' onClick={() => handleColorClick(props.name)}>
            <div className={`img-wrap ${props.active ? 'active' : ''}`}>
                <div className='image' style={{backgroundImage: `url(${props.img})`}}></div>
            </div>
            <div className='color-text'>{props.text}</div>
        </div>
    )
}

export default ColorPicker;