import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { LayoutContext } from '@components/Context'
import './layout.scss'
import Header from './Header'
import HeaderM from './HeaderM'
import Footer from './Footer'
import FooterM from './FooterM'

const RegularLayout = ({ children }) => {
  const layoutContext = useContext(LayoutContext)
  return (
    <div className={layoutContext?.widthAuto ? '' : 'min-width-limit'}>
      <Header />
      <HeaderM />
      {children}
      <Footer />
      { layoutContext.isShowDownloadBar && <FooterM /> }
    </div>
  )
}

RegularLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default RegularLayout
