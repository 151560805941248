import * as React from "react"
import './haul.scss'
import like from '@/images/like.png'
import { Empty } from "antd-mobile"
import { getHaulComment } from '@/service/api'
import { useEffect, useState, useRef } from "react"
import SEO from './seo'
import { isBrowser } from "@/lib/utils"
import Loading from '@/layouts/Loading'
// import { Link, navigate } from 'gatsby'
import Pagination from "@/modules/Common/Pagination"
import Breadcrumb from "@/modules/productPc/Breadcrumb"
import FloatingWidget from "@/components/FloatingWidget"
import bxlUtils from 'bxl-utils'
import { useMediaQuery } from "react-responsive"

const tabs = [
  {
    "categoryName": "推荐",
    "categoryId": "RECOMMEND"
  },
  {
    "categoryName": "女包",
    "categoryId": "WOMEN_BAG"
  },
  {
    "categoryName": "配饰",
    "categoryId": "ACCESSORIES"
  },
  {
    "categoryName": "美护",
    "categoryId": "BEAUTY"
  },
  {
    "categoryName": "女鞋",
    "categoryId": "WOMEN_SHOES"
  },
  {
    "categoryName": "女装",
    "categoryId": "WOMEN_CLOTH"
  },
  {
    "categoryName": "男鞋",
    "categoryId": "MEN_SHOES"
  },
  {
    "categoryName": "婴童",
    "categoryId": "CHILD"
  },
  {
    "categoryName": "男装",
    "categoryId": "MEN_CLOTH"
  },
  {
    "categoryName": "健康",
    "categoryId": "HEALTH"
  },
  {
    "categoryName": "生活",
    "categoryId": "LIFE"
  }
]
const size = 30

function parseTabParam() {
  if (!isBrowser) return 0
  const params = bxlUtils.uri.parseUrlParams(location.search)
  if (Array.isArray(params?.tab)) {
    return +params.tab?.[0] || 0
  }
  return +params?.tab || 0
}

// function updateUrlHistory(tab: number) {
//   try {
//     if (!isBrowser) return
//     const qs = +tab === 0 ? '' : `?tab=${tab}`
//     const url = window.location.protocol + "//" + window.location.host + window.location.pathname + qs
//     window.history.pushState({ path: url }, '', url)
//   } catch (e) {
//       console.error(e)
//   }
// }

function getTabSafe(active: number | null) {
  if (!active) {
    return tabs[0]
  }
  if (tabs.length <= +active) {
    return tabs[0]
  }
  return tabs[+active]
}

const Haul = (props: any) => {
  const { pageContext } = props || {}
  const { categoryId, haulData: propsHaulData, totalNum: _total } = pageContext || {}
  const [loading, setLoading] = useState(false)
  const [f, setFrom] = useState<number>(0)
  const isPC = useMediaQuery({ query: "(min-width: 960px)" })
  const delimiter = useRef(0)
  const active = React.useMemo(() => {
    if (categoryId) {
      const index = tabs.findIndex(t => t.categoryId.toLowerCase() == categoryId.toLowerCase())

      if (index !== -1) {
        return index
      }
    }
    return parseTabParam()
  }, [categoryId])
  const currentTab = React.useMemo(() => {
    return getTabSafe(active)
  }, [active])
  const breadCrumbList = React.useMemo(() => {
    return [
      { path: '/', term: '首页' },
      { path: '/haul', term: '晒单精选' },
      { path: ``, term: currentTab.categoryName },
    ]
  }, [currentTab])
  const waterfallRef = useRef(null)
  const [totalNum, setTotal] = useState(() => {
    return _total || 0
  })
  const [haulData, setHaulData] = useState<any[]>(() => {
    let _propsHaulData: any[] = []
    propsHaulData.map((item: any, key: number) => {
      const remain = isPC ? key % 5 : key % 2
      if (!_propsHaulData[remain]) {
        _propsHaulData[remain] = []
      }
      _propsHaulData[remain].push(item)
    })
    return _propsHaulData
  })

  useEffect(() => {
    if (isPC) return
    const scrollHandler = () => {
      if (waterfallRef.current && !loading) {
        // const scrollTop = window.pageYOffset
        const menu: HTMLElement = waterfallRef.current
        // const windowHeight = window.innerHeight
        // const { height: menuHeight } = menu.getBoundingClientRect()
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= menu.scrollHeight
        if (bottom) {
          setFrom(isPC ? f + 1 : delimiter.current)
        }
      }
    }
    window.removeEventListener('scroll', scrollHandler)
    window.addEventListener('scroll', scrollHandler)

    return () => window.removeEventListener('scroll', scrollHandler)
  }, [f, loading])

  useEffect(() => {
    getData()
  }, [f, active])

  // pc 分页
  function onChange(page: number) {
    // setHaulData([])
    setFrom(page - 1)
    window.scrollTo(0, 0)
  }

  function getData() {
    if (loading) return
    setLoading(true)
    const q = `${ isPC && active ? `page=${f * size}` : `delimiter=${isPC && !active ? f * size : f}` }&size=${size}&needTotalCount=true${
      active ? `&category=${currentTab?.categoryId}` : ''
    }`
    getHaulComment(q)
      .then((res: any) => {
        let _haulData: any = f === 0 || isPC ? [] : [...haulData];
        const data = res.waterFall?.waterDrops;
        if (data && data.length) {
          data.map((item: any, key: number) => {
            const remain = isPC ? key % 5 : key % 2
            if (!_haulData[remain]) {
              _haulData[remain] = []
            }
            _haulData[remain].push(item)
          })
          setHaulData(_haulData)
          setTotal(res.waterFall.total)
          delimiter.current = res.waterFall.delimiter
        }
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
      })
  }

  return (
    <article className="p-haul">
      <Breadcrumb cate={breadCrumbList} />
      <SEO
        title={`${currentTab.categoryName}商品评价精选晒单 | 别样海外购`}
        description={`别样海外购${currentTab.categoryName}商品评价精选晒单, 分享近期超过${totalNum || 100}篇包含${currentTab.categoryName}的好物种草,买家秀,促销攻略和晒单。上别样,海淘专家一站式服务`}
        keywords={`别样海外购,别样海淘,${currentTab.categoryName},海淘`}
        canonical={+active ? `https://www.bieyangapp.com/haul/${currentTab.categoryId.toLowerCase()}/` : 'https://www.bieyangapp.com/haul'}
        image={haulData?.length && haulData?.[0] ? (haulData?.[0]?.image?.full?.url || haulData?.[0]?.image?.thumbnail?.url) : undefined}
      />
      <div className='tab-wrap'>
        {Array.from(tabs)?.map((item: any, index: number) => {
          return (
            <div className='tab-wrap-main' key={item.categoryId}>
              <a
                className={`tab-item ${index === +active ? 'active' : ''}`}
                href={index === 0 ? `/haul` : `/haul/${item.categoryId.toLowerCase()}/`}
              >
                {item.categoryName}
              </a>
              {index !== tabs.length - 1
                ? <div className='tab-item-split'></div>
                : null
              }
            </div>
          )
        })}
      </div>
      <div className="waterfall" ref={waterfallRef}>
        {
          haulData?.length ? haulData?.map((haul: any, key: number) => {
            return <div className='waterfall-item' key={key}>
              {
                haul.map((item: any, idx: number) => {
                  return (
                    <div className='waterfall-item-content' key={`${key}_${idx}`}>
                      <a href={`/review/${item.productId}/${item.commentId}/`}>
                        <img
                          className='waterfall-item-content-img'
                          src={item?.image?.thumbnail?.url || item?.image?.full?.url || item?.video?.cover?.url || ''}
                          alt={`用户${item.userLabel}对商品的晒单评价: ${item.content || '默认好评'}, 点赞数: ${item.likes}` || "晒单图片"}
                        />
                        <p className='waterfall-item-content-content'>{item.content || '默认好评'}</p>
                        <div className='waterfall-item-content-bottom'>
                          <div className='waterfall-item-content-bottom-left'>
                            <div className='waterfall-item-content-bottom-left-avatar'>
                              <img src={item.userAvatar || 'https://www.bieyangapp.com/static/curation/authors/bieyangnew.png'} alt={item.userLabel || '用户头像'} />
                            </div>
                            <span>{item.userLabel}</span>
                          </div>
                          <div className='waterfall-item-content-bottom-right'>
                            <img src={like} alt="点赞图标 like icon" />
                            <span>{item.likes}</span>
                          </div>
                        </div>
                      </a>
                    </div>
                  )
                })
              }
            </div>
          }) : (
            <Empty description='暂无数据' style={{ width: '100%', padding: '64px 0' }} />
          )
        }
      </div>
      {loading && <Loading />}
      <div className='getMore'>
        <Pagination current={f+1} onChange={onChange} totalNum={totalNum} pageSize={30} />
      </div>
      <FloatingWidget />
    </article>
  )
}
export default Haul
