import { useContext } from 'react';
import { ProductContext, SKUContext, CartContext, CheckoutContext } from '@components/Context';
import Toast from '@components/Toast';
import { addShoppingCart, getCartInfo, expressBuy } from '@service/api';
import SessionStorage from '@lib/session';
import { BXL_UTM_PARAMS } from '@lib/constants';
import history from '@lib/history';

interface choseSkuPatameter {
    color?: string,
    size?: string,
    width?: string
}

//根据颜色、宽度、尺寸选择sku
export function useChoseSku() {
    const productContext: any = useContext(ProductContext);
    const skuContext: any = useContext(SKUContext);
    return function (choseSkuPatameter: choseSkuPatameter) {
        const skus = productContext.product && productContext?.product?.availableSkus;
        const chosenParam = skuContext.chosenParam || {};
        let param = {
            ...chosenParam,
            ...choseSkuPatameter
        };

        //更新不能选择项
        let availableColors: Array<any> | null = null, availableWidths: Array<string> | null = null, availableSizes: Array<any> | null = null;
        if (param.color) {
            skus?.forEach((item: any) => {
                let skuColor = item?.color?.name,
                    skuWidth = item?.attributes?.attributes?.width?.choices?.[0],
                    skuSize = item?.size?.name;
                if (skuColor === param.color) {
                    availableWidths = (availableWidths || []).concat(skuWidth);
                    availableSizes = (availableSizes || []).concat(skuSize);
                }
            })
            availableWidths = Array.from(new Set(availableWidths));
            availableSizes = Array.from(new Set(availableSizes));
        }
        if (param.size && (!availableSizes || availableSizes.indexOf(param.size) > -1)) {
            let tempWidth: Array<string> = [];
            skus?.forEach((item: any) => {
                let skuColor = item?.color?.name,
                    skuWidth = item?.attributes?.attributes?.width?.choices?.[0],
                    skuSize = item?.size?.name;
                if (skuSize === param.size) {
                    if (!availableWidths || availableWidths?.find(w => w === skuWidth)) {
                        tempWidth.push(skuWidth);
                    }
                    availableColors = (availableColors || []).concat(skuColor);
                }
            })
            availableWidths = Array.from(new Set(tempWidth));
            availableColors = Array.from(new Set(availableColors));
        } else {
            param.size = undefined;
        }
        if (param.width && (!availableWidths || availableWidths.indexOf(param.width) > -1)) {
            let tempColor: Array<any> = [];
            let tempSize: Array<any> = [];
            skus?.forEach((item: any) => {
                let skuColor = item?.color?.name,
                    skuWidth = item?.attributes?.attributes?.width?.choices?.[0],
                    skuSize = item?.size?.name;
                if (skuWidth === param.width) {
                    if (!availableColors || availableColors?.find(c => c === skuColor)) {
                        tempColor.push(skuColor);
                    }
                    if (!availableSizes || availableSizes?.find(s => s === skuSize)) {
                        tempSize.push(skuSize);
                    }
                }
            })
            availableColors = Array.from(new Set(tempColor));
            availableSizes = Array.from(new Set(tempSize));
        } else {
            param.width = undefined;
        }
        const allColors = productContext?.product?.colors?.map((item: any) => item?.name);
        const allWidths = productContext?.product?.attributes?.attributes?.width?.choices;
        const allSizes = productContext?.product?.sizes?.map((item: any) => item?.name);
        const availableOption = {
            colors: availableColors || allColors,
            widths: availableWidths || allWidths,
            sizes: availableSizes || allSizes
        }

        //选出sku
        let { color = null, size = null, width = null } = param;
        let sku;
        const hasOption = productContext?.product?.colors?.length ||
            productContext?.product?.sizes?.length ||
            productContext?.product?.attributes?.attributes?.width?.choices?.length;
        if (color || size || width) {
            sku = skus?.find((item: any) => {
                return (!color || color === item?.color?.name)
                    && (!size || size === item?.size?.name)
                    && (!width || width === item?.attributes?.attributes?.width?.choices?.[0])
            });
        } else if (!hasOption) {
            //无规格选择项
            sku = skus?.[0];
        } else sku = null;

        skuContext.updateChosenParam({
            ...skuContext.chosenParam,
            ...param
        })
        skuContext.updateChosenSku(sku);
        skuContext.updateAvailableOption(availableOption);
    }
}

//根据sku选择价格信息
export function useGetPrice() {
    const productContext: any = useContext(ProductContext);
    const skuContext: any = useContext(SKUContext);
    const { chosenSku } = skuContext;
    const { product } = productContext;
    if (!chosenSku) {
        return {
            priceTag: product?.promotions?.priceTag,
            originalPriceTag: product?.promotions?.originalPriceTag,
            priceTagCN: product?.promotions?.priceTagCN
        }
    } else {
        const skus = product?.promotions?.skus || [];
        return skus?.find((item: any) => (item.id === chosenSku.id) || (item.id === '_all'));
    }
}

//商品已选信息：已选择<string>，未选择<null>，无法选择<undefined>
export function useChosenInfo() {
    const productContext: any = useContext(ProductContext);
    const skuContext: any = useContext(SKUContext);
    const { product } = productContext;
    const { chosenParam } = skuContext;
    const chosen = {
        color: chosenParam?.color ?
            chosenParam.color : product?.colors?.length ?
                null : undefined,
        width: chosenParam?.width ?
            chosenParam.width : product?.attributes?.attributes?.width ?
                null : undefined,
        size: chosenParam?.size ?
            chosenParam.size : product?.sizes?.length ?
                null : undefined
    }
    const hasChosen = !!(chosen.color || chosen.width || chosen.size);
    const chosenFinished = (chosen.color !== null) && (chosen.width !== null) && (chosen.size !== null);
    return [hasChosen, chosenFinished, chosen];
}

export function useAddCart() {
    const [hasChosen, chosenFinished, chosen] = useChosenInfo();
    const skuContext: any = useContext(SKUContext);
    const productContext: any = useContext(ProductContext);
    const cartContext: any = useContext(CartContext);
    const checkoutContext: any = useContext(CheckoutContext);
    const skus = productContext?.product?.availableSkus;
    return function () {
        if (chosenFinished) {
            const sku = skuContext?.chosenSku || skus?.[0];
            handleAddShoppingCart({
                sku,
                productContext,
                skuContext,
                cartContext,
                checkoutContext
            })
        } else {
            const tips: any = [];
            Object.entries(chosen).forEach((item: Array<any>, index: number) => {
                if (item[1] === null) {
                    switch (item[0]) {
                        case 'color':
                            tips.push('颜色');
                            break;
                        case 'width':
                            tips.push('宽度');
                            break;
                        case 'size':
                            tips.push('尺码');
                            break;
                    }
                }
            })
            Toast(`请选择${tips.join('、')}`);
        }
    }
}

function handleAddShoppingCart(params: any) {
    const { sku, stack, productContext, skuContext, cartContext, checkoutContext } = params;
    const utmParams = JSON.parse(SessionStorage.get(BXL_UTM_PARAMS) || '{}');
    addShoppingCart({
        sku: {
            ...sku,
            merchantId: productContext?.product?.merchantId,
            productId: productContext?.product?.id
        },
        quantity: skuContext?.count,
        trace: {
            adTags: {
                ...utmParams,
                acqchannel: 'H5'
            },
            stack: stack ? [JSON.stringify(stack)] : []
        }
    }).then((res: any) => {
        skuContext.updateDrawerVisible(false);
        checkoutContext.updateEvent('add_shopping_cart');
        Toast('已成功加入购物袋');
        getCartInfo().then((res: any) => {
            cartContext.updateCartNum(res?.itemsQuantity || 0);
        });
    }).catch((err: any) => {
        Toast(err?.messages?.[0] || '加入购物袋失败');
    })
}


export function useExpressBuy() {
    const [hasChosen, chosenFinished, chosen] = useChosenInfo();
    const skuContext: any = useContext(SKUContext);
    const productContext: any = useContext(ProductContext);
    const cartContext: any = useContext(CartContext);
    const checkoutContext: any = useContext(CheckoutContext);
    const skus = productContext?.product?.availableSkus;
    return function () {
        if (chosenFinished) {
            const sku = skuContext?.chosenSku || skus?.[0];
            handleUseExpressBuy({
                sku,
                productContext,
                skuContext,
                cartContext,
                checkoutContext
            })
        } else {
            const tips: any = [];
            Object.entries(chosen).forEach((item: Array<any>, index: number) => {
                if (item[1] === null) {
                    switch (item[0]) {
                        case 'color':
                            tips.push('颜色');
                            break;
                        case 'width':
                            tips.push('宽度');
                            break;
                        case 'size':
                            tips.push('尺码');
                            break;
                    }
                }
            })
            Toast(`请选择${tips.join('、')}`);
        }
    }
}

function handleUseExpressBuy(params: any) {
    const { sku, stack, productContext, skuContext, cartContext, checkoutContext } = params;
    const utmParams = JSON.parse(SessionStorage.get(BXL_UTM_PARAMS) || '{}');
    expressBuy({
        orderItem: {
            sku: {
                ...sku,
                merchantId: productContext?.product?.merchantId,
                productId: productContext?.product?.id
            },
            quantity: skuContext?.count,
            trace: {
                adTags: {
                    ...utmParams,
                    acqchannel: 'H5'
                },
                stack: stack ? [JSON.stringify(stack)] : []
            }
        }
    }).then((res: any) => {
        skuContext.updateDrawerVisible(false);
        checkoutContext.updateDetail(res);
        checkoutContext.updateEvent('expressBuy');
        Toast('提交中');
        history.push(`/checkout/${res?.groups[0]?.id}`);
    }).catch((err: any) => {
        Toast(err?.messages?.[0] || '立即购买失败');
    })
}

//清空sku
export function useClearSku() {
    const skuContext: any = useContext(SKUContext);
    return function () {
        skuContext.updateChosenSku(null);
        skuContext.updateChosenParam({});
        skuContext.updateAvailableOption({});
        skuContext.updateCount(1);
        skuContext.updateDrawerVisible(false);
    }
}