import React, { useState } from 'react'
import './index.scss'
import showMore from '../../assets/images/ic_arrow_down_filter.png'
import showLess from '../../assets/images/ic_arrow_down_filter.png'
import { ICateGoryItem } from '../FirstCategory'
import { Link } from 'gatsby'
interface ICateGory {
    secondCategory: ICateGoryItem
}
const SecondCategory = (props: ICateGory) => {
    const { secondCategory } = props
    const { category, children } = secondCategory
    const [showChildren, setshowChildren] = useState(false)
    return (
        <>
            <div className="second" onClick={() => setshowChildren(!showChildren)}>
                <div className="leftCategory">
                    <span className={`categoryName`}>
                        {category.displayTerm}
                    </span>
                </div>
                <div className="rightCategory">
                    {children && children.length > 0 && (
                        <img
                            className="rightIcon"
                            style={showChildren ? { transform: 'rotate(180deg)' } : {} }
                            src={showLess}
                            alt=""
                        ></img>
                    )}
                </div>
            </div>
            {showChildren && children &&
                <div className="thirdContent">
                    {
                        children.map((item, index) => {
                            return (
                                <Link key={`third${index}`} to={`/category/${item?.category?.id}/`} >
                                    <div className={`thirdItem`}>
                                        {item?.category?.displayTerm}
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>
            }
        </>
    )
}

export default SecondCategory
