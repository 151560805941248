import React from 'react'
import './index.scss'

interface IInputProps {
    value?: string
    place?: string
    onChange: (value: string) => void
    onFocus: (value: any) => void
}
const InputBox = (props: IInputProps) => {
    const { value, place, onChange, onFocus } = props
    return (
        <div className="inputBox">
            <input
                className="setInput"
                value={value}
                placeholder={place}
                onFocus={ e=> onFocus(e) }
                onChange={e => onChange(e.target.value)}
            ></input>
        </div>
    )
}

export default InputBox
