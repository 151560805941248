import * as React from 'react'
import * as fs from './footer_m.module.scss'
import Logo from '@images/logo_white.png'
import LogoCircle from '@images/img_logo_cicle.png'
// import { Link } from 'gatsby'
const FooterM = () => {
  return (
    <div className={fs.footerM}>
      <div className={fs.footTop}>
        <img src={Logo} alt="logo" />
        <div className={fs.footTopDesc}>
          <span>下载别样APP，领&nbsp;</span>
          <span> 688元 </span>
          <span>&nbsp;新人大礼包</span>
        </div>
        <div className={fs.footerNavs}>
          <a className={fs.footerNavItem} href='/help'><span>帮助中心</span></a>
          <a className={fs.footerNavItem} href='/privacy'><span>隐私政策</span></a>
          <a className={fs.footerNavItem} target='_blank' href='/static/html/payment_agreement.html'><span>消费者告知书</span></a>
          <a className={fs.footerNavItem} target='_blank' href='/static/html/qualityPage/index.html'><span>正品保障</span></a>
          <a className={fs.footerNavItem} rel='nofollow' href='https://bybieyang.sobot.com/chat/pc/v2/index.html?sysnum=4291aa02fcc7428c96b43b3ee59ca7ad'><span>联系客服</span></a>
          <a className={fs.footerNavItem} href='/about'><span>关于我们</span></a>
          <a className={fs.footerNavItem} target='_blank' href='/static/html/logistics_details.html'><span>国际运送方式</span></a>
          <a className={fs.footerNavItem} href='/about'><span>招商合作</span></a>
        </div>
        {/* <div className={fs.footTopText}>别样海外总部：298 S Sunnyvale Ave, STE 210 Sunnyvale, CA 94086</div>
        <a href='/'><div className={fs.footTopText}>别样中国总部：上海市浦东新区张江高科科苑路399号10号楼12层</div></a> */}
        <div className={fs.footLine}></div>
        <div className={fs.footTopAddress}>Copyright © 2015-2024, 上海别样秀数据科技有限公司, 上海市浦东新区张江高科科苑路399号10号楼12层, 021-61514616, All rights reserved. 沪ICP备18004034号</div>
        <div className={fs.outlinks}>
          <a className={fs.footerNavItem} target='_blank' href='https://www.beyondstyle.us'><span>BeyondStyle</span></a>
          <a className={fs.footerNavItem}>|</a>
          <a className={fs.footerNavItem} target='_blank' href='https://www.nubestore.ai/'><span>CloudStore</span></a>
          <a className={fs.footerNavItem}>|</a>
          <a className={fs.footerNavItem} target='_blank' href='https://www.borderxlab.com/bgm'><span>Beyond Global Marketplace</span></a>
        </div>
      </div>
    </div>
  )
}

export default FooterM