import React, { useContext } from 'react';
import { ProductContext } from '@components/Context';
import { getImageUrl, getProductAlt } from '@lib/utils';
import './product-description.scss';
import { openAppStore } from '@lib/appstore';

const DES_TYPE = {
    TEXT: 'TEXT',
    IMAGE: 'IMAGE'
}

function ProductDescription() {
    const productContext: any = useContext(ProductContext);
    const { product } = productContext;
    const onImageClick = () => {
        openAppStore()
    }
    if (!product?.imageText?.layers?.length) return null;
    return (
        <div className='m-product-description'>
            <div className='title'>商品描述</div>
            <div className='content'>
                {product?.imageText?.layers?.map((item: any, index: number) => {
                    if (item.layerType === DES_TYPE.TEXT) {
                        return (
                            <div key={index}>{item?.text?.text}</div>
                        )
                    }
                    if (item.layerType === DES_TYPE.IMAGE) {
                        return (
                            <img key={index} onClick={onImageClick} src={getImageUrl(item?.image?.url)} alt={`${getProductAlt(product)},第${index + 1}张图片详细描述`} />
                        )
                    }
                })}
            </div>
        </div>
    )
}

export default ProductDescription;