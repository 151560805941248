// extracted by mini-css-extract-plugin
export var arrow = "index-module--arrow--0d135";
export var avatar = "index-module--avatar--79264";
export var badge = "index-module--badge--ca793";
export var button = "index-module--button--32a90";
export var content = "index-module--content--11291";
export var info = "index-module--info--c03c2";
export var like = "index-module--like--9d007";
export var likeNumber = "index-module--like-number--11875";
export var msg = "index-module--msg--fc9f8";
export var name = "index-module--name--15993";
export var nameWrap = "index-module--name-wrap--e1919";
export var price = "index-module--price--34130";
export var product = "index-module--product--8695e";
export var productImg = "index-module--product-img--6e7ca";
export var specification = "index-module--specification--fc6fb";
export var starWrap = "index-module--star-wrap--13914";
export var tag = "index-module--tag--f62cf";
export var tagItem = "index-module--tag-item--ef825";
export var time = "index-module--time--10fe0";
export var title = "index-module--title--38e34";
export var userDetail = "index-module--user-detail--31700";
export var userInfo = "index-module--user-info--cc9ad";