import React, { useEffect, useState } from 'react';
// import qrcode from '@images/qrcode-download-app.png';
import close from '@images/close.png';
import Portal from '@components/Portal';
// import { getGiftAmount } from '@service/api';
import LocalStorage from '@/lib/localStorage';
import { GIFT_AMOUNT } from '@lib/constants';
import WechatMiniProgramQrCode from './qrCode';
import './download.scss';

function Download(props: any) {
    const { id, onClose } = props || {};
    const [amount, setAmount] = useState(LocalStorage.get(GIFT_AMOUNT));

    // useEffect(() => {
    //     if(!LocalStorage.get(GIFT_AMOUNT)) {
    //         getGiftAmount()
    //             .then((res: any) => {
    //                 LocalStorage.set(GIFT_AMOUNT, res.amount);
    //                 setAmount(res.amount);
    //             })
    //     }
    // }, []);

    return (
        <div className='m-download-dialog'>
            <div className='download-content'>
                <img src={close} className='close' onClick={onClose} />
                {/* <img src={qrcode} className='qrcode' /> */}
                <WechatMiniProgramQrCode id={id} />
                <p className='download-text'>微信扫码打开别样APP小程序立即购买</p>
                <p className='download-text'>领取新人<span className='red'>{amount || '688元'}</span>专享礼包</p>
                <p className='text'>欧美潮流正品 | 官网价格同步 | 一键安心购买</p>
            </div>
        </div>
    )
}

export default Portal(Download);