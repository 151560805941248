import React from 'react';
import './pyml_section.scss';
import Product from '@modules/Home/Product';

function PymlSection(props: any) {
  const { pymlProducts } = props || {}
  if (!pymlProducts?.length) return null
  return (
    <div className='pyml-list'>
      <div className='pyml-content'>
          <h2 className='main-title'>猜你喜欢</h2>
          <h3 className='sub-title'>You Also Might Like</h3>
      </div>
      <div className={'pyml-product-list'}>
        {pymlProducts.map((product: any, index: number) => {
          return (
            <div
              key={index}
              className="pyml-product"
            >
              <Product product={product} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PymlSection
