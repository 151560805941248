import React, { useState } from 'react'
import './index.scss'
import showMore from '../../assets/images/ic_showmore.png'
import showLess from '../../assets/images/ic_showless.png'
import SecondCategory from '../SecondCategory'
export interface ICateGoryItem {
    category: {
        displayTerm: string
        id?: string
        parentId?: string
        term: string
    }
    children: ICateGoryItem[]
    rootCategoryId?: string
    rootSortedId?: number
    id?: number
    name?: string
}

interface IFirstCateGoryProps {
    firstCategory: ICateGoryItem
}
const FirstCateGory = (props: IFirstCateGoryProps) => {
    const { firstCategory } = props
    const { category, children, id, name } = firstCategory
    const [showChildren, setshowChildren] = useState(false)
    return (
        <div className={showChildren ? 'firstCate-c firstCate-pb' : 'firstCate-c'}>
            <div className="firstCate" onClick={() => setshowChildren(!showChildren)}>
                <div className="leftCategory">
                    <span
                        className={`categoryName`}
                    >
                        {category?.displayTerm || name}
                    </span>
                </div>
            </div>
            {children &&
                showChildren &&
                children.map((item, index) => {
                    return (
                        <SecondCategory
                            key={`second${index}`}
                            secondCategory={item}
                        ></SecondCategory>
                    )
                })}
        </div>
    )
}

export default FirstCateGory
