import React from 'react';
import './tag.scss';

interface TagInterface {
    className?: string,
    backgroundColor?: string,
    fontColor?: string,
    children: React.ReactNode
}
function Tag(props: TagInterface) {
    // if (typeof(props.children) === 'string') {
    //     return (
    //         <div className={`c-tag ${props.className ? props.className : ''}`}>
    //             {props.children}
    //         </div>
    //     )
    // } else return null;
    return (
        <div 
            className={`c-tag ${props.className ? props.className : ''}`} 
            style={{backgroundColor: `${props.backgroundColor}`, color: `${props.fontColor}`}}
        >
            {props.children}
        </div>
    )
}

Tag.defaultProps = {
    backgroundColor: '#F08F48',
    fontColor: '#FFFFFF'
}

export default Tag;