import LocalStorage from '@/lib/localStorage';
import SessionStorage from '@/lib/session';
import { BXL_USER_ID, BXL_USER_KEY, BXL_GUEST_ID, BXL_DEVICE_ID, BXL_SESSION_START, BXL_UTM_PARAMS, STAIC_ORIGIN, CONTENT_BASE, SMZDM } from '@/lib/constants';
import { v4 as uuidv4 } from 'uuid';
import bxlUtils from 'bxl-utils'

export const isBrowser = typeof window !== 'undefined'

// const BRIDGE_RULE = "bieyang:///bridge";
// const ua = isBrowser && navigator.userAgent || '';
const appDownloadLink = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.borderxlab.bieyang';
const sensorsServer = 'http://sensorsdata.bybieyang.com/sa?project=production';

export const isAndroid = false;

export function interlaceImg(url: string) {
    return `${url}?x-oss-process=image/format,jpg/interlace,1`;
}

export function init() {
    if (!isBrowser) return
    const guestId = LocalStorage.get(BXL_GUEST_ID);
    const deviceId = LocalStorage.get(BXL_DEVICE_ID);
    const sessionStart = SessionStorage.get(BXL_SESSION_START);
    const utmParam = {};
    LocalStorage.set(BXL_GUEST_ID, guestId || uuidv4());
    LocalStorage.set(BXL_DEVICE_ID, deviceId || 'byh5-' + uuidv4());
    SessionStorage.set(BXL_SESSION_START, sessionStart || Date.now().toString());
    SessionStorage.set(BXL_UTM_PARAMS, JSON.stringify(utmParam));
}

export function isLogin() {
    const userId = LocalStorage.get(BXL_USER_ID);
    const userKey = LocalStorage.get(BXL_USER_KEY);
    return !!(userId && userKey);
}

export let type = (obj: any) => {
    return {}.toString.call(obj).replace(/^\[object (.+)\]$/, '$1').toLowerCase()
}

export function wxShare(options: any) {
    wx.updateAppMessageShareData(options);
    wx.updateTimelineShareData(options);
}

export function hideMenu(menuList: Array<any>) {
    wx.hideMenuItems({
        menuList // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
    });
}

export function hideNonBaseMenu() {
    wx.hideAllNonBaseMenuItem();
}

export function showAllNonBaseMenu() {
    wx.showAllNonBaseMenuItem();
}

export function setReopenFlag() {
    SessionStorage.set('BXL_IN_SESSION', 'IN_SESSION');
}

export function checkReopen() {
    const session = SessionStorage.get('BXL_IN_SESSION');
    if (!session) {
        window.location.href = `${STAIC_ORIGIN}/popularity/index.html`;
    }
}

export function getAliPayParams(payInfo: any) {
    let result = []
    for (let key in payInfo) {
        result.push(key + '=' + encodeURIComponent(payInfo[key]))
    }
    return result.join('&')
}

export function aliPay(payInfo: any) {
    window.location.href = 'https://mapi.alipay.com/gateway.do?' + getAliPayParams(payInfo)
}

interface WechatPayParam {
    payInfo: any,
    success?: any,
    fail?: any,
    cancel?: any
}
export function wechatPay(param: WechatPayParam) {
    const newPayInfo = {
        timestamp: param?.payInfo?.timeStamp,
        nonceStr: param?.payInfo?.nonceStr,
        package: param?.payInfo?.package,
        signType: param?.payInfo?.signType,
        paySign: param?.payInfo?.paySign
    }
}

export function errorMessage(error: any) {
    let message = null;
    if (error.response) {
        error = error.response.data
    }
    if (error.messages && error.messages.length > 0) {
        message = error.messages[0];
    } else if (error.errors && error.errors.length > 0) {
        message = error.errors[0];
    } else if (error.message) {
        message = error.message;
    }

    return message;
}

interface Deconstruct {
    color: string,
    fontSize: number,
    strike?: boolean,
    text: string
}
export function deconstruct(param: Deconstruct) {
    if (!param) { }
    return {
        text: param?.text,
        style: {
            color: param?.color,
            fontSize: param?.fontSize / 2 + 'px',
            textDecoration: param?.strike ? 'line-through' : 'none',
            marginLeft: '2px'
        }
    }
}

export function paramstr(params: any) {
    let strArr = [];
    for (let key in params) {
        strArr.push(camelToSnake(key) + '=' + encodeURIComponent(params[key]))
    }
    return strArr.join('&');
}

export function camelToSnake(value: any) {
    if (!value) {
        return '';
    }
    return value.replace(/[A-Z]+/g, function (m: any) {
        return '_' + m[0].toLowerCase();
    });
}

export function generateUrl(utmParams: any) {
    if (!isBrowser) return ''
    var app_store_url = 'redirect_url=' + encodeURIComponent(appDownloadLink);
    // 匹配以sa结尾的或者sa?xx结尾的
    var url = sensorsServer.replace(/\/sa$/, '/track_installation').replace(/(\/sa)(\?[^\/]+)$/, '/track_installation$2');
    if (url.indexOf('?') === -1) {
        url = url + '?' + app_store_url + getIoschPara(utmParams);
    } else {
        url = url + '&' + app_store_url + getIoschPara(utmParams);
    }
    return url;
    function getIoschPara(props: any) {
        var iosData = JSON.stringify({
            distinct_id: 'ios_channel_track_id',
            event: '$AppChannelMatching',
            properties: props,
            type: 'track_installation'
        });
        return '&data=' + encodeURIComponent(window.btoa(iosData));
    }
}

export function runScript(script: any) {
    const newScript = document.createElement('script');
    newScript.innerHTML = script.innerHTML;
    const src = script.getAttribute('src');
    if (src) newScript.setAttribute('src', src);

    document.head.appendChild(newScript);
    document.head.removeChild(newScript);
}

export function nihaopay(payInfo: any) {
    const page = payInfo.redirectHtml;
    document.documentElement.innerHTML = page;
    const scripts = document.querySelectorAll('script');
    for (let script of scripts) {
        runScript(script);
    }
}

/**
 * 获取文章资源 url , image / video / json, etc
 */
export function getResource(path = "") {
    if (!path) {
        return "";
    }

    if (
        /^((ht|f)tps?):\/\/([\w-]+(\.[\w-]+)*\/?)+(\?([\w\-\.,@?^=%&:\/~\+#]*)+)?$/i.test(
            path
        )
    ) {
        return path
    }

    if (path.startsWith("/")) {
        return CONTENT_BASE + "static/curation" + path + '?v=1'
    }
    return CONTENT_BASE + "static/curation/" + path + '?v=1'
}

// 跳转到一个h5
export function navigateToH5(url: string) {
    window.location.href = url;
}

export function toZC() {
    return `https://bybieyang.sobot.com/chat/h5/v2/index.html?sysnum=4291aa02fcc7428c96b43b3ee59ca7ad&channelid=2&partnerid=${LocalStorage.get(BXL_USER_ID)}`
}

export function initNoLogin() {
    LocalStorage.set(BXL_USER_ID, '');
    LocalStorage.set(BXL_USER_KEY, '');
    init()
}

export function methodOptionUseAli(options: []) {
    let _options: any = [];
    options.map((val: any) => {
        if (val.name === "ALIPAY") {
            _options.push(val);
        }
    })
    return _options;
}

export function evokedAppType() {
    const utmParams = JSON.parse(SessionStorage.get(BXL_UTM_PARAMS) || '{}');
    return utmParams?.utmSource === SMZDM ? `global` : `global`
}


export function copyTextToClipboard(text: any, callback: Function) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        callback(successful);
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
        callback(false)
    }

    document.body.removeChild(textArea);
}

export function removeHash(url: string) {
    const hashStart = url.indexOf('#');
    if (hashStart !== -1) {
        url = url.slice(0, hashStart);
    }
    return url;
}

export function getHash(url: string) {
    let hash = '';
    const hashStart = url.indexOf('#');
    if (hashStart !== -1) {
        hash = url.slice(hashStart);
    }
    return hash;
}

export function clearHashParams(hash: string) {
    const paramsStart = hash.indexOf('?');
    if (paramsStart !== -1) {
        hash = hash.slice(0, paramsStart);
    }
    return hash;
}

export function fixNumber(number: number, decimal = 2) {
    return Math.round(number * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

export function getNumberInt(number: number) {
    return Math.floor(number);
}

export function getNumberFloat(number: number) {
    var num = fixNumber(number % 1);
    if (num === 0) {
        return ''
    } else {
        return Number(num).toString().slice(2)
    }
}

export function goToDownload(productId?: string) {
    if (!window) {
        return
    }
    const utmParams = JSON.parse(SessionStorage.get(BXL_UTM_PARAMS) || '{}');
    const utmStr = paramstr(utmParams);
    const sensorsUrl = generateUrl({
        $utm_source: utmParams?.utmSource || 'byh5',
        $utm_medium: utmParams?.utmMedium || 'download-bar',
        $utm_term: utmParams?.utmTerm || 'product-detail-page',
        $utm_content: utmParams?.utmContent || '',
        $utm_campaign: utmParams?.utmCampaign || ''
    });
    const dLink = sensorsUrl ? sensorsUrl : 'https://sensorsdata.bybieyang.com/r/Gz';
    const bieyang = encodeURIComponent(`bieyang:///pdp?productId=${productId}&${utmStr}&type=${evokedAppType()}`);
    const downloadLink = encodeURIComponent(dLink);
    window.location.href = `${STAIC_ORIGIN}/static/html/appEntry/index.html?bieyang=${bieyang}&download=${downloadLink}`;
}

export function getIdFromUrl() {
    if (isBrowser) {
        const pathname = window.location.pathname;
        if (pathname.indexOf('/dp') !== -1) {
            let id = window.location.hash
            id = id.replace(/(#|\/index.html|(\/$))/g, "");
            return id
        } else {
            const pathArr = pathname.split('/').filter(Boolean);
            return pathArr.slice(-1)[0];
        }
    }
}

export function parseLegoId(dynamicLink: string | undefined) {
    // must be webview deeplink
    if (!dynamicLink || dynamicLink.indexOf('\/wvp') === -1) return undefined
    try {
      let _paramsPart = dynamicLink.substring(dynamicLink.indexOf('?'))
      const linkParams = new URLSearchParams(_paramsPart)
      const parsedLink = bxlUtils.uri.decodeBase64(linkParams?.get('link'))
    //   console.log(parsedLink)
      if (parsedLink?.indexOf('lego') !== -1) {
        // console.log(parsedLink)
        const result = parsedLink.match(/pages\/(.+)\/index.html/)
        if (result?.[1]) {
          return result[1]
        }
      }
    } catch (e) {
        console.error(e)
    } 
    return undefined
}

export function shuffle(arr: any): any[] {
    if (!arr?.length) return []
    let len = arr.length
    let dump = [...arr]
    for(let i = 0; i < len - 1; i++){
      let idx = Math.floor(Math.random() * (len - i))
      let temp = dump[idx]
      dump[idx] = dump[len - i - 1]
      dump[len - i -1] = temp
    }
    return dump
}

export function getImageUrl(url: string | undefined | null, styleName?: string): string {
    if (!url) {
        return ""
    }
    if (url.startsWith("http://")) {
        url = url.replace("http://", "https://")
    }
    // if image url has any query params, assume that the image will override the oss params 
    if (/(beyondstyle\.us|bybieyang\.com|bieyangapp\.com)\/.*\.(png|jpg|jpeg)$/i.test(url)) {
        // by default s1 -> webp + 90q
        return url + `!${styleName || "s1"}`
    }
    return url
}

export function startSobotChat(productInfo: any) {
    if (!isBrowser) return
    try {
        const zc = (window as any).zc
        if (!zc) return
        zc("config", {
            "color": "D27D3F",
            photo_flag: true,
            show_manual: true,
            leave_msg_flag: true,
            guide_flag: true,
            show_evaluate: 1,
            "show_face": 1,
            type: 3,
            width: 500,
            dragging: true,
            cut_flag: true,
            msg_flag: 0,
            card_title: productInfo?.nameCN || productInfo?.name || '',
            card_url: encodeURIComponent(`https://bieyangapp.com/pd/${productInfo?.id}/`),
            card_picture: encodeURIComponent(productInfo?.images?.[0]?.thumbnail?.url),
        })
        zc("frame_status", function(data: any) {
            // console.log(data)
            if (data?.code === '000002') {
                zc("frame_manual", function(res: any) {
                    // console.log(res)
                    zc("frame_status")
                })
            } else if(data === 'collapse') {
                zc('frame_status')
            }
        })
    } catch(e) {
        console.error(e)
    }
}

export function getProductAlt(product: any) {
    const name = product?.nameCN || product?.name || ''
    const brand = product?.displayBrand || product?.brand || ''
    const price = product?.priceTagCN || product?.priceTag || ''
    return `商品${brand ? `${brand}|${name}` : name},价格${price}`
}

export function isMobile() {
    if (!isBrowser) {
        return false
    }
    if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        return true; // 移动端
    } else {
        return false; // PC端
    }
}
