import React, { useContext, useState } from 'react';
import { useGetPrice, useChosenInfo, useChoseSku, useAddCart, useExpressBuy } from '@/lib/hooks';
import { ProductContext, SKUContext, GlobalContext } from '@/components/Context';
import { getImageUrl, goToDownload, isMobile } from '@/lib/utils';
// import { isProd } from '@lib/env';
import history from '@lib/history';
import Drawer from '@/components/Drawer';
// import Download from '@modules/Common/Download';
import './specification-drawer.scss';

interface SpecificationDrawerProps {
    visible: boolean,
    isExpressBuy: boolean,
    onClose: (event: React.MouseEvent) => void
}

function SpecificationDrawer(props: SpecificationDrawerProps) {
    const [visible, setVisible] = useState(false);
    const chosenSku = useChoseSku();
    const addCart = useAddCart();
    const expressBuy = useExpressBuy();

    function handlePickerClick(type: string, item: any) {
        chosenSku({ [type]: item });
    }

    function handleAddCart(event: React.MouseEvent) {
        event.stopPropagation();
        if(isMobile()) {
            goToDownload();
        } else {
            setVisible(true);
        }
    }

    return (
        <Drawer
            visible={props.visible}
            onClose={props.onClose}
            className='m-specification-drawer'
            id="C-Specification-Drawer"
        >
            <CartProductInfo />
            <div className='picker-scroll-wrap'>
                <CartColorPicker handlePickerClick={handlePickerClick} />
                <CartWidthPicker handlePickerClick={handlePickerClick} />
                <CartSizePicker handlePickerClick={handlePickerClick} />
                <Count />
                <div className='tips'>图片仅供示意，颜色、尺码、宽度请以选项文字为准</div>
            </div>
            <div className='add-cart-button' onClick={handleAddCart}>{props.isExpressBuy ? '立即购买' : '加入购物袋'}</div>
            {/* {visible && <Download onClose={() => setVisible(false)} />} */}
        </Drawer>
    )
}

function CartProductInfo() {
    const skuContext: any = useContext(SKUContext);
    const productContext: any = useContext(ProductContext);
    const { chosenSku } = skuContext;
    const { product } = productContext;
    const price = useGetPrice();
    const previewImg = chosenSku ? chosenSku?.images?.[0]?.full.url : product?.images?.[0]?.full?.url;
    return (
        <div className='m-cart-product-info'>
            <div className='img-wrap'>
                <img className='preview-img' src={getImageUrl(previewImg)} />
            </div>
            <div className='product-info'>
                <div className='preview-price'>
                    <div className='price'>{price.priceTag}</div>
                    <div className='original-price'>{price.originalPriceTag}</div>
                </div>
                <div className='main-price'><span className='small'>约</span>{price.priceTagCN}</div>
                <Tips />
            </div>
        </div>
    )
}

function Tips(props: any) {
    const [hasChosen, chosenFinished, chosen] = useChosenInfo();
    if (hasChosen) {
        return (
            <div className='tips'>
                已选：
                {Object.entries(chosen).map((item: Array<any>, index: number) => {
                    if (item[1]) {
                        return <span className='selected-item' key={index}>{item[1]}</span>
                    }
                    return;
                })}
            </div>
        )
    } else {
        return (
            <div className='tips'>
                请选择
                {Object.entries(chosen).map((item: Array<any>, index: number) => {
                    if (item[1] === null) {
                        switch (item?.[0]) {
                            case 'color':
                                return <span className='unselected-item' key='color'>颜色</span>
                            case 'width':
                                return <span className='unselected-item' key='width'>宽度</span>
                            case 'size':
                                return <span className='unselected-item' key='size'>尺码</span>
                        }
                    }
                    return;
                })}
                <span className='unselected-item'>数量</span>
            </div>
        )
    }
}

interface PickerInterface {
    handlePickerClick: (type: string, item: any) => void
}

function CartColorPicker(props: PickerInterface) {
    const productContext: any = useContext(ProductContext);
    const skuContext: any = useContext(SKUContext);
    const availableColor = skuContext?.availableOption?.colors;
    const colors = productContext?.product?.colors || [];
    const activeColor = skuContext?.chosenParam?.color;

    if (!colors || !colors.length) return null;
    return (
        <div className='m-picker m-cart-color-picker'>
            <div className='title'>颜色：</div>
            {colors.map((item: any, index: number) => {
                const isActive = activeColor && (item.name === activeColor);
                const isAvailable = !availableColor || availableColor.find((ele: any) => ele === item.name);
                if (isActive) {
                    return (
                        <ActivePickerItem key={item.name} handlePickerClick={() => props.handlePickerClick('color', null)}>
                            <div className='picker-img-wrap'>
                                <img src={getImageUrl(item?.image?.full?.url)} />
                                {item.name}
                            </div>
                        </ActivePickerItem>
                    )
                } else if (isAvailable) {
                    return (
                        <AvailablePickerItem key={item.name} handlePickerClick={() => props.handlePickerClick('color', item.name)}>
                            <div className='picker-img-wrap'>
                                <img src={item?.image?.full?.url} />
                                {item.name}
                            </div>
                        </AvailablePickerItem>
                    );
                } else {
                    return (
                        <DisablePickerItem key={item.name}>
                            <div className='picker-img-wrap'>
                                <img src={item?.image?.full?.url} />
                                {item.name}
                            </div>
                        </DisablePickerItem>
                    );
                }
            })}
        </div>
    )
}

function CartWidthPicker(props: PickerInterface) {
    const productContext: any = useContext(ProductContext);
    const skuContext: any = useContext(SKUContext);
    const availableWidth = skuContext?.availableOption?.widths;
    const width = productContext?.product?.attributes?.attributes?.width?.choices || [];
    const activeWidth = skuContext?.chosenParam?.width;

    if (!width || !width.length) return null;
    return (
        <div className='m-picker m-cart-width-picker'>
            <div className='title'>宽度：</div>
            {width.map((item: any, index: number) => {
                const isActive = activeWidth && (item === activeWidth);
                const isAvailable = !availableWidth || availableWidth.find((ele: any) => ele === item);
                if (isActive) {
                    return (
                        <ActivePickerItem key={item} handlePickerClick={() => props.handlePickerClick('width', null)}>
                            {item}
                        </ActivePickerItem>
                    )
                } else if (isAvailable) {
                    return (
                        <AvailablePickerItem key={item} handlePickerClick={() => props.handlePickerClick('width', item)}>
                            {item}
                        </AvailablePickerItem>
                    );
                } else {
                    return (
                        <DisablePickerItem key={item}>
                            {item}
                        </DisablePickerItem>
                    );
                }
            })}
        </div>
    )
}

function CartSizePicker(props: PickerInterface) {
    const productContext: any = useContext(ProductContext);
    const skuContext: any = useContext(SKUContext);
    const globalContext: any = useContext(GlobalContext);
    const availableSize = skuContext?.availableOption?.sizes;
    const sizes = productContext?.product?.sizes || [];
    const activeSize = skuContext?.chosenParam?.size;
    const activeSizeTips = sizes.find((item: any) => item.name === activeSize)?.displayName;
    const productId = productContext?.product?.id || '';
    const sizeReference = productContext?.product?.sizeReference;

    function handleChoseSize(event: React.MouseEvent) {
        event.stopPropagation();
        const link = sizeReference?.refLink;
        globalContext.updateIframeLink(link);
        skuContext.updateDrawerVisible(false);
        history.push('/iframe');
    }

    if (!sizes || !sizes.length) return null;
    return (
        <div className='m-picker m-cart-size-picker'>
            <div className='title'>
                <div>尺码：<span className='size-tips'>{activeSizeTips}</span></div>
                {sizeReference?.refLink ? <div className='chose-size' onClick={handleChoseSize}>{sizeReference?.refText}</div> : null}
            </div>
            {sizes.map((item: any, index: number) => {
                const isActive = activeSize && (item.name === activeSize);
                const isAvailable = !availableSize || availableSize.find((ele: any) => ele === item.name);
                if (isActive) {
                    return (
                        <ActivePickerItem key={item.name} handlePickerClick={() => props.handlePickerClick('size', null)}>
                            {item.label || item.name}
                        </ActivePickerItem>
                    )
                } else if (isAvailable) {
                    return (
                        <AvailablePickerItem key={item.name} handlePickerClick={() => props.handlePickerClick('size', item.name)}>
                            {item.label || item.name}
                        </AvailablePickerItem>
                    );
                } else {
                    return (
                        <DisablePickerItem key={item.name}>
                            {item.label || item.name}
                        </DisablePickerItem>
                    );
                }
            })}
        </div>
    )
}

interface PickerItemInterface {
    status?: 'active' | 'available' | 'disable',
    handlePickerClick?: () => void,
    children: React.ReactNode
}
function PickerItem(props: PickerItemInterface) {
    function handleClick(event: React.MouseEvent) {
        event.stopPropagation();
        if (props.status && props.status !== 'disable') {
            props.handlePickerClick && props.handlePickerClick();
        }
    }
    return (
        <div
            className={`picker-item ${props.status}`}
            onClick={handleClick}
        >
            {props.children}
        </div>
    )
}

PickerItem.defaultProps = {
    active: true,
    children: ''
}

function AvailablePickerItem(props: PickerItemInterface) {
    return PickerItem({
        ...props,
        status: 'available'
    })
}

function ActivePickerItem(props: PickerItemInterface) {
    return PickerItem({
        ...props,
        status: 'active'
    })
}

function DisablePickerItem(props: PickerItemInterface) {
    return PickerItem({
        ...props,
        status: 'disable'
    })
}

function Count() {
    const skuContext: any = useContext(SKUContext);
    const productContext: any = useContext(ProductContext);
    const count = skuContext.count || 1;
    const minusAvailble = count > 1;
    const plusAvailble = count < 5;
    const productId = productContext?.product?.id || '';

    function handleClick(event: React.MouseEvent, count: number, btnType: string) {
        event.stopPropagation();
        skuContext.updateCount(count);
    }

    return (
        <div className='m-cart-count'>
            <div>数量：</div>
            <div className='count-button-area'>
                <div
                    className={`count-button minus ${minusAvailble ? 'availble' : 'disable'}`}
                    onClick={minusAvailble ? (event) => handleClick(event, count - 1, 'minus') : (event) => event.stopPropagation()}
                >－</div>
                <div>{count}</div>
                <div
                    className={`count-button plus ${plusAvailble ? 'availble' : 'disable'}`}
                    onClick={plusAvailble ? (event) => handleClick(event, count + 1, 'plus') : (event) => event.stopPropagation()}
                >＋</div>
            </div>
        </div>
    )
}

export default SpecificationDrawer;