import React, { useContext } from 'react';
import { ProductContext, SKUContext } from '@components/Context';
import SpecificationDrawer from '@modules/Product/SpecificationDrawer';
import { useChosenInfo } from '@lib/hooks';
import './specification.scss';

function Specification() {
    const productContext: any = useContext(ProductContext);
    const skuContext: any = useContext(SKUContext);
    const { product } = productContext;
    const hasOption = product?.colors?.length ||
        product?.sizes?.length || 
        product?.attributes?.attributes?.width?.choices?.length;

    function onClose(event: React.MouseEvent) {
        event.stopPropagation();
        skuContext.updateDrawerVisible(false);
    }
    function openDrawer(event: React.MouseEvent) {
        event.stopPropagation();
        const disabled = productContext?.product?.buyButtons?.[0]?.disabled;
        if (disabled) return;
        skuContext.updateDrawerVisible(true);
    }
    if (productContext?.product?.id && hasOption) {
        return (
            <div className='m-specification' onClick={openDrawer}>
                <div className='specification-title'>
                    <div className='title'>规格</div>
                    <Tips />
                </div>
                {product.notice && <div className='notice'>{product.notice}</div>}
                <SpecificationDrawer visible={skuContext?.drawerVisible} isExpressBuy={skuContext?.isExpressBuy} onClose={onClose} />
            </div>
        )
    }
    return null;
}

//TODO
function Tips() {
    const [hasChosen, chosenFinished, chosen] = useChosenInfo();
    if (chosenFinished) {
        return (
            <div className='tips'>
                已选：
                {Object.entries(chosen).map((item: Array<any>, index: number) => {
                    if (item[1]) {
                        return <span className='selected-item' key={index}>{item[1]}</span>
                    }
                    return;
                })}
            </div>
        )
    } else {
        return (
            <div className='tips'>
                请选择
                {Object.entries(chosen).map((item: Array<any>, index: number) => {
                    if (item[1] === null) {
                        switch(item[0]) {
                            case 'color':
                                return <span className='unselected-item' key='color'>颜色</span>
                            case 'width':
                                return <span className='unselected-item' key='width'>宽度</span>
                            case 'size':
                                return <span className='unselected-item' key='size'>尺码</span>
                        }
                    }
                    return;
                })}
            </div>
        )
    }
}

export default Specification