import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react';
import { getSizeInfo } from '@/service/api';
import { LayoutContext, ProductContext } from '@/components/Context';
import * as styles from './product-pc.module.scss';
import Breadcrumb from '@/modules/productPc/Breadcrumb';
import Preview from '@/modules/productPc/Preview';
import ProductInfo from '@/modules/productPc/ProductInfo';
import Anchor from '@/modules/productPc/Anchor';
import Details from '@/modules/productPc/Details';
import Merchant from '@/modules/productPc/Merchant';
import Brand from '@/modules/productPc/Brand';
import Size from '@/modules/productPc/Size';
import useCateName from '@/modules/Common/useCateName';
import qrcode from '@images/qrcode-download-app.png';
import customIcon from '@images/ic_customer.png';
import qrIcon from '@images/qrcodeicon.png';
import topIcon from '@images/top.png';
import Download from '@/modules/Home/Download';
import ProductComment from '@/modules/productPc/Comment';
import SimilarProduct from '@/modules/Product/SimilarProduct';
import tabs from '@/layouts/tabs.json';
import { startSobotChat } from '@/lib/utils';

const { Item }: any = Anchor

const Product = (props: any) => {
  const { id: productId, pageContext, isPC } = props || {}
  const { updateHeaderState, updateActiveCategroyIndex,  setHeaderUnderline} = useContext<any>(LayoutContext)
  const productContext: any = useContext(ProductContext)
  const { product: productInfo, merchant, brand, comment, translation, similar } = productContext || {}
  const [sizeInfo, setSizeInfo] = useState(() => {
    return pageContext?.sizeInfo
  })
  const cateList = useCateName(productInfo?.categoryIds)

  const cate = useMemo(() => {
    const list = [...cateList || []] 
    if(productInfo?.name) {
      list.push({
        cid: '',
        term: productInfo.displayBrand || productInfo.nameCN || productInfo.name
      })
    }
    return list
  }, [cateList, productInfo])

  useEffect(() => {
    const categoryId = cateList?.[0]?.cid
    if(categoryId) {
      const index = tabs.findIndex(item => item.id && item.id === categoryId)
      updateActiveCategroyIndex(index > -1 ? index : 0)
    }else {
      updateActiveCategroyIndex(0)
    }
    return () => {
      updateActiveCategroyIndex(-1)
    }
  }, [cateList])

  function handleTop() {
    const html = document.querySelector('html');
    html?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  useEffect(() => {
    setHeaderUnderline(true)
    updateHeaderState('relative')
    return () => {
      updateHeaderState('fixed')
      setHeaderUnderline(false)
    }
  }, [])

  useEffect(() => {
    if (!isPC || !productId || pageContext?.sizeInfo) return
    getSizeInfo(productId).then((res: any) => {
      setSizeInfo(res)
    }).catch(() => {})
  }, [isPC, productId, pageContext?.sizeInfo])

  const onCustomServiceClick = useCallback(() => {
    startSobotChat(productInfo)
  }, [productInfo])

  return (
    <>
      <section className={`${styles.container} ${props.className || ''}`}>
        <Breadcrumb cate={cate} />
        <div className={styles.info}>
          <Preview images={productInfo?.images} product={productInfo} />
          <ProductInfo info={productInfo} translation={translation} merchantName={merchant?.name} />
        </div>
        <Anchor>
          <Item title="商品详情">
            <Details product={productInfo} />
          </Item>
          {
            sizeInfo ? (
              <Item title="尺码信息">
                <Size info={sizeInfo}  />
              </Item>
            ): null
          }
          {
            merchant ? (
              <Item title="商家信息">
                <Merchant data={merchant} />
              </Item>
            ): null
          }
          {
            brand ? (
              <Item title="品牌信息">
                <Brand data={brand} />
              </Item>
            ): null
          }
          {
            comment?.comments?.length ? (
              <Item title="商品评论">
                <ProductComment comment={comment} />
              </Item>
            ) : null
          }
          { 
            similar?.length ? (
            <Item title="相似商品">
              <SimilarProduct similar={similar} />
            </Item>
            ) : null
          }
        </Anchor>
        <div className={styles.floatingDownload}>
          <div
            id="customBtn"
            className={`${styles.csWrap} zhiCustomBtn`}
            onClick={onCustomServiceClick}
          >
            <img src={customIcon} className={styles.csIcon} />
          </div>
          <div className={styles.qrCodeWrap}>
            <Download qrcodeImg={qrcode} amount={'688元'} className={styles.qrCode} />
            <img src={qrIcon} className={styles.qrIcon} />
          </div>
          <img src={topIcon} onClick={handleTop} className={styles.topIcon} />
        </div>
      </section>
    </>
  )
}

Product.displayName="Product"

export default Product