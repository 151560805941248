import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './Breadcrumb.module.scss'
// import { Link } from 'gatsby'
const Breadcrumb = ({ cate }) => {
  return (
    <ul className={styles.breadcrumb}>
      {
        cate.map((item, index) => (
          <li key={item.cid}>
            {
              index < cate?.length - 1 ? (
                <a href={`/category/${item.cid}/`}>{item.term}</a>
              ) : <a href={void(0)}><strong>{item.term}</strong></a>
            }
          </li>
        ))
      }
    </ul>
  )
}

Breadcrumb.displayName = 'Breadcrumb'

Breadcrumb.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    cid: PropTypes.string,
    term: PropTypes.string,
  }))
}

Breadcrumb.defaultProps = {
  cate: []
}

export default Breadcrumb