import React, { useEffect, useState } from 'react';
import qrcode from '@images/qrcode-download-app.jpeg';
import close from '@images/close.png';
import Portal from '@components/Portal';
import { getGiftAmount } from '@service/api';
import LocalStorage from '@/lib/localStorage';
import { GIFT_AMOUNT } from '@lib/constants';
import './download.scss';

function Download(props: any) {
    const {onClose } = props || {};
    const [amount, setAmount] = useState(LocalStorage.get(GIFT_AMOUNT));

    useEffect(() => {
        if(!LocalStorage.get(GIFT_AMOUNT)) {
            getGiftAmount()
                .then((res: any) => {
                    LocalStorage.set(GIFT_AMOUNT, res.amount);
                    setAmount(res.amount);
                })
        }
    }, []);

    return (
        <div className='m-download-dialog'>
            <div className='download-content'>
                <img src={close} className='close' onClick={onClose} />
                <img src={qrcode} className='qrcode' />
                <div className='title'>扫码下载别样海外购APP</div>
                <div className='title'>领取新人<span className='red'>{amount || '688元'}</span>专享礼包</div>
                <div className='text'>欧美潮流正品 | 官网价格同步 | 一键安心购买</div>
            </div>
        </div>
    )
}

export default Portal(Download);