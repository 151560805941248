import React from 'react';
import Carousel from '@components/Carousel';
import { getImageUrl, getProductAlt } from '@/lib/utils';
import './product-detail-carousel.scss';
import { openAppStore } from '@/lib/appstore';
import { sendClickEvent } from '@/service/trackingService';

@Carousel
class ProductDetailCarousel extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    onClick = (e: any) => {
        openAppStore(this.props.productId)
        sendClickEvent(this.props.productId, e.currentTarget?.id)
    }

    render() {
        const { carouselItem, index, product } = this.props;
        return (
            <div id='pdbanner__img' className='m-product-detail-carousel' key={index} onClick={this.onClick}>
                <img
                    src={getImageUrl(carouselItem.full?.url || carouselItem.thumbnail?.url)}
                    alt={`${getProductAlt(product)},第${index + 1}张图片`}
                />
            </div>
        )
    }
}

export default ProductDetailCarousel;