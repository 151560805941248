import HTTP from '../lib/http'

// 首页猜你喜欢商品
function getPymlHomeProducts(from: number = 0, size: number = 40) {
  return HTTP.post(`/api/v2/recommend/product/bottom`, {
    from,
    size,
  }, {
    headers: {
        "Content-Type": "application/x-protobuf-json"
    },
    data: {}
  })
}

export {
  getPymlHomeProducts,
}