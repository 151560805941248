// extracted by mini-css-extract-plugin
export var arrowDown = "CommentItemPc-module--arrow-down--47800";
export var attributes = "CommentItemPc-module--attributes--cfe72";
export var commentContent = "CommentItemPc-module--comment-content--824b5";
export var commentFooter = "CommentItemPc-module--comment-footer--b18e9";
export var commentImage = "CommentItemPc-module--comment-image--97aca";
export var commentImageWrap = "CommentItemPc-module--comment-image-wrap--84156";
export var commentItem = "CommentItemPc-module--comment-item--2f6fd";
export var commentItemContainer = "CommentItemPc-module--comment-item-container--92136";
export var footerItem = "CommentItemPc-module--footer-item--3e68b";
export var moreReply = "CommentItemPc-module--more-reply--b98cf";
export var replyContent = "CommentItemPc-module--reply-content--bb229";
export var replyDate = "CommentItemPc-module--reply-date--eb036";
export var replyDetail = "CommentItemPc-module--reply-detail--6f3b5";
export var replyHeader = "CommentItemPc-module--reply-header--1f00b";
export var replyIconWrap = "CommentItemPc-module--reply-icon-wrap--a246f";
export var replyItem = "CommentItemPc-module--reply-item--c4983";
export var replyParentName = "CommentItemPc-module--reply-parent-name--536dd";
export var replyPrefix = "CommentItemPc-module--reply-prefix--f2dca";
export var replyUserAvatar = "CommentItemPc-module--reply-user-avatar--4af4f";
export var replyUserName = "CommentItemPc-module--reply-user-name--03ab8";
export var replyWrap = "CommentItemPc-module--reply-wrap--8193c";
export var starLabel = "CommentItemPc-module--star-label--ebc2c";
export var starWrap = "CommentItemPc-module--star-wrap--76c49";
export var timeIconWrap = "CommentItemPc-module--time-icon-wrap--b6e10";
export var userAvatar = "CommentItemPc-module--user-avatar--33e79";
export var userName = "CommentItemPc-module--user-name--e8858";
export var userWrap = "CommentItemPc-module--user-wrap--01645";