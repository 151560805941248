import React, { useEffect, useState, useRef, TouchEventHandler } from 'react'
import { Slider } from 'antd-mobile';
import './index.scss'
// import 'antd-mobile/dist/antd-mobile.css';  // or 'antd-mobile/dist/antd-mobile.less'

const DiscountSort = (props: any) => {
  const { q, setSearchQuery, searchProducts } = props

  const resetQuery = () => {
    setSearchQuery('discountRange', ['', ''])
    const [amSliderHandle1, amSliderHandle2] = document.querySelectorAll('.adm-slider-thumb-container')

    amSliderHandle1?.setAttribute('data-text', '0折')
    amSliderHandle2?.setAttribute('data-text', '不打折')
}

  useEffect(() => {
    const [amSliderHandle1, amSliderHandle2] = document.querySelectorAll('.adm-slider-thumb-container')
    amSliderHandle1?.setAttribute('data-text', q.discountRange1 ? `${q.discountRange1 / 10} 折` : '0折')
    amSliderHandle2?.setAttribute('data-text', q.discountRange2 ? `${q.discountRange2 / 10} 折` : '不打折')
  }, [])

  const onRange = (value: any) => {
    const [range1, range2] = value
    const [amSliderHandle1, amSliderHandle2] = document.querySelectorAll('.adm-slider-thumb-container')
    amSliderHandle1?.setAttribute('data-text', range1 ? `${range1 / 10} 折` : '0折')
    amSliderHandle2?.setAttribute('data-text', range2 !== 100 ? `${range2 / 10} 折` : '不打折')
    setSearchQuery('discountRange', [range1, range2])
  }
  return (
    <div className="discountSortContent">
      <div className="process">
        <Slider
          min={0}
          max={100}
          step={1}
          range={true}
          value={[Number(q.discountRange1) || 0, Number(q.discountRange2) || 100]}
          onChange={onRange}
        />
      </div>
      <div className="searchBtnContent">
        <div className="resetBtn baseBtn" onClick={resetQuery}>
          重置
        </div>
        <div className="sureBtn baseBtn" onClick={searchProducts}>
          确认
        </div>
      </div>
    </div>
  )
}

export default DiscountSort
