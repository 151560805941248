import React from 'react'
import { Link } from 'gatsby'
import * as styles from './ProductItem.module.scss'
import { getImageUrl } from '@lib/utils';

const ProductItem = ({ name, product = {}}) => {
  const { image, label, marks, labelView, id } = product
  const shortLabels = (labelView && labelView.shortLabels) || []
  const labelViews = shortLabels.reduce((init, item ) => {
    return [...init, ...(item.labels || []).filter((label) => label.text)]
  }, [])

  return  (
      <a className={styles.productItem} href={`/pd/${id}/`} title={label || name || ''}>
        <div className={styles.img}>
          <img src={getImageUrl(image?.path)} alt={`${label || name || '商品图片'},价格${marks?.[0]}`} />
        </div>
        <div className={styles.label}>
          { label }
        </div>
        <div className={styles.country}>
          { name }
        </div>
        <div className={styles.marks}>
          {marks.map((price, i) => (
            <span key={i}>{price}</span>
          ))}
        </div>
        <div className={styles.labels}>
          {labelViews.map(item => item.text).join(' | ')}
        </div>
      </a>
  )
}

export default ProductItem