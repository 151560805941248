import React from 'react';
import Fav from '@/images/favicon.ico';
import { Helmet } from 'react-helmet';

function SEO(props: any) {
  const { description, keywords, title, canonical, image, jsonLd } = props || {}
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="applicable-device" content="pc,mobile" />
        <meta name="og:title" property="og:title" content={title} />
        <meta name="og:description" property="og:description" content={description} />
        <meta name="og:site_name" property="og:site_name" content={'别样海外购'} />
        <meta name="og:type" property="og:type" content='website' />
        <meta name="og:author" property="og:author" content='别样海外购' />
        <meta name="og:image" property="og:image" content={image || 'https://www.bieyangapp.com/static/images/new_logo.png'} />
        <meta name="description" content={description} />
        {/* <meta name="image" content={image} /> */}
        {canonical ? <link rel="canonical" href={canonical} /> : null}
        {canonical ? <meta name="og:url" property="og:url" content={canonical} /> : null}
        {
          jsonLd ? (
            <script type="application/ld+json">
              {jsonLd}
            </script>
          ) : null
        }
        <link rel="shortcut icon" type='image/x-icon' href={Fav} />
        <meta name='keywords' content={keywords}></meta>
        <meta name='google-site-verification' content='_JB8ytuqVrvViJAczGvWLMwXTgrgx4O7i5cRSY8O9To'></meta>
        <meta name='baidu-site-verification' content='code-s7H5z2bDV5'></meta>
        <meta name="google-adsense-account" content="ca-pub-9185778655077786"></meta>
      </Helmet>
    </React.Fragment>
  )
}

export default SEO
