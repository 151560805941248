import React, { useEffect, useState } from 'react';
import Portal from '@components/Portal';
import closeImg from '@images/close.svg';
import './drawer.scss';

interface DrawerInterface {
    visible?: boolean,
    children: React.ReactNode,
    hasCloseBtn?: boolean,
    onClose: (event: React.MouseEvent) => void,
    className?: string,
    id: string
}

function Drawer(props: DrawerInterface) {
    const { visible, className } = props;
    const [open, setOpen] = useState(props.visible);
    const [hidden, setHidden] = useState(!props.visible);

    useEffect(() => {
        if (visible && !open) {
            setHidden(false);
        } else if (!visible && open) {
            setOpen(false);
        }
    }, [visible]);

    useEffect(() => {
        if (!hidden) {
            setTimeout(() => {
                setOpen(true);
            }, 50);
        }
    }, [hidden]);

    useEffect(() => {
        if (!open) {
            setTimeout(() => {
                setHidden(true);
            }, 600);
        }
    }, [open]);


    if (hidden) return null;
    return (
        <div className={`c-drawer ${open ? 'drawer-out' : 'drawer-in'} ${className || ''}`}>
            <div className='mask' onClick={props.onClose} />
            <div className='content'>
                {props.children}
                {props.hasCloseBtn && <img className='close-btn' src={closeImg} onClick={props.onClose} />}
            </div>
        </div>
    )
}

Drawer.defaultProps = {
    visible: false,
    hasCloseBtn: false
}

export default Portal(Drawer);