import { analytics } from '@/service/trackingService';
import SessionStorage from '@/lib/session';
import bxlUtils from 'bxl-utils';

const BIEYANGAPP_PACKAGENAME = 'com.borderxlab.bieyang'
const APP_STORE_LINK = 'https://apps.apple.com/cn/app/id947269781'
const HUAWEI_APP_MARKET_DEEPLINK = `appmarket://details?id=${BIEYANGAPP_PACKAGENAME}`
const VIVO_APP_MARKET_DEEPLINK = `vivomarket://details?id=${BIEYANGAPP_PACKAGENAME}`
const OPPO_APP_MARKET_DEEPLINK = `oppomarket://details?packagename=${BIEYANGAPP_PACKAGENAME}`
const XIAOMI_APP_MARKET_DEEPLINK = `mimarket://details?id=${BIEYANGAPP_PACKAGENAME}`
const SAMSUNG_APP_MARKET_DEEPLINK = `samsungapps://ProductDetail/${BIEYANGAPP_PACKAGENAME}`
const SAMSUNG_APP_MARKET_DOWNLOAD_LINK = `https://apps.galaxyappstore.com/detail/${BIEYANGAPP_PACKAGENAME}`
const YINNGYONGBAO_APP_MARKET_DEEPLINK = `tmast://appdetails?pname=${BIEYANGAPP_PACKAGENAME}`
const YINGYONGBAO_APP_MARKET_DOWNLOAD_LINK = `https://a.app.qq.com/o/simple.jsp?pkgname=${BIEYANGAPP_PACKAGENAME}`
const BAIDU_APP_MARKET_DOWNLOAD_LINK = 'https://mobile.baidu.com/item?docid=31866412'

const androidAppMarketMatches = [{
  match: /huawei|hornor/i,
  deeplink: HUAWEI_APP_MARKET_DEEPLINK,
  url: YINGYONGBAO_APP_MARKET_DOWNLOAD_LINK,
}, {
  match: /vivo/i,
  deeplink: VIVO_APP_MARKET_DEEPLINK,
  url: YINGYONGBAO_APP_MARKET_DOWNLOAD_LINK,
}, {
  // to avoid a collision with /Micromessage/ ua
  match: /xiaomi|Mi\s|Redmi|mix/i,
  deeplink: XIAOMI_APP_MARKET_DEEPLINK,
  url: YINGYONGBAO_APP_MARKET_DOWNLOAD_LINK,
}, {
  // oppo ua 管理比较混乱，无法准确定位
  match: /oppo/i,
  deeplink: OPPO_APP_MARKET_DEEPLINK,
  url: YINGYONGBAO_APP_MARKET_DOWNLOAD_LINK,
}, {
  match: /samsung|SM-|GT-|SCH-/i,
  deeplink: SAMSUNG_APP_MARKET_DEEPLINK,
  url: SAMSUNG_APP_MARKET_DOWNLOAD_LINK,
}]

function openAppStore(productId: string = '') {
  if (typeof window === 'undefined') {
    return
  }
  openAppStoreProcess()

  // if (productId) {
  //   try {
  //     const utmParams = analytics?.utmParams()
  //     tryOpenBieyangApp(
  //       `bieyang://pdp?produtdId=${productId}&utm_source=${utmParams?.utm_source || 'baidu_bmc'}&utm_term=${utmParams?.utm_term || productId}`,
  //       () => {
  //         openAppStoreProcess()
  //       },
  //     )
  //   } catch(e) {
  //     console.error(e)
  //     openAppStoreProcess()
  //   }
  // } else {
  // }
}

function openAppStoreProcess() {
  try {
    const ua = navigator?.userAgent || ''
    if (ua.match(/MicroMessenger/i)) {
      // wechat 只能通过应用宝中转
      window.location.href = YINGYONGBAO_APP_MARKET_DOWNLOAD_LINK
    } else if (ua.match(/iP(hone|ad|od)/i)) {
      // open app store
      window.location.href = APP_STORE_LINK
    } else {
      // 优先 baidu app 匹配
      if (ua.match(/baiduboxapp/i)) {
        window.location.href = BAIDU_APP_MARKET_DOWNLOAD_LINK
        return
      }
      // 应用商店匹配
      for (let i = 0; i < androidAppMarketMatches.length; i++) {
        const config = androidAppMarketMatches[i]
        if (ua.match(config.match)) {
          tryOpenMarketLink(config.deeplink, config.url)
          return
        }
      }
      // fallback
      tryOpenMarketLink(YINNGYONGBAO_APP_MARKET_DEEPLINK, YINGYONGBAO_APP_MARKET_DOWNLOAD_LINK)
    }
  } catch (e) {
    console.error(e)
  }
}

const OPEN_APP_INVOKED_FLAG = 'open_app_invoked_flag'

function launchProductDetailInApp(productId?: string) {
  try {
    if (!window || !productId) {
      return
    }
    const ua = navigator?.userAgent || ''
    if (ua.match(/MicroMessenger/i)) {
      // for wechat, we should disable launch app deeplink
      return
    }
    if (ua.match(/bot/i) || ua.match(/spider/i)) {
      // for spider, we should disable launch app deeplink
      return
    }
    if (!bxlUtils.platform.isAndroid() || !bxlUtils.platform.isIOS()) {
      // only try to trigger on Mobile device
      return
    }
    // only trigger one time for one session
    const openAppInvokedFlag = SessionStorage.get(OPEN_APP_INVOKED_FLAG)
    if (openAppInvokedFlag === '1') {
      return
    }
    SessionStorage.set(OPEN_APP_INVOKED_FLAG, '1')
    const utmParams = analytics?.utmParams()
    tryOpenBieyangApp(
      `bieyang://pdp?productId=${productId}&utm_source=${utmParams?.utm_source || 'baidu_bmc'}&utm_term=${utmParams?.utm_term || productId}&type=global`
    )
  } catch(e) {
    console.error(e)
  }
}

// 尝试打开别样 app deeplink
function tryOpenBieyangApp(deeplink: string, fallback?: () => void) {
  if (!window) {
    return
  }
  
  try {
    window.location.href = deeplink
    const timer = setTimeout(() => {
      fallback && fallback()
    }, 1000)
    document.addEventListener('webkitvisibilitychange', () => {
      if (document.visibilityState == "hidden") {
        timer && clearTimeout(timer)
      }
    })
    window.addEventListener('pagehide', () => {
      timer && clearTimeout(timer)
    })
  } catch (e) {
    fallback && fallback()
    console.warn(e)
  }
}

// 尝试打开应用商店链接，先通过不可见的 iframe 调起 deeplink，如果无效尝试浏览器直接跳转页面
function tryOpenMarketLink(deeplink: string, url: string) {
  if (!window) {
    return
  }
  try {
    let ifr = document.createElement('iframe')
    ifr.src = deeplink
    ifr.style.display = 'none'
    document.body.appendChild(ifr)
    const timer = setTimeout(() => {
      document.body.removeChild(ifr)
      window.location.href = url
    }, 1200)
    document.addEventListener('webkitvisibilitychange', () => {
      if (document.visibilityState == "hidden") {
        timer && clearTimeout(timer)
      }
    })
    window.addEventListener('pagehide', () => {
      timer && clearTimeout(timer)
    })
  } catch (e) {
    console.warn(e)
  }
}

export {
  openAppStore,
  tryOpenBieyangApp,
  tryOpenMarketLink,
  launchProductDetailInApp,
}