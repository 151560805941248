import React, { createContext, useState, useContext, memo, useCallback, useEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import bxlUtils from 'bxl-utils'
import { isBrowser } from '@/lib/utils'
const initialValue = {
  anchorList: [],
  activeIndex: -1,
  addAnchor: () => {},
  removeAnchor: () => {},
  scrollIntoView: () => {},
  headerEl: null
}

const AnchorContext = createContext(initialValue)

const AnchorItem = PropTypes.shape({
  elRefObj: PropTypes.shape({
    current: PropTypes.object
  }),
  title: PropTypes.string
})

AnchorContext.Provider.propTypes = {
  anchorList: PropTypes.arrayOf(AnchorItem),
  addAnchor: PropTypes.func,
  activeIndex: PropTypes.number,
  removeAnchor: PropTypes.func,
  scrollIntoView: PropTypes.func,
}

export const getOffsetPosition = (element) => {
  if (!element?.getClientRects()?.length) {
    return 0;
  }
  const rect = element.getBoundingClientRect();
  if (rect.width || rect.height) {
    const container = element.ownerDocument.documentElement;
    return {
      top: rect.top - container.clientTop,
      bottom: rect.bottom - container.clientTop
    }
  }
  return {
    top: rect.top,
    bottom: rect.bottom
  }
}

export const AnchorProvider = memo(({ children }) => {
  const [ anchorList, setAnchorList ] = useState([])
  const [ activeIndex, setActiveIndex ] = useState(-1)
  const headerEl = useRef()
  const getCurrentAnchorIndex = useCallback(() => {
    for(let index = 0; index < anchorList.length; index++) {
      if(anchorList[index]?.elRefObj.current) {
        const { bottom } = getOffsetPosition(anchorList[index].elRefObj.current)
          if(bottom - (headerEl?.current?.offsetHeight || 0) - 2 >= 0 ) {
            return index
        }
      }
    }
    return -1
  }, [anchorList])
  const onScrollObserver = useMemo(() => {
    if (!isBrowser) return null
    return bxlUtils.throttle(500, () => {
      // console.log('active observe', getCurrentAnchorIndex())
      setActiveIndex(getCurrentAnchorIndex)
    })
  }, [getCurrentAnchorIndex])

  const scrollIntoView = useCallback((anchorItem) => {
    anchorItem.current && anchorItem.current?.scrollIntoView()
    headerEl?.current && window.scrollBy(0, -headerEl.current.offsetHeight)
    setActiveIndex(getCurrentAnchorIndex)
  }, [getCurrentAnchorIndex])
  
  const addAnchor = useCallback((index, anchorItem) => {
    setAnchorList(pre => {
      const list = [...pre]
      list[index] = anchorItem
      return list
    })
  }, [])

  const removeAnchor = useCallback((key) => {
    setAnchorList(pre => pre.filter((anchorItem, index) => index !== key))
  }, [])

  useEffect(() => {
    setActiveIndex(getCurrentAnchorIndex)
  }, [getCurrentAnchorIndex])

  useEffect(() => {
    if (!isBrowser) return
    window.addEventListener('scroll', onScrollObserver)
    return () => {
      window.removeEventListener('scroll', onScrollObserver)
    }
  }, [onScrollObserver])
  
  return (
    <AnchorContext.Provider value={{
      anchorList,
      addAnchor,
      removeAnchor,
      activeIndex,
      scrollIntoView,
      headerEl
    }} >
      { children }
    </AnchorContext.Provider>
  )
})

AnchorProvider.displayName = 'AnchorProvider'

AnchorProvider.propTypes = {
  children: PropTypes.node
}


export const WithAnchorProvider = (Component) => (props) => {
  return (
    <AnchorProvider>
      <Component {...props} />
    </AnchorProvider>
  )
}

export const useAnchor = () => useContext(AnchorContext)
