import React, { useMemo, useState, useEffect, useRef } from 'react';
import * as styles from './productInfo.module.scss';
import './ads.scss';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import qrcode from '@images/qrcode-download-app.png';
import infoSvg from '@images/info.svg';
import WechatMiniProgramDialog from './Download';
import Download from '@modules/Home/Download';
import { isBrowser } from '@lib/utils';

const getAvailableSizes = memoizeOne((activeColor, activeWidth, colorSku, sizesSku, widthSku) => {
  if(activeColor || activeWidth) {
    const skuBycolor = colorSku[activeColor] || []
    const skuByWidth = widthSku[activeWidth] || []
    let size = []
    if(!activeColor) {
      size = skuByWidth
    }else if(!activeWidth) {
      size = skuBycolor
    } else {
      size =  _.intersectionBy(skuBycolor, skuByWidth, item => item.size?.name)
    }
    return size.reduce((init, item) => {
      const key = item.size?.name
      if(key) {
        init[key] = sizesSku[key] || []
      }
      return init
    }, {})
  }
})

const getAvailableColors = memoizeOne((activeSize, activeWidth, colorSku, sizesSku, widthSku) => {
  if(activeSize || activeWidth) {
    const skuBySize = sizesSku[activeSize] || []
    const skuByWidth = widthSku[activeWidth] || []
    let color = []
    if(!activeSize) {
      color = skuByWidth
    }else if(!activeWidth) {
      color = skuBySize
    } else {
      color =  _.intersectionBy(skuBySize, skuByWidth, item => item.color?.name)
    }
     return color.reduce((init, item) => {
      const key = item.color?.name
      if(key) {
        init[key] = colorSku[key] || []
      }
      return init
    }, {})
  }
})

const getAvailableWidth =  memoizeOne((activeColor, activeSize, colorSku, sizesSku, widthSku) => {
  if(activeSize || activeColor) {
    const skuBySize = sizesSku[activeSize] || []
    const skuBycolor = colorSku[activeColor] || []
    let width = []
    if(!activeSize) {
      width = skuBycolor
    }else if(!activeColor) {
      width = skuBySize
    } else {
      width =  _.intersectionBy(skuBySize, skuBycolor, item => item.attributes?.attributes?.width?.choices?.[0])
    }
    return width.reduce((init, item) => {
      const key = item.attributes?.attributes?.width?.choices?.[0]
      if(key) {
        init[key] = widthSku[key] || []
      }
      return init
    }, {}) 
  }
})

const ProductInfo = ({ info, translation, merchantName }) => {
  const [expand, setExpand] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const {
    id,
    brand,
    displayBrand,
    nameCN,
    name,
    gender,
    labelView,
    badges,
    colors = [],
    sizes = [],
    availableSkus = [],
    attributes,
    description,
    featureBar,
  } = info || {}
  const _description = useMemo(() => {
    if (translation) return translation
    return description
  }, [translation, description])
  const widthList = useMemo(() => {
    return attributes?.attributes?.width?.choices || []
  }, [attributes])

  const [
    {
      activeColor, 
      activeSize,
      activeWidth
    }, setActiveSku 
  ] = useState({
    activeColor: '',
    activeSize: '',
    activeWidth: ''
  })

  const [price, setPrice] = useState(() => {
    const { priceTag, priceTagCN, originalPriceTag } = info?.promotions || {}
    return {
      priceTagCN: priceTagCN || '',
      priceTag: priceTag || '',
      originalPriceTag: originalPriceTag || '',
    }
  })

  const { colorSku, sizesSku, widthSku } = useMemo(() => {
    return availableSkus?.reduce((initival, item) => {
      const color = item.color?.name
      const size = item.size?.name
      const width = item.attributes?.attributes?.width?.choices?.[0]
      if(color) {
        if(!Array.isArray(initival.colorSku[color])) {
          initival.colorSku[color] = []
        }
        initival.colorSku[color].push(item)
      }
      if(size) {
        if(!Array.isArray(initival.sizesSku[size])) {
          initival.sizesSku[size] = []
        }
        initival.sizesSku[size].push(item)
      }
      if(width) {
        if(!Array.isArray(initival.widthSku[width])) {
          initival.widthSku[width] = []
        }
        initival.widthSku[width].push(item)
      }
      return initival
    }, {colorSku: {}, sizesSku: {}, widthSku: {}}) || {colorSku: {}, sizesSku: {}, widthSku: {}}
  }, [availableSkus])

  const { availableColors, availableSizes, availableWidth } = useMemo(() => {
    const availableSizes = getAvailableSizes(activeColor, activeWidth, colorSku, sizesSku, widthSku)
    const availableColors = getAvailableColors(activeSize, activeWidth, colorSku, sizesSku, widthSku)
    const availableWidth = getAvailableWidth(activeColor, activeSize, colorSku, sizesSku, widthSku)
    return {
      availableColors: availableColors || colorSku,
      availableSizes: availableSizes || sizesSku,
      availableWidth: availableWidth || widthSku
    }
  }, [colorSku, sizesSku, activeColor, activeSize, activeWidth, widthSku])

  const getProductTitle = (language = 'en') => {
    const nameSection = language === 'en' ? (name || '') : (nameCN || name || '')
    const brandSection = displayBrand || brand || ''
    const showBrandSection = (brandSection && nameSection) ? nameSection.indexOf(brandSection) === -1 : false
    return `${gender ? `${gender} ` : ''}${showBrandSection ? `${brandSection} | ` : ''}${nameSection}`
  }

  const labelViews = useMemo(() => {
    const shortLabels = (labelView && labelView.shortLabels) || []
    return shortLabels.reduce((init, item ) => {
      return [...init, ...(item.labels || []).filter((label) => label.text)]
    }, [])
  }, [labelView])

  const onSelectColor = (color) => {
    setActiveSku(pre => ({
      ...pre,
      activeColor: pre.activeColor === color ? '' : color
    }))
  }

  const onSelectSize = (size) => {
    setActiveSku(pre => ({
      ...pre,
      activeSize: pre.activeSize === size ? '' : size
    }))
  }

  const onSelectWidth = (width) => {
    setActiveSku(pre => ({
      ...pre,
      activeWidth: pre.activeWidth === width ? '' : width
    }))
  }

  function renderLowestPriceLabel(badges) {
    if (!badges?.length) return null
    const lowestPriceIndex = badges.findIndex(b => b.productLable === 'LOW_PRICE')
    if (lowestPriceIndex !== -1) {
        const badge = badges[lowestPriceIndex]
        return (
            <span className={styles.lowestPriceLabel}>{badge.text}</span>
        )
    }
    return null
  }

  const labelViewsEl = useRef()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if(labelViews.length) {
      const children = labelViewsEl.current.children
      setVisible(!!Math.floor(children[children.length - 1].offsetTop))
    }
  }, [labelViews])

  useEffect(() => {
    try {
      if (isBrowser) {
        window.adsbygoogle = window.adsbygoogle || []
        window.adsbygoogle.push({})
      }
    } catch (e) {}
  }, [])

  useEffect(() => {
    const { priceTag, priceTagCN, originalPriceTag,  skus = []  } = info?.promotions || {}
    if(!activeColor && colors.length) {
      setPrice({
        priceTag,
        priceTagCN,
        originalPriceTag
      })
      return 
    }
    if(!activeSize && sizes?.length) {
      setPrice({
        priceTag,
        priceTagCN,
        originalPriceTag
      })
      return
    }
    if(!activeWidth && widthList.length) {
      setPrice({
        priceTag,
        priceTagCN,
        originalPriceTag
      })
      return
    }
    if(!availableSkus?.length) {
      return
    }
    const isAccordWithSku = (sku) => {
      if(activeColor) {
        if(sku?.color?.name !== activeColor) { return false }
      }
      if(activeSize) {
        if(sku?.size?.name !== activeSize) { return false }
      }
      if(activeWidth) {
        if(sku.attributes?.attributes?.width?.choices?.[0] !== activeWidth) { return false }
      }
      return true
    }
    const sku = availableSkus?.find(sku => isAccordWithSku(sku))
    if(sku && skus) {
      const target = skus.find(item => item.id === sku.id || item.id === '_all') || {}
      if (target) {
        setPrice({
          priceTag: target.priceTag,
          priceTagCN: target.priceTagCN,
          originalPriceTag: target.originalPriceTag
        })
      }
    }
  }, [
    activeColor, activeSize, activeWidth, colors, sizes, availableSkus, widthList, info?.promotions ])

  const features = featureBar?.features || [];
  const logisticsProcess = featureBar?.logisticsProcess?.process || []

  const onBuyNow = () => {
    setOpenDialog(true)
  }

  return (
    <ul className={`${styles.infoWrapper} info-wrapper`}>
      <h1 className={styles.title}>
        {getProductTitle('zh')}
      </h1>
      <h2 className={styles.subtitle}>
        {getProductTitle('en')}
      </h2>
      <li className={styles.price}>
        <span className={styles.label}>价格</span>
        <span className={styles.priceTagCn}><strong>{ price.priceTagCN }</strong></span>
        <span className={styles.priceTag}>{ price.priceTag }</span>
        <span className={styles.originalPriceTag}>{ price.originalPriceTag }</span>
        {renderLowestPriceLabel(badges)}
      </li>
      <li>
        <span className={styles.label}>促销</span>
        <div  className={`${styles.labelView} ${visible ? styles.visible : ''}`}>
          <div className={styles.labelItemGroup} ref={labelViewsEl}>
            {
              labelViews.length ? labelViews?.map(({text, color, backgroundColor}, index) => (
                <div
                  key={index}
                  className={styles.labelItem}
                  style={{
                    color : `#${color}`,
                    backgroundColor: `#${backgroundColor}`
                  }}>
                  { text }
                </div>
              )) : null
            }
            <div
              key={'open_app_tag'}
              className={`${styles.labelItem} ${styles.labelItemOpenApp}`}>
              <span>扫码打开APP</span>
              <Download qrcodeImg={qrcode} amount={'688元'} className={styles.labelItemOpenAppPopup} />
            </div>
          </div>
        </div>
      </li>
      {
        colors?.length ? (
          <li className={`${styles.colors} ${styles.property}`}>
            <span className={styles.label}>颜色</span>
            <div className={styles.colorsMain}>
              {
                colors?.map((item, index) => (
                  <div
                    className={`
                      ${availableColors[item.name] ? '' : styles.unavailable}
                      ${activeColor === item.name ? styles.active : ''}
                    `}
                    key={index}
                    onClick={() => availableColors[item.name] && onSelectColor(item.name)}
                  >
                    <img src={item.image.full.url} alt="" />
                  </div>
                ))
              }
            </div>
          </li>
        ): null
      }
      {
        widthList?.length ? (
          <li className={`${styles.sizes} ${styles.property}`}>
            <span className={styles.label}>宽度</span>
            <div className={styles.sizesMain}>
              {
                widthList?.map((item, index) => (
                  <div
                    className={`
                      ${availableWidth[item] ? '' : styles.unavailable}
                      ${activeWidth === item ? styles.active : ''}
                    `}
                    key={index}
                    onClick={() => availableWidth[item] && onSelectWidth(item)}
                  >
                    { item }
                  </div>
                ))
              }
            </div>
          </li>
        ): null
      }
      {
        sizes?.length ? (
          <li className={`${styles.sizes} ${styles.property}`}>
            <span className={styles.label}>尺码</span>
            <div className={styles.sizesMain}>
              {
                sizes?.map((item, index) => (
                  <div 
                    className={`
                      ${availableSizes[item.name] ? '' : styles.unavailable}
                      ${activeSize === item.name ? styles.active : ''}
                    `}
                    key={index}
                    onClick={() => availableSizes[item.name] && onSelectSize(item.name)}
                  >
                    {item.label || item.name}
                  </div>
                ))
              }
            </div>
          </li>
        ): null
      }
      {
        logisticsProcess?.length ? (
          <li className={styles.service}>
            <span className={styles.label}>服务</span>
            <div className={styles.processGroup}>
              <div className={styles.processTitles}>
                {featureBar.titles?.map(t => (
                  <span className={styles.processTitle} key={t}>{t}</span>
                ))}
              </div>
              <ul className={styles.processList}>
                {
                  logisticsProcess.map((process, index) => (
                    <li className={styles.processItem} key={index}>
                      <img className={styles.processImg} src={process.image}></img>
                      <span className={styles.processText}>{process.text}</span>
                    </li>
                  ))
                }
              </ul>
            </div>
          </li>
        ) : null
      }
      {
        features.length ? (
          <li className={styles.service}>
            <span className={styles.label}>
              说明
              <img className={styles.infoIcon} src={infoSvg} />
              <div className={styles.moreService}>
                <ul>
                  {merchantName ? <li><span>此商品由 <strong>{merchantName}</strong> 发货并提供售后服务</span></li> : null}
                  <li><span>此商品不提供国内购物发票</span></li>
                  <li><span>根据中国海关总署要求，您所购买的商品清关入境需要提供身份证信息进行入境申报，请您配合。我们不会向第三方泄露您的资料，请您放心</span></li>
                </ul>
              </div>
            </span>
            <div className={styles.serviceGroup}>
              {
                features.map(item => <div key={item.name}>{ item.name }</div>)
              }
            </div>
          </li>
        ) : null
      }
      {
        _description?.length ? (
          <li className={styles.service}>
            <span className={styles.label}>描述</span>
            <article className={styles.description}>
              <p className={expand ? styles.expanded : styles.expandable}>
                <label style={{ display: _description?.length < 11 ? 'none' : 'block'  }} className={styles.expandLabel} onClick={() => setExpand(!expand)}>{!expand ? '展开' : '收起'}</label>
                {
                  _description.map((d, i) => <React.Fragment key={i}>{d}<br></br></React.Fragment>)
                }
              </p>
            </article>
          </li>
        ) : null
      }
      <li>
        <button className={styles.buyNow} onClick={onBuyNow}>立即购买</button>
      </li>
      <li className={styles.qrWrap}>
        <img src={qrcode} alt="buynow qrcode icon" />
        <div>
          <div>扫码下单，</div>
          <div>
            领<span>¥688</span>大礼包～
          </div>
        </div>
      </li>
      <li className={styles.autoAdsIns} >
        <ins
          className="adsbygoogle infeed"
          data-ad-client="ca-pub-9185778655077786"
          data-ad-slot="8722090405"
          data-ad-format="auto"
          data-full-width-responsive="true">
        </ins>
      </li>
      {openDialog ? <WechatMiniProgramDialog id={id} onClose={() => setOpenDialog(false)} /> : null}
    </ul>
  )
}

ProductInfo.displayName = "ProductInfo"

export default ProductInfo