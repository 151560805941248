import React from 'react';
import ProductItem from '@/modules/Product/ProductItem';
import './similar-product.scss';

function SimilarProduct(props: any) {
    const { similar } = props || {}

    if (!similar?.length) {
        return null
    }
    return (
        <div className='m-similar-product'>
            <div className='title'>相似商品</div>
            <div className='content'>
                {similar?.map((item: any, index: number) => {
                    const product = {
                        ...item.product,
                        promotions: {
                            off: item?.off || '',
                            activityOffs: item?.activityOffs || []
                        },
                        merchantInfo: {
                            merchantName: item?.merchantName
                        },
                        origin: item?.origin
                    }
                    return (
                        <ProductItem labelView={item.labelView} product={product || {}} key={`${item.product.id}_${index}`} />
                    )
                })}
            </div>
        </div>
    )
}

export default SimilarProduct
