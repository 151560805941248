import React, { useState, useContext, useEffect } from 'react';
import { ProductContext } from '@components/Context';
import CommentItem from '@modules/Comment/CommentItem';
// import Toast from '@components/Toast';
import './product-comment.scss';
import { openAppStore } from '@lib/appstore';

function ProductComment() {
    const productContext: any = useContext(ProductContext);
    const { comment } = productContext || {};

    function goToCommentList() {
        // Toast('请前往APP内查看更多精彩内容');
        openAppStore()
    }

    if(!comment?.comments?.length) {
        return null;
    }

    return (
        <div className="m-comment">
            <div className="title" onClick={goToCommentList}>
                商品评价({comment?.total})
            </div>
            <div className="comment-container">
                {comment?.tagInfos?.length ? (
                    <div className="tag-wrap">
                        {comment.tagInfos.map((item: any) => {
                            return (
                                <div className="comment-tag" key={item?.tagName} onClick={goToCommentList}>
                                    { item?.tagName}({ item?.num})
                                </div>
                            )
                        })}
                    </div >
                ) : null}
                <div className="comment-wrap">
                    {comment.comments.map((item: any, index: number) => {
                        return (
                            <CommentItem key={`comment_${index}`} comment={item} className={index > 2 ? 'hiden-item' : ''} />
                        )
                    })}
                </div>
                <div className="comment-more" onClick={goToCommentList}>
                    查看更多
                    <img className="comment-more-icon" src={require('@images/arrow-right.png').default} />
                </div>
            </div >
        </div >
    )
}

export default ProductComment;