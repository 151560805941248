import React, { useContext, useState } from 'react';
import * as styles from './CommentItemPc.module.scss';
import { openAppStore } from '@lib/appstore';
import { getImageUrl } from '@/lib/utils';
const starGrey = require('@images/star-grey.png').default;
const starYellow = require('@images/star-yellow.png').default;
const defaultAvatar = require('@images/ic_default_avatar.png').default;
const likeBlack = require('@images/like-black.png').default;
const likeGrey = require('@images/like-grey.png').default;
const reply = require('@images/reply.png').default;
const time = require('@images/time.png').default;
const arrowDown = require('@images/ic_arrow_down.png').default;

const starLabel = [
    '很糟糕',
    '不推荐',
    '还不错',
    '比较推荐',
    '强烈推荐'
]

function CommentItem(props: any) {
    const { comment } = props;
    const [current, setCurrent] = useState(comment);
    const { productCommentLabel, userLabel, content, pictures, sku, postedAt, descendantsN, liked, likes, descendants, userAvatar } = current || {};
    const { productSatisfyScore } = productCommentLabel || {};

    function handleLikeClick() {
        openAppStore()
    }

    function goToCommentList() {
        // Toast('请前往APP内查看更多精彩内容');
        openAppStore()
    }

    function handleImgClick(index: number) {  
        openAppStore()
    }

    return (
        <div className={styles.commentItem}>
        <div className={styles.userWrap}>
            <img className={styles.userAvatar} src={userAvatar || defaultAvatar} alt={`用户${userLabel}头像`} />
            <div className={styles.userName}>{userLabel}</div>
            {productCommentLabel && <div className={styles.starWrap}>
                {[1,2,3,4,5].map((item: any, index: number) => {
                    return (
                        <img
                            key={`star_${index}`}
                            alt={`start_${index}`}
                            onClick={goToCommentList}
                            src={productSatisfyScore > index ? starYellow : starGrey }
                        />
                    )
                })}
            </div>}
            {productCommentLabel && <div className={styles.starLabel}>{starLabel[productSatisfyScore - 1]}</div>}
        </div>
        <div className={styles.commentItemContainer}>
            {content && <div className={styles.commentContent}>{content}</div>}
            <div className={styles.attributes}>{getAttributes(sku)}</div>
            {pictures && pictures.length &&
                <div className={styles.commentImageWrap}>
                    {pictures.map(((img: any, index: number) => {
                        return (
                            <img
                                key={`product_image_${index}`}
                                className={`${styles.commentImage} ${(index + 1)%3 === 0 ? styles.third : ''}`}
                                src={getImageUrl(img?.full?.url)}
                                alt={`用户${userLabel}对${sku?.name || ''}商品评价, ${content}`}
                                onClick={() => handleImgClick(index)}
                            />
                        )
                    }))}
                </div>}
            <div className={styles.commentFooter}>
                <div className={`${styles.timeIconWrap} ${styles.footerItem}`}>
                    <img src={time} alt='time-icon' />
                    <div>{getCommentDate(postedAt)}</div>
                </div>
                <div className={`${styles.replyIconWrap} ${styles.footerItem}`}>
                    <img src={reply} alt='reply-icon' />
                    <div>{descendantsN || 0}</div>
                </div>
                <div
                    className={`${styles.likeIconWrap} ${styles.footerItem}`}
                    onClick={handleLikeClick}
                >
                    <img alt='like-icon' src={liked ? likeBlack : likeGrey} />
                    <div>{likes}</div>
                </div>
            </div >
        </div >
        {descendants && descendants?.length > 0 &&
            <div className={styles.replyWrap}>
                {descendants.map((item: any, index: any) => {
                    return (
                        <div className={styles.replyItem} key={`descendants_${index}`}>
                            <img className={styles.replyUserAvatar} src={item.userAvatar || defaultAvatar} alt={`reply user avatar ${item.userLabel}, ${item.content}`} />
                            <div className={styles.replyDetail}>
                                <div className={styles.replyHeader}>
                                    <div className={styles.replyUserName}>{item.userLabel}</div>
                                    <div className={styles.replyDate}>{getCommentDate(item.postedAt)}</div>
                                </div>
                                <div className={styles.replyContent}>
                                    {item.parentUserLabel &&
                                        <div className={styles.replyPrefix}>
                                            <div>回复</div>
                                            <div className={styles.replyParentName}>{item.parentUserLabel}：</div>
                                        </div>
                                    }
                                    {item.content}
                                </div>
                            </div>
                        </div>
                    )
                })}
                <div className={styles.moreReply} onClick={goToCommentList}>
                  更多回复({descendantsN}条)
                  <img className={styles.arrowDown} src={arrowDown} alt='arrow-more-reply' />
                </div>
            </div>
        }
    </div >)
}

function getAttributes(sku: any) {
    var attstr = '';
    var color = (sku.color && sku.color.name) ? '颜色：' + sku.color.name + ' ' : '';
    var size = (sku.size && sku.size.name) ? '尺码：' + sku.size.name + ' ' : '';
    var width = (sku.attributes && sku.attributes.attributes && sku.attributes.attributes.width) ? '宽度：' + sku.attributes.attributes.width.choices[0] + ' ' : '';
    return color + size + width;
}

function getCommentDate(timeStamp: number) {
    var date = new Date(timeStamp);
    var m = ('0' + (date.getMonth() + 1)).slice(-2);
    var d = ('0' + date.getDate()).slice(-2);
    var h = ('0' + date.getHours()).slice(-2);
    var min = ('0' + date.getMinutes()).slice(-2);
    
    return m + '-' + d + ' ' + h + ':' + min
}

export default CommentItem;