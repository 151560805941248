import React, { useContext, useEffect, useState } from 'react';
import { ProductContext, CartContext } from '@components/Context';
import './product-bottom-bar.scss';
// import { getCartInfo } from '@service/api';
import HomeIcon from '@images/ic_home.png';
import CustomerIcon from '@images/ic_customer.png';
// import { isLogin } from '@lib/utils';
// import { openAppStore } from '@lib/appstore';

const carIcon = require('@images/cart.svg').default;

function ProductBottomBar(props: any) {
  const productContext: any = useContext(ProductContext);
  const cartContext: any = useContext(CartContext);
  const { cartNum } = cartContext;
  const button = productContext?.product?.buyButtons?.[0];

  function goDownload() {
    // openAppStore()
    // todo to merchant official url
    
  }

  useEffect(() => {
    // getCartInfo().then((res: any) => {
    //   cartContext.updateCartNum(res?.itemsQuantity || 0);
    // });
  }, []);

  return (
    <div className='m-product-buttom-bar'>
        <div className='cart' onClick={goDownload}>
          <div className='img-wrap'>
            <img src={HomeIcon} />
          </div>
        </div>
      {props.isMini || (
        <div className='cart' onClick={goDownload}>
          <div className='img-wrap'>
            <img src={CustomerIcon} />
          </div>
        </div>
      )}
      <div className='cart' onClick={goDownload}>
        <div className='img-wrap'>
          <img src={carIcon} />
          {cartNum ? (
            <div className='badge'>{cartNum > 99 ? '99+' : cartNum}</div>
          ) : null}
        </div>
      </div>
      <div className='button-wrap'>
        {/* {!button?.disabled &&
          <div
            className='add_to_shoppingcart add-black'
            onClick={goDownload}
          >
            立即购买
          </div>
        } */}
        <div
          className={`add_to_shoppingcart ${button?.disabled ? 'add-disabled' : ''
            }`}
          onClick={goDownload}
        >
          {'立即购买'}
        </div>
      </div>
    </div>
  );
}

export default ProductBottomBar;
