import React, { useState } from 'react';
import './main.scss';
import homeBannerBottom from '@images/home-banner-bottom.png';
import homeBannerTop from '@images/home-banner-top.png';
import logo from '@images/logo.png';
import quality1 from '@images/quality1.png';
import quality2 from '@images/quality2.png';
import quality3 from '@images/quality3.png';
import quality4 from '@images/quality4.png';
import ios from '@images/ios.png';
import android from '@images/android.png';
import Download from '@components/DownloadPopup';
import iosQrcode from '@images/iosQrcode.jpeg';
import androidQrcode from '@images/androidQrcode.jpeg';
import arraw1 from '@/images/arraw1.png';
import { useMediaQuery } from "react-responsive";

const AD = [{
    icon: quality1,
    text: '一站式服务，无需操心转运与支付'
}, {
    icon: quality2,
    text: '同步官网折扣，尽享海外低价'
}, {
    icon: quality3,
    text: '安全快捷发货，正品直达家门 '
}, {
    icon: quality4,
    text: '地道中文客服，购物更有保障'
}]

function Main(props: any) {
    const { amount } = props;
    const isMobile = useMediaQuery({ query: "(max-width: 960px)" })
    const [current, setCurrent] = useState('IOS')

    return (
        <section className='m-main'>
            <div className='main-image-wrap'>
                <img src={homeBannerBottom} className='bottom-img' alt="banner-background-img" />
                <img src={homeBannerTop} className='top-img' alt="banner-foreground-img" />
            </div>
            <div className='right-wrap'>
                <div className='info-wrap'>
                    <div className='info-wrap-slogan'>
                        <div className='logo-wrap'>
                            <img className='logo' src={logo} alt="别样海外购logo" />
                            <div className='text-wrap'>
                                <div>别样App</div>
                                <div>海淘专家一站式服务</div>
                            </div>
                        </div>
                        <div className='ad-wrap'>
                            {AD.map((item, index) => {
                                return (
                                    <div className='ad-item' key={index}>
                                        <img className='ad-icon' src={item.icon} alt={item.text} />
                                        <div className='ad-text'>{item.text}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='button-wrap'>
                        <div className={current === 'IOS' ? 'button iOS-button button-current' : 'button iOS-button'} onMouseMove={() => setCurrent("IOS")}>
                            <img className='icon' src={ios} alt="ios下载按钮" />
                            <div>iOS下载</div>
                            <Download qrcodeImg={iosQrcode} amount={amount} />
                        </div>
                        <div className={current === 'Android' ? 'button android-button button-current' : 'button android-button'} onMouseMove={() => setCurrent("Android")}>
                            <img className='icon' src={android} alt="android下载按钮" />
                            <div>Android下载</div>
                            <Download qrcodeImg={androidQrcode} amount={amount} />
                            <div className='decoration1'></div>
                        </div>
                    </div>
                    <div className='decoration2'></div>
                    <a href="https://sensorsdata.bybieyang.com/r/Gz" target='_blank' rel='nofollow' className='m-button-wrap'>
                        <img src={arraw1} alt="arrow icon" />
                        <div>下载别样海外购</div>
                    </a>
                </div>
            </div>
        </section>
    )
}

export default Main;