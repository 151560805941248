import React from 'react';
import Portal from '@components/Portal';
import './loading.scss';

interface LoadingInterface {
    text: string
}
function Loading(props: LoadingInterface) {
    return (
        <div className='c-loading'>
            <div className='mask'></div>
            <div className='loading-content'>
                <div className='image-wrap'>
                    <div className='image-box'>
                        <img src={require('@images/loading_product_icon_bag.png').default} />
                        <img src={require('@images/loading_product_icon_cos.png').default} />
                        <img src={require('@images/loading_product_icon_cloth.png').default} />
                        <img src={require('@images/loading_product_icon_shoe.png').default} />
                    </div>
                </div>
                <div className='text'>{props?.text}</div>
            </div>
        </div>
    )
}

Loading.defaultProps = {
    text: '请稍后',
    id: 'C-Loading'
}

export default Portal(Loading);