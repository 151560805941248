import * as React from 'react'
import { categoryDiscover } from '@/service/api'
import { useState, useRef, useEffect } from "react"
import * as cms from './category-m.module.scss'
import Loading from '@/layouts/Loading'
import { useMediaQuery } from 'react-responsive'
import arrowGray from '@images/ic_arrow_down.png'
import BgModul from '@modules/Category/BgModul/index'
import DefaultSort from '@modules/Category/DefaultSort/index'
import PriceSort from '@modules/Category/PriceSort'
import DiscountSort from '@modules/Category/DiscoutSort'
import CategorySort from '@modules/Category/CategorySort'
import { SORTTYPE } from '@modules/Category/DefaultSort'
import CategorySeoComponent from './CategorySeoComponent'
import { isBrowser, getImageUrl } from '@/lib/utils'

const size = 60;
interface iSearchQ {
  s?: string
  discountRange?: any
  discountRange1?: string
  discountRange2?: string
  priceRange?: any
  priceRange1?: string
  priceRange2?: string
  selectBrands?: string[]
}

const defaultFilters = [
  { displayText: '类别筛选', text: '类别筛选', component: CategorySort },
  { displayText: '排序方式', text: '默认排序', component: DefaultSort },
  { displayText: '价格筛选', text: '价格筛选', component: PriceSort },
  { displayText: '折扣筛选', text: '折扣筛选', component: DiscountSort },
]

function ProductItem(props: any) {
  const { item, index } = props || {}
  const { origin, merchantName, labelView } = item?.searchProduct || {}
  return (
    <a
      key={`p_${index}_mobile`} 
      className={cms.categoryProduct} 
      href={`/pd/${item?.searchProduct?.product?.id}/`}
      title={item?.searchProduct?.product?.nameCN || item.searchProduct?.product?.name || ''}
    >
      <div className={cms.categoryProductImg}>
        <img
          src={getImageUrl(item?.searchProduct?.product?.images?.[0]?.thumbnail?.url || item?.searchProduct?.product?.images?.[0]?.full?.url)}
          alt={item?.searchProduct?.product?.nameCN || item.searchProduct?.product?.name || ''}
        />
      </div>
      <h2 className={cms.categoryProductName}>
        {item?.searchProduct?.product?.brandCN || item?.searchProduct?.product?.brand} | {item?.searchProduct?.product?.nameCN || item.searchProduct?.product?.name}
      </h2>
      <div className={cms.categoryProductOrigin}>
        {origin || ''}{merchantName || ''}
      </div>
      <div className={cms.categoryProductPrice}>
        <h3 className={cms.categoryProductPriceCn}>{item.searchProduct.product.priceTagCN}</h3>
        <span className={cms.categoryProductPriceEn}>{item.searchProduct.product.priceTag}</span>
      </div>
      <div className={cms.categoryProductTag}>
        {labelView?.shortLabels?.length ? (
            labelView?.shortLabels.map((shortLabel: any) => {
                return shortLabel?.labels?.length ? shortLabel?.labels.map((label: any, index: number) => {
                    return (
                      <div key={`${label.text}_${index}`} className={cms.categoryProductTagItem}>
                        <span>{label.text}</span>
                        <span className={cms.categoryProductTagItemLine}></span>
                      </div>
                    )
                }) : null
            })
          ) : null}
      </div>
    </a>
  )
}

const CategoryM = (props: any) => {
  const { cid, product, mappingTerm, displayTerm, term } = props || {}
  const { productList: _productList, total: _total } = product || {}
  const [products, setProducts] = useState<null | Array<any>>(() => {
    return _productList
  })
  const [total, setTotal] = useState<null | number>(() => {
    return _total
  })
  const [from, setFrom] = useState(0)
  const [showLoading, setShowLoading] = useState(false)
  const categoryListRef = useRef(null)
  const [searchQ, setQ] = useState<iSearchQ>({})
  const [sort, setSort] = useState('')
  const firstRender = useRef(true)
  const [activeIndex, steActiveIndex] = useState<any>('')
  const [isFixed, setFixed] = useState(false)
  const [FILTER, setFilter] = useState(defaultFilters)
  const isPC = useMediaQuery({ query: "(min-width: 960px)" })

  const search = () => {
    closeMolul()
    const disableLoading = firstRender.current && from === 0 && _productList?.length
    setShowLoading(!disableLoading)
    const { discountRange1, discountRange2, priceRange1, priceRange2 } = searchQ || {}
    let q = `f=${from * size}&t=${(from + 1) * size}&cids=${cid}`
    if (sort) q += `&s=${sort}`
    let _discountRange1 = Number(discountRange1) || 0;
    let _discountRange2 = Number(discountRange2) || 100;
    q += `&dr=${100 - _discountRange2}-${100 - _discountRange1}`
    if (priceRange1 && priceRange2) {
      q += `&prs=${Number(priceRange1) * 100}-${Number(priceRange2) * 100}`
    } else if (priceRange1) {
      q += `&prs=${Number(priceRange1) * 100}-`
    } else if (priceRange2) {
      q += `&prs=0-${Number(priceRange2) * 100}`
    }
    categoryDiscover(q)
      .then((res: any) => {
        const productList = res.products?.filter((product: any) => product.type == "PRODUCT") || []
        if (+from) {
          setProducts(products ? [...products].concat(productList) : productList)
        } else {
          setProducts(productList)
        }
        if (res?.total > 0) {
          setTotal(res?.total)
        }
        setShowLoading(false)
      }).catch(err => {
        setShowLoading(false)
      })
  }

  useEffect(() => {
    if (isPC) return
    const onScrollObserver = () => {
      const headerHeight: number = document.querySelector('#header-m')?.clientHeight || 0
      setFixed(window.pageYOffset - headerHeight >= 0)

      if (categoryListRef.current && !showLoading) {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= (categoryListRef.current as HTMLElement).scrollHeight
        if (bottom) {
          setFrom(prev => prev + 1)
        }
      }
    }
    window.removeEventListener('scroll', onScrollObserver)
    window.addEventListener('scroll', onScrollObserver)
    return () => {
      window.removeEventListener('scroll', onScrollObserver)
    }
  }, [isPC, showLoading])

  const chooseFilterType = (index: number) => {
    steActiveIndex(activeIndex === index ? '' : index)
  }
  
  const setSearchQuery = (type: keyof iSearchQ, value: any) => {
    const q = { ...searchQ }
    if (type === 's') {
      // 排序选择，修改默认值
      const textItem = SORTTYPE.find(item => item.value === value)
      if (textItem) {
        const _FILTER = [...FILTER]
        _FILTER[1].text = textItem?.label
        setFilter(_FILTER)
      }
      setSort(value)
      return
    } else if (type === 'discountRange') {
      const [range1, range2] = value
      q['discountRange1'] = range1
      q['discountRange2'] = range2
    } else if (type === 'priceRange') {
      const [range1, range2] = value
      q['priceRange1'] = range1
      q['priceRange2'] = range2
    } else if (type === 'priceRange1') {
      q['priceRange'] = [value, searchQ.priceRange2]
    } else if (type === 'priceRange2') {
      q['priceRange'] = [searchQ.priceRange1, value]
    }

    q[type] = value
    setQ(q)
  }

  useEffect(() => {
    if (isPC) return
    search()
  }, [isPC, from, sort])

  useEffect(() => {
    firstRender.current = false
  }, [])

  const closeMolul = () => {
    steActiveIndex('')
    let node: HTMLDivElement | null = document.querySelector('.App')
    node?.classList.remove('over')
  }

  const Content = FILTER[activeIndex as number]?.component
  return (
    <div className={`${isFixed ? `${cms.categoryM} ${cms.categoryMFixed}` : cms.categoryM}`}>
      {showLoading && <Loading />}
      {!isPC ? (
        <CategorySeoComponent
          cid={cid}
          mappingTerm={mappingTerm}
          displayTerm={displayTerm}
          term={term}
          products={products}
          total={total}
        />
      ) : null }
      {
        isPC || (isBrowser && location?.search?.indexOf('from=menu') !== -1) ? null : <h1 className={cms.categoryMName}>{mappingTerm || displayTerm}</h1>
      }
      <div className={`${cms.filterContent} ${cms.toLeft}`}>
        <div className={cms.filterChoose}>
          {FILTER.map((item, index) => {
            return (
              <div
                className={cms.filterItem}
                key={`filter${index}`}
                onClick={() => chooseFilterType(index)}
              >
                <span className={cms.filterItemText}>
                  {item.text}
                </span>
                <img src={ arrowGray } alt="arrow icon"></img>
              </div>
            )
          })}
        </div>
      </div>
      {typeof activeIndex === 'number' && (
        <BgModul closeMolul={closeMolul} activeText={FILTER[activeIndex].displayText}>
          <Content
            q={searchQ}
            setSearchQuery={setSearchQuery as any}
            searchProducts={search}
          ></Content>
        </BgModul>
      )}
      <section className={cms.categorySection} ref={categoryListRef}>
        {
          products?.map((item: any, idx: number) => {
            return (
              <ProductItem item={item} index={idx} key={idx} />
            )
          })
        }
      </section>
    </div>
  )
}

export default CategoryM