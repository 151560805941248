import * as React from 'react'
import * as fs from './footer.module.scss'
import qrcodeDownload from '@images/qrcode-download-app.jpeg'
import wxPublish from '@images/wxPublish.png'
import weiboCode from '@images/weiboCode.png'
import miniProgramCode from '@images/miniProgramCode.png'
import Logo from '@images/logo_white.png'
import Foot2 from '@images/footer_1.png'

const Footer = () => {
  return (
    <footer className={fs.footer}>
      <div className={fs.footerTop}>
        <div className={fs.footerTopQrcode}>
          <div className={fs.footerTopQrcodeBox}>
            <img className={fs.footerTopQrcodeBoxImg} src={qrcodeDownload} alt="别样APP下载二维码" />
          </div>
          <div className={fs.footerTopQrcodeText}>
            <div>下载别样APP</div>
            <span>领</span>
            <span className={fs.footerTopQrcodeTextHighlight}>688元</span>
            <span>新人大礼包</span>
          </div>
        </div>
        <div className={fs.footerTopQrcode}>
          <div className={fs.footerTopQrcodeBox}>
            <img className={fs.footerTopQrcodeBoxImg} src={wxPublish} alt="别样官方微信二维码" />
          </div>
          <div className={fs.footerTopQrcodeText}>
            <span>官方微信</span>
          </div>
        </div>
        <div className={fs.footerTopQrcode}>
          <div className={fs.footerTopQrcodeBox}>
            <img className={fs.footerTopQrcodeBoxImg} src={weiboCode} alt="别样官方微博二维码" />
          </div>
          <div className={fs.footerTopQrcodeText}>
            <span>官方微博</span>
          </div>
        </div>
        <div className={fs.footerTopQrcode}>
          <div className={fs.footerTopQrcodeBox}>
            <img className={fs.footerTopQrcodeBoxImg} src={miniProgramCode} alt="别样小程序二维码" />
          </div>
          <div className={fs.footerTopQrcodeText}>
            <span>别样小程序</span>
          </div>
        </div>
        <div className={fs.footerTopRight}>
          <img className={fs.footerTopRightLogo} src={Logo} alt="底部-logo" />
          <div className={fs.footerTopRightDescription}>下载别样APP，领 <span>688元</span> 新人大礼包</div>
          <div className={fs.footerNavs}>
            <a className={fs.footerNavItem} href='/help'><span>帮助中心</span></a>
            <a className={fs.footerNavItem} href='/privacy'><span>隐私政策</span></a>
            <a className={fs.footerNavItem} target='_blank' href='/static/html/payment_agreement.html'><span>消费者告知书</span></a>
            <a className={fs.footerNavItem} target='_blank' href='/static/html/qualityPage/index.html'><span>正品保障</span></a>
            <a className={fs.footerNavItem} target='_blank' rel='nofollow' href='https://bybieyang.sobot.com/chat/pc/v2/index.html?sysnum=4291aa02fcc7428c96b43b3ee59ca7ad'><span>联系客服</span></a>
            <a className={fs.footerNavItem} href='/about'><span>关于我们</span></a>
            <a className={fs.footerNavItem} target='_blank' href='/static/html/logistics_details.html'><span>国际运送方式</span></a>
            <a className={fs.footerNavItem} href='/about'><span>招商合作</span></a>
          </div>
          <br />
          {/* <div className={fs.footerTopRightAddress}>别样App下载: iOS/Android</div> */}
        </div>
      </div>
      <div className={fs.footerBottom}>
        Copyright © 2015-2024, 上海别样秀数据科技有限公司, 上海市浦东新区张江高科科苑路399号10号楼12层, 021-61514616, All rights reserved. 
        <a target='_blank' rel='nofollow' href="https://beian.miit.gov.cn/#/Integrated/recordQuery">沪ICP备 18004034号</a>|
        <a target='_blank' href="https://baleen-cdn-g.bieyangapp.com/by/info/icpediwatermark.pdf">增值电信业务经营许可证：沪B2-20190462</a>
        <a target='_blank' rel='nofollow' href="http://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020190319171346000004469222-SAIC_SHOW_310000-4028e4cb69e32371016a44ea858c3934228&signData=MEYCIQDHbTLsJBMSvGqSpEhaa8UbCIMHuGAKsE6890TxMhoI9gIhANnjtCP9obDgUPyROL8e4lYUTfTIT1cDh5NLBl7k2MOl">
          <img src={Foot2} alt="verify-icon" />
        </a>
      </div>
      <div className={fs.outlinks}>
        <a className={fs.footerNavItem} target='_blank' href='https://www.beyondstyle.us'><span>BeyondStyle</span></a>
        <a className={fs.footerNavItem}>|</a>
        <a className={fs.footerNavItem} target='_blank' href='https://www.nubestore.ai/'><span>CloudStore</span></a>
        <a className={fs.footerNavItem}>|</a>
        <a className={fs.footerNavItem} target='_blank' href='https://www.borderxlab.com/bgm'><span>Beyond Global Marketplace</span></a>
      </div>
    </footer>
  )
}

export default Footer