import React from 'react'
import { QRCodeSVG } from 'qrcode.react'

function WechatMiniProgramQrCode(props: any) {
  const { id } = props || {}
  if (!id) return null
  return (
    <QRCodeSVG value={`https://bxl-weixin.bieyangapp.com/pdp?id=${id}&channel=bieyang_home`} />
  )
}

export default WechatMiniProgramQrCode
