import React, { useMemo } from 'react';
import * as styles from './Merchant.module.scss';
import ProductItem from '../ProductItem';
import { getImageUrl, logoImageUrl } from '@/lib/utils';

const Merchant  = ({ data }) => {
  const { name, specialties, images, recommendProducts, descriptions } = data || {}
  const _description = useMemo(() => {
    return Array.isArray(descriptions) ? descriptions.join('\n') : descriptions
  }, [descriptions])
  if (!name) return null
  return (
    <div className={styles.merchant}>
      <div className={styles.header}>
        <img src={getImageUrl(images?.[0]?.full?.url || images?.[0]?.thumbnail?.url) || logoImageUrl} alt={`${name}商家, ${_description || specialties || ''}`} />
        <div className={styles.info}>
          <div>
            {/* <img src={images?.[0]?.full?.url} alt="merchant country" /> */}
            <div className={styles.name}>{ name }</div>
          </div>
          <div className={styles.specialties}>
            { specialties }
          </div>
        </div>
      </div>
      <div className={styles.descriptions}>
        {_description}
      </div>
      <div className={styles.productList}>
        {
          recommendProducts?.slice(0, 16)?.map?.(item => (
            <ProductItem
              key={item.id}
              product={item}
              name={name}
            />
          ))
        }
      </div>
    </div>
  )
}

export default Merchant