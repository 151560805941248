// extracted by mini-css-extract-plugin
export var articleLink = "ArticleItem-module--article-link--0e7d9";
export var articleWideLink = "ArticleItem-module--article-wide-link--cfa87";
export var articleWideWrap = "ArticleItem-module--article-wide-wrap--4079f";
export var articleWrap = "ArticleItem-module--article-wrap--080f5";
export var avatar = "ArticleItem-module--avatar--3f552";
export var avatarImg = "ArticleItem-module--avatar-img--0ac53";
export var content = "ArticleItem-module--content--db522";
export var des = "ArticleItem-module--des--41490";
export var img = "ArticleItem-module--img--8bdc5";
export var imgWideWrap = "ArticleItem-module--img-wide-wrap--08e95";
export var imgWrap = "ArticleItem-module--img-wrap--6296a";
export var subtitle = "ArticleItem-module--subtitle--09cb0";
export var title = "ArticleItem-module--title--0ba93";