// extracted by mini-css-extract-plugin
export var active = "category-m-module--active--02968";
export var categoryM = "category-m-module--category-m--d1944";
export var categoryMFixed = "category-m-module--category-m-fixed--05262";
export var categoryMName = "category-m-module--category-m-name--15b5d";
export var categoryProduct = "category-m-module--category-product--f5b37";
export var categoryProductImg = "category-m-module--category-product-img--f0bf7";
export var categoryProductName = "category-m-module--category-product-name--6ad79";
export var categoryProductOrigin = "category-m-module--category-product-origin--169d8";
export var categoryProductPrice = "category-m-module--category-product-price--79810";
export var categoryProductPriceCn = "category-m-module--category-product-price-cn--abe9f";
export var categoryProductPriceEn = "category-m-module--category-product-price-en--7733b";
export var categoryProductTag = "category-m-module--category-product-tag--49087";
export var categoryProductTagItem = "category-m-module--category-product-tag-item--8fd60";
export var categoryProductTagItemLine = "category-m-module--category-product-tag-item-line--7bea1";
export var categorySection = "category-m-module--category-section--a6e49";
export var filterChoose = "category-m-module--filterChoose--0743c";
export var filterContent = "category-m-module--filterContent--f9104";
export var filterItem = "category-m-module--filterItem--adbe3";
export var filterItemText = "category-m-module--filterItemText--0e5b3";
export var filterList = "category-m-module--filterList--ddbca";
export var toLeft = "category-m-module--toLeft--02192";