import React, { useMemo } from "react"
import SEO from '@/templates/seo'

function CategorySeoComponent(props: any) {
  const { mappingTerm, displayTerm, term, products, total, cid, } = props || {}
  const keyword = useMemo(() => {
    return mappingTerm || displayTerm || term || ''
  }, [mappingTerm, displayTerm, term])
  const keywords = useMemo(() => {
    const res = new Set()
    res.add('别样海外购')
    if (keyword) {
      res.add(keyword)
    }
    const len = Math.min(products?.length, 5)
    for (let i = 0; i < len; i++) {
      const item = products[i]
      const brand = item?.searchProduct?.product?.displayBrand || item?.searchProduct?.product?.brand || ''
      // const name = item?.searchProduct?.nameCN || item?.searchProduct?.name
      if (brand) {
        res.add(brand)
      } 
      if (res.size > 6) break
    }
    res.add('别样App')
    return Array.from(res)
  }, [products, keyword])
  const desp = useMemo(() => {
    const brandMerchants = new Set()
    const len = Math.min(products?.length, 5)
    for (let i = 0; i < len; i++) {
      const item = products[i]
      const brand = item?.searchProduct?.product?.displayBrand || item?.searchProduct?.product?.brand || ''
      if (brand) {
        brandMerchants.add(brand)
      } else if (item?.searchProduct?.merchantName) {
        brandMerchants.add(item?.searchProduct?.merchantName)
      }
      if (brandMerchants.size > 5) break
    }
    return `别样海外购${keyword}商品类目频道,为您提供${total > 0 ? `超过${total}件${keyword}商品, ` : ''}${Array.from(brandMerchants).join(', ')}商家品牌官网价格,优惠,折扣,尺码等相关信息,上别样,海淘专家一站式服务`
  }, [products, total, keyword])

  return (
    <SEO
      title={`${keyword}商品类目频道 | 别样海外购`}
      description={desp}
      keywords={keywords?.join(',')}
      image={products?.[0]?.searchProduct?.product?.images[0]?.thumbnail?.url || products?.[0]?.searchProduct?.product.images[0].full.url}
      canonical={`https://www.bieyangapp.com/category/${cid}/`}
    />
  )
}

export default CategorySeoComponent
