import React, { useContext } from 'react';
import { ProductContext } from '@components/Context';
import './official-website.scss'; 

function OfficialWebsite(props: any) {
    const productContext: any = useContext(ProductContext);
    const { merchant } = productContext;
    if (!props?.description?.length) return null;
    return (
        <div className='m-official-website'>
            <div className='title'>{merchant?.name}官网详情</div>
            <article className='content'>
                {props?.description?.map((item: any, index: number) => {
                    const display = item?.replace(/(\r\n\\|\n\\|\r\\)/g, "\r\n");
                    if (!display) return null
                    return (
                        <p key={index} className='item'>{display}</p>
                    )
                })}
            </article>
        </div>
    )
}

export default OfficialWebsite;