// extracted by mini-css-extract-plugin
export var active = "productInfo-module--active--cd845";
export var autoAdsIns = "productInfo-module--auto-ads-ins--95be5";
export var buyNow = "productInfo-module--buyNow--7303a";
export var colors = "productInfo-module--colors--06e9c";
export var colorsMain = "productInfo-module--colors-main--9d406";
export var commitment = "productInfo-module--commitment--6f6bb";
export var commitmentGroup = "productInfo-module--commitment-group--3c322";
export var description = "productInfo-module--description--de72d";
export var expandLabel = "productInfo-module--expand-label--53fe8";
export var expandable = "productInfo-module--expandable--1f4ff";
export var expanded = "productInfo-module--expanded--2c5af";
export var infoIcon = "productInfo-module--info-icon--c764d";
export var infoWrapper = "productInfo-module--info-wrapper--6452a";
export var label = "productInfo-module--label--f3bad";
export var labelItem = "productInfo-module--label-item--078e1";
export var labelItemBeyondStyle = "productInfo-module--label-item-beyond-style--f3900";
export var labelItemGroup = "productInfo-module--label-item-group--b7ab9";
export var labelItemOpenApp = "productInfo-module--label-item-open-app--20159";
export var labelItemOpenAppPopup = "productInfo-module--label-item-open-app-popup--f15d4";
export var labelView = "productInfo-module--label-view--88066";
export var lowestPriceLabel = "productInfo-module--lowest-price-label--e077a";
export var moreService = "productInfo-module--more-service--89c09";
export var originalPriceTag = "productInfo-module--original-price-tag--00436";
export var price = "productInfo-module--price--52d7a";
export var priceTag = "productInfo-module--price-tag--e0325";
export var priceTagCn = "productInfo-module--price-tag-cn--5f05e";
export var processGroup = "productInfo-module--process-group--dd3e4";
export var processImg = "productInfo-module--process-img--310f5";
export var processItem = "productInfo-module--process-item--92c4a";
export var processList = "productInfo-module--process-list--943a7";
export var processText = "productInfo-module--process-text--c7324";
export var processTitle = "productInfo-module--process-title--dbba3";
export var processTitles = "productInfo-module--process-titles--9575b";
export var property = "productInfo-module--property--6c175";
export var qrWrap = "productInfo-module--qr-wrap--d303c";
export var service = "productInfo-module--service--d19ba";
export var serviceGroup = "productInfo-module--service-group--13db6";
export var sizes = "productInfo-module--sizes--1e16c";
export var sizesMain = "productInfo-module--sizes-main--4d596";
export var subtitle = "productInfo-module--subtitle--eb0f0";
export var title = "productInfo-module--title--c186a";
export var unavailable = "productInfo-module--unavailable--f5de4";
export var visible = "productInfo-module--visible--4a29c";