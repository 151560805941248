import React, { useContext, useState } from 'react';
import { ProductContext } from '@components/Context';
// import { likeProductComment, unlikeProductComment } from '@service/api';
// import Toast from '@components/Toast';
import './comment-item.scss';
import { openAppStore } from '@lib/appstore';
import { getImageUrl } from '@lib/utils';

const starGrey = require('@images/star-grey.png').default;
const starYellow = require('@images/star-yellow.png').default;
const defaultAvatar = require('@images/ic_default_avatar.png').default;
const likeBlack = require('@images/like-black.png').default;
const likeGrey = require('@images/like-grey.png').default;
const reply = require('@images/reply.png').default;
const time = require('@images/time.png').default;
const arrowDown = require('@images/ic_arrow_down.png').default;

const starLabel = [
    '很糟糕',
    '不推荐',
    '还不错',
    '比较推荐',
    '强烈推荐'
]

function CommentItem(props: any) {
    const { comment, className } = props;
    const productContext: any = useContext(ProductContext);
    const [current, setCurrent] = useState(comment);
    const name = productContext?.product?.nameCN || productContext?.product?.name || '';
    const { id, productCommentLabel, userLabel, content, pictures, sku, postedAt, descendantsN, liked, likes, descendants, userAvatar } = current || {};
    const { productSatisfyScore } = productCommentLabel || {};

    function handleLikeClick() {
        // if(liked) {
        //     unlikeProductComment(producId, id)
        //         .then((res: any) => {
        //             setCurrent({
        //                 ...current,
        //                 liked: false,
        //                 likes: current.likes - 1
        //             })
        //         })
        // } else {
        //     likeProductComment(producId, id)
        //         .then((res: any) => {
        //             setCurrent({
        //                 ...current,
        //                 liked: true,
        //                 likes: current.likes + 1
        //             })
        //         })
        // }
        openAppStore()
    }

    function goToCommentList() {
        // Toast('请前往APP内查看更多精彩内容');
        openAppStore()
    }

    function handleImgClick(index: number) {  
        openAppStore()
    }

    return (
        <div className={`m-comment-item ${className}`}>
            <div className="user-wrap">
                <img className="user-avatar" src={userAvatar || defaultAvatar} alt={`author avatar ${userLabel}`} />
                <div className="user-name">{userLabel}</div>
                {productCommentLabel && <div className="star-wrap">
                    {[1,2,3,4,5].map((item: any, index: number) => {
                        return (
                            <img
                                key={`star_${index}`}
                                alt={`start_${index}`}
                                src={productSatisfyScore > index ? starYellow : starGrey }
                            />
                        )
                    })}
                </div>}
                {productCommentLabel && <div className="star-label">{starLabel[productSatisfyScore - 1]}</div>}
            </div>
            <div className="comment-item-container">
                {content && <div className="comment-content">{content}</div>}
                <div className="attributes">{getAttributes(sku)}</div>
                {pictures && pictures.length &&
                    <div className="comment-image-wrap">
                        {pictures.map(((img: any, index: number) => {
                            return (
                                <img
                                    key={`product_image_${index}`}
                                    className="comment-image {(index + 1)%3 === 0 ? 'third' : ''}"
                                    src={getImageUrl(img?.full?.url)}
                                    alt={`用户${userLabel}对${name}商品评价, ${content}图片`}
                                    onClick={() => handleImgClick(index)}
                                />
                            )
                        }))}
                    </div>}
                <div className="comment-footer">
                    <div className="time-icon-wrap footer-item">
                        <img src={time} alt='time-icon' />
                        <div>{getCommentDate(postedAt)}</div>
                    </div>
                    <div className="reply-icon-wrap footer-item">
                        <img src={reply} alt='reply-icon' />
                        <div>{descendantsN || 0}</div>
                    </div>
                    <div
                        className="like-icon-wrap footer-item"
                        onClick={handleLikeClick}
                    >
                        <img alt='like-icon' src={liked ? likeBlack : likeGrey} />
                        <div>{likes}</div>
                    </div>
                </div >
            </div >
            {descendants && descendants?.length > 0 &&
                <div className="reply-wrap">
                    {descendants.map((item: any, index: any) => {
                        return (
                            <div className="reply-item" key={`descendants_${index}`}>
                                <img className="reply-user-avatar" src={item.userAvatar || defaultAvatar} alt={`reply user avatar ${item.userLabel}, ${item.content}`} />
                                <div className="reply-detail">
                                    <div className="reply-header">
                                        <div className="reply-user-name">{item.userLabel}</div>
                                        <div className="reply-date">{getCommentDate(item.postedAt)}</div>
                                    </div>
                                    <div className="reply-content">
                                        {item.parentUserLabel &&
                                            <div className="reply-prefix">
                                                <div>回复</div>
                                                <div className="reply-parent-name">{item.parentUserLabel}：</div>
                                            </div>
                                        }
                                        {item.content}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className="more-reply" onClick={goToCommentList}>
                        查看更多({descendantsN}条)
                        <img className="arrow-down" src={arrowDown} alt='arrow-more-reply' />
                    </div>
                </div>
            }
        </div >
    )
}

function getAttributes(sku: any) {
    var attstr = '';
    var color = (sku.color && sku.color.name) ? '颜色：' + sku.color.name + ' ' : '';
    var size = (sku.size && sku.size.name) ? '尺码：' + sku.size.name + ' ' : '';
    var width = (sku.attributes && sku.attributes.attributes && sku.attributes.attributes.width) ? '宽度：' + sku.attributes.attributes.width.choices[0] + ' ' : '';
    return color + size + width;
}

function getCommentDate(timeStamp: number) {
    var date = new Date(timeStamp);
    var m = ('0' + (date.getMonth() + 1)).slice(-2);
    var d = ('0' + date.getDate()).slice(-2);
    var h = ('0' + date.getHours()).slice(-2);
    var min = ('0' + date.getMinutes()).slice(-2);
    
    return m + '-' + d + ' ' + h + ':' + min
}

export default CommentItem;