// extracted by mini-css-extract-plugin
export var category = "category-pc-module--category--386b3";
export var categoryBar = "category-pc-module--category-bar--757bc";
export var categoryBarLeft = "category-pc-module--category-bar-left--7338a";
export var categoryBarLeftDiscount = "category-pc-module--category-bar-left-discount--5768f";
export var categoryBarLeftDiscountHover = "category-pc-module--category-bar-left-discount-hover--b60d6";
export var categoryBarLeftDiscountHoverBtns = "category-pc-module--category-bar-left-discount-hover-btns--4df31";
export var categoryBarLeftDiscountHoverHover = "category-pc-module--category-bar-left-discount-hover-hover--78187";
export var categoryBarLeftDiscountHoverHoverBtns = "category-pc-module--category-bar-left-discount-hover-hover-btns--8fe2e";
export var categoryBarLeftDiscountHoverHoverTop = "category-pc-module--category-bar-left-discount-hover-hover-top--531f8";
export var categoryBarLeftDiscountHoverLine = "category-pc-module--category-bar-left-discount-hover-line--ac215";
export var categoryBarLeftDiscountHoverTop = "category-pc-module--category-bar-left-discount-hover-top--56b13";
export var categoryBarLeftDiscountLine = "category-pc-module--category-bar-left-discount-line--5d6e0";
export var categoryBarLeftPrice = "category-pc-module--category-bar-left-price--7e76f";
export var categoryBarLeftPriceHove = "category-pc-module--category-bar-left-price-hove--6151c";
export var categoryBarLeftPriceHoveHover = "category-pc-module--category-bar-left-price-hove-hover--0671a";
export var categoryBarLeftPriceHoveHoverBtns = "category-pc-module--category-bar-left-price-hove-hover-btns--7109c";
export var categoryBarLeftPriceHoveHoverTop = "category-pc-module--category-bar-left-price-hove-hover-top--5950e";
export var categoryBarLeftPriceHoveLine = "category-pc-module--category-bar-left-price-hove-line--f965f";
export var categoryBarLeftPriceHover = "category-pc-module--category-bar-left-price-hover--62ce0";
export var categoryBarLeftPriceHoverBtns = "category-pc-module--category-bar-left-price-hover-btns--cff8f";
export var categoryBarLeftPriceHoverTop = "category-pc-module--category-bar-left-price-hover-top--fc63d";
export var categoryBarLeftPriceLine = "category-pc-module--category-bar-left-price-line--4add6";
export var categoryBarLeftSort = "category-pc-module--category-bar-left-sort--556de";
export var categoryBarLeftSortHover = "category-pc-module--category-bar-left-sort-hover--45d8e";
export var categoryBarLeftSortHoverBg = "category-pc-module--category-bar-left-sort-hover-bg--5bdcd";
export var categoryBarLeftSortHoverCurrent = "category-pc-module--category-bar-left-sort-hover-current--0ffb1";
export var categoryBarLeftSortText = "category-pc-module--category-bar-left-sort-text--92104";
export var categoryBarRight = "category-pc-module--category-bar-right--e572b";
export var categoryLoadMore = "category-pc-module--category-loadMore--ea279";
export var categoryProduct = "category-pc-module--category-product--58b16";
export var categoryProductImg = "category-pc-module--category-product-img--1925a";
export var categoryProductImgColors = "category-pc-module--category-product-img-colors--e2564";
export var categoryProductName = "category-pc-module--category-product-name--4a76d";
export var categoryProductOrigin = "category-pc-module--category-product-origin--5f399";
export var categoryProductPriceCn = "category-pc-module--category-product-price-cn--ddddd";
export var categoryProductPriceEn = "category-pc-module--category-product-price-en--cebc9";
export var categoryProductTag = "category-pc-module--category-product-tag--84e1d";
export var categoryProductTagItem = "category-pc-module--category-product-tag-item--f26af";
export var categoryProductTagItemLine = "category-pc-module--category-product-tag-item-line--95ee1";
export var categorySection = "category-pc-module--category-section--51281";
export var categoryStep = "category-pc-module--category-step--614cd";
export var categoryStepArrow = "category-pc-module--category-step-arrow--cd037";
export var categoryTitle = "category-pc-module--category-title--7809f";
export var hotProduct = "category-pc-module--hot-product--f20e3";
export var hotProductList = "category-pc-module--hot-product-list--9d5b2";
export var hotProductSection = "category-pc-module--hot-product-section--8ffc1";
export var hotProductSwiper = "category-pc-module--hot-product-swiper--66d2a";
export var mainTitle = "category-pc-module--main-title--000af";
export var searchBar = "category-pc-module--search-bar--630ca";
export var searchBarBtn = "category-pc-module--search-bar-btn--0735a";
export var searchBarWrapper = "category-pc-module--search-bar-wrapper--e852d";
export var searchNavRelevantKeywords = "category-pc-module--search-nav-relevant-keywords--2649d";
export var searchNavSection = "category-pc-module--search-nav-section--19a6e";
export var subTitle = "category-pc-module--sub-title--f3a74";