import { isBrowser } from "./utils";

export default {
    // 是否支持本地存储，如开启隐身模式
    _isStorageSupported: (() => {
        if (!isBrowser) return false

        const _sessionStorage = window.sessionStorage;

        if (!_sessionStorage) {
            return false;
        }

        try {
            const testKey = 'xbl_openrack';
            _sessionStorage.setItem(testKey, 'wonderful');
            _sessionStorage.removeItem(testKey);
        } catch (error) {
            console.error(error);
            return false;
        }
        return true;
    })(),

    // 设置缓存
    /**
     * @param key
     * @param value
     * @returns {boolean}
     */
    set(key: string, value: string) {
        if (this._isStorageSupported) {
            try {
                window.sessionStorage.setItem(key, value);
                return;
            } catch (e) {
                console.error(e);
            }
        }
    },

    // 读取
    get(key: string) {
        if (this._isStorageSupported) {
            const v = window.sessionStorage.getItem(key);
            return v === null ? undefined : v;
        }
        return undefined;
    },

    clearAll() {
        window.sessionStorage.clear();
    },

    // 删除
    remove(key: string) {
        if (this._isStorageSupported) {
            window.sessionStorage.removeItem(key);
            return true;
        }
        return false;
    },
};
