
import { isBrowser } from './utils'
const alipayRegex = /AlipayClient/i;
const weixinRegex = /MicroMessenger/i;
export const ALIPAY = 'Alipay';
export const WEIXIN = 'Wechat';
export const OTHER = ''; // 其他所有平台

export const platform = (() => {
    if (!isBrowser) return OTHER

    if (alipayRegex.test(navigator.userAgent)) {
        return ALIPAY;
    } else if (weixinRegex.test(navigator.userAgent)) {
        return WEIXIN;
    }
    return OTHER;
})();