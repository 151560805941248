import React from 'react';
import Tag from '@/components/Tag';
import './product-item.scss';
import { getImageUrl } from '@/lib/utils';

function ProductItem(props: any) {
    const { product, labelView, handleClickItem } = props || {}
    const {
        id,
        images,
        displayBrand,
        brand,
        nameCN,
        name,
        origin,
        merchantInfo,
        promotions,
        priceTag,
        originalPriceTag,
        priceTagCN,
        colors,
    } = product
    const brandSection = displayBrand || brand || ''
    const tags = labelView?.shortLabels.map((label: any) => label.labels.map((l: any) => l.text))?.flat() || promotions?.activityOffs

    return (
        <a className='m-product-item-wrapper' title={nameCN || name || ''} href={`/pd/${id}/`}>
            <div className='m-product-item' data-viewtype="product" data-title='product' data-id={id}>
                <div className='img-wrap'>
                    <img src={getImageUrl(images?.[0]?.full?.url)} alt={`商品${nameCN || name},价格${priceTagCN || priceTag || ''}`} />
                </div>
                <div className='detail'>
                    <div className='name'>{brandSection ? `${brandSection} | ` : ''}{nameCN || name}</div>
                    <div className='origin'>{origin || ''}{merchantInfo?.merchantName}</div>
                    <div className='sub-price'>
                        {promotions?.off &&
                            <Tag backgroundColor='#000000'>
                                {promotions?.off}
                            </Tag>
                        }
                        <div className='price-tag'>{priceTag}</div>
                        <div className='original-price-tag'>{originalPriceTag || ''}</div>
                    </div>
                    <div className='main-price-area'>
                        <div className={`main-price ${originalPriceTag ? 'discount' : ''}`}>{priceTagCN}</div>
                        {/* <div className='like'>{favoritedCount || 0}人收藏</div> */}
                    </div>
                    {tags?.length ?
                        <div className='promotion-tags'>
                            {tags?.map((item: any, index: number) => {
                                return (
                                    <div className='tag' key={index}>{item}</div>
                                )
                            })}
                        </div> : null}
                </div>
                {colors?.length ?
                    <div className='color-num'>
                        <Tag backgroundColor='#F2F2F2' fontColor='#666666'>
                            {colors?.length}色可选
                        </Tag>
                    </div> : null}
            </div>
        </a>
    )
}

export default ProductItem
